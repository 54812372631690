<mat-toolbar class="header" (click)="this.onClickBack()">
    <mat-icon>arrow_back</mat-icon>
    <h1>Network settings</h1>
</mat-toolbar>
<div class="body">
    <mat-list>
        <mat-list-item *ngIf="(deviceMacAddress$ | async) !== notSupported">
            <div class="label">MAC Address</div>
            <div class="value">
                {{ this.deviceMacAddress$ | async }}
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="(deviceIpAddress$ | async) !== notSupported">
            <div class="label">Network Address</div>
            <div class="value">
                {{ this.deviceIpAddress$ | async }}
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="(deviceSSID$ | async) !== notSupported">
            <div class="label">SSID</div>
            <div class="value">
                {{ this.deviceSSID$ | async }}
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="(deviceNetworkStatus$ | async) !== notSupported">
            <div class="label">Status</div>
            <div class="value">
                {{ this.deviceNetworkStatus$ | async }}
            </div>
        </mat-list-item>
        <ng-container *appOnlyDisplayOn="'DEVICE'">
            <mat-list-item *ngIf="hasProofOfPossession$ | async">
                <button mat-flat-button routerLink="/settings/pop">
                    Proof of Possession
                </button>
            </mat-list-item>
        </ng-container>
        <mat-list-item>
            <button mat-flat-button (click)="onClickWifiReset()">
                {{ this.resetWifiText }}
            </button>
        </mat-list-item>
        <mat-list-item>
            <button mat-flat-button (click)="onClickWifiRestore()">
                {{ this.restoreWifiText }}
            </button>
        </mat-list-item>
    </mat-list>
    <img
        class="networkQr"
        [src]="this.xfeIpAddressQr$ | async"
        *appOnlyDisplayOn="'DEVICE'"
    />
</div>
