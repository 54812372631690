import { DisplayTypeService } from '@amp/services/display-type.service';
import { InputService } from '@amp/services/input.service';
import { MenuActionsService } from '@amp/services/menu-actions.service';
import { ServerService } from '@amp/services/server.service';
import { SettingsService } from '@amp/services/settings.service';
import { clearPlaylist } from '@amp/store/playlist/actions';
import { PlaylistState } from '@amp/store/playlist/reducer';
import { selectCurrentPlaylistTrackId, selectOhTrackList } from '@amp/store/playlist/selectors';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { OhTrackListData, PlaylistItem } from './playlist.types';



const isMouseEvent = (event: MouseEvent | TouchEvent): event is MouseEvent => (event as MouseEvent).screenX !== undefined

@Component({
    selector: 'app-playlist',
    templateUrl: './playlist.component.html',
    styleUrls: ['./playlist.component.scss', '../common/header/header.common.component.scss']
}) export class PlaylistComponent implements OnInit {

    public tracks$: Observable<OhTrackListData> = this.store.select(selectOhTrackList);
    public currentTrackId$: Observable<number> = this.store.select(selectCurrentPlaylistTrackId);

    constructor(
        private server: ServerService,
        private settings: SettingsService,
        private router: Router,
        private store: Store<PlaylistState>,
        public menu: MenuActionsService,
        public input: InputService,
        public displayType: DisplayTypeService,
    ) { }

    ngOnInit() { }

    gotoPlayer() {
        this.router.navigate(['nowplaying_player']);
    }

    async onClearPlaylist() {
        this.store.dispatch(clearPlaylist());
        this.server.deletePlaylistDeleteAll();
    }

    getId(index: number, item: PlaylistItem) {
        return item.id;
    }

    onClickPlaylist(item: PlaylistItem) {
        this.server.postPlay({ playlist_id: item.id });
    }


    private pointerDown = false;
    private startX: number;
    private startY: number;
    private pointerStartMs: number;
    onDown(event: MouseEvent | TouchEvent) {
        if (this.pointerDown) { return }

        this.pointerDown = true;
        this.pointerStartMs = new Date().valueOf();
        if (isMouseEvent(event)) {
            this.startX = event.screenX;
            this.startY = event.screenY;
        } else {
            const firstTouch = event.touches[0];
            this.startX = firstTouch.screenX;
            this.startY = firstTouch.screenY;
        }

        // touchEventUp does not have any location info; if there is no movement
        // make sure we have a end location defined
        this.endX = this.startX;
        this.endY = this.startY;
    }

    private endX: number;
    private endY: number;
    trackTouch(event: TouchEvent) {
        const firstTouch = event.touches[0];
        this.endX = firstTouch.screenX;
        this.endY = firstTouch.screenY;
    }

    onUp(event: MouseEvent | TouchEvent) {
        if (!this.pointerDown) { return }

        this.pointerDown = false;
        const endTime = new Date().valueOf();

        if (isMouseEvent(event)) {
            this.endY = event.screenY;
            this.endX = event.screenX;
        }
        const deltaX = this.startX - this.endX;
        const deltaY = this.startY - this.endY;

        // determine if its a horizontal swipe
        if (Math.abs(deltaY) > Math.abs(deltaX) || Math.abs(deltaX) < 40) {
            return;
        }

        this.gotoPlayer();
    }

    // drop(event: CdkDragDrop<string[]>, tracks: Array<SongData>) {
    //     const trackIds = tracks.map(t => t.id);
    //     moveItemInArray(trackIds, event.previousIndex, event.currentIndex);
    //     console.log(trackIds);
    // }
}
