<app-header>
    <mat-menu #ampMenu="matMenu">
        <a
            (click)="this.menu.onClickSelectDevice()"
            *appOnlyDisplayOn="'NOTDEVICE'"
            ><button mat-menu-item>Select Boulder Device</button></a
        >
        <a routerLink="/settings"><button mat-menu-item>Settings</button></a>
    </mat-menu>
    <button mat-button [matMenuTriggerFor]="ampMenu">
        <mat-icon inline>menu</mat-icon>
    </button>
    <button mat-button routerLink="/input/show">
        <mat-icon inline svgIcon="amp:select"></mat-icon>
    </button>
    <button mat-button (click)="this.menu.onClickStandby()">
        <mat-icon inline>power_settings_new</mat-icon>
    </button>
</app-header>

<mat-list
    (mousedown)="onDown($event)"
    (touchstart)="onDown($event)"
    (mouseup)="onUp($event)"
    (touchend)="onUp($event)"
    (touchmove)="trackTouch($event)"
    *ngIf="this.tracks$ | async as tracks"
>
    <!-- cdkDropList (cdkDropListDropped)="drop($event, tracks)" -->
    <mat-list-item class="list-header">
        <div matLine class="queue">Track</div>
        <div class="track">Queue</div>
    </mat-list-item>
    <mat-list-item
        class="list-item"
        *ngFor="let track of tracks; index as i; trackBy: this.getId"
        [class.playing]="track.id === (this.currentTrackId$ | async)"
        (click)="this.onClickPlaylist(track)"
    >
        <!-- cdkDrag cdkDragLockAxis="y" -->

        <img matListAvatar [src]="track.cover_art_uri" />
        <div matLine class="title">{{ track.title }}</div>
        <div matLine class="artist">
            {{ track | getArtist }}&nbsp;-&nbsp;{{ track.album }}
        </div>
        <div class="trackNumber">{{ i + 1 }}</div>

        <div *cdkDragPlaceholder></div>
    </mat-list-item>
</mat-list>

<div class="actions">
    <button
        mat-button
        *ngIf="
            !(this.input.isRemoteService$ | async) &&
            !this.displayType.isDevice()
        "
        routerLink="/filebrowser"
    >
        <mat-icon inline fontSet="mdi" fontIcon="mdi-folder-music"></mat-icon>
    </button>

    <button
        mat-flat-button
        (click)="this.onClearPlaylist()"
        color="primary"
        class="clear-playlist"
    >
        Clear Playlist
    </button>

    <button
        mat-button
        *ngIf="
            !(this.input.isRemoteService$ | async) &&
            !this.displayType.isDevice()
        "
        routerLink="/nowplaying_player"
    >
        <mat-icon inline>music_note</mat-icon>
    </button>
</div>

<app-footer></app-footer>
