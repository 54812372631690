<p>Let's connect your Boulder Device to your wifi.</p>
<p>
    Step 1: Remove any ethernet cable connected to your Boulder Device. Restart
    your Device.
</p>
<p>
    Step 2: Connect your phone to the WiFi named BoulderAmplifiers-&lt;serial
    number&gt;
</p>

<h3>Currently connected to <app-currentnetwork></app-currentnetwork>.</h3>
<h3>
    <ng-container *ngIf="this.networkLooksOk$ | async; else networkBad">
        Network is <span class="network-ok">ok</span>.
    </ng-container>
</h3>

<ng-template #networkBad>
    Network is <span class="network-bad">not correct</span>.
</ng-template>
