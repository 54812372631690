<ng-container
    *ngIf="this.displayType.isDevice(); then onDevice; else notDevice"
>
</ng-container>

<ng-template #notDevice>
    <div class="logo">
        <img src="./assets/img/logo_boulderamps.png" />
        <h2>{{ this.model$ | ngrxPush }}</h2>
        <mat-icon
            fontSet="mdi"
            fontIcon="mdi-lan-disconnect"
            class="disconnected"
            [inline]="true"
            *ngIf="(this.model$ | ngrxPush) === ''"
        ></mat-icon>
    </div>

    <div class="login">
        <mat-form-field>
            <mat-label>Server Url</mat-label>
            <input matInput [formControl]="this.server" />
            <app-barcode-scanner
                matSuffix
                (barcode)="this.server.setValue($event)"
            ></app-barcode-scanner>
            <button
                mat-flat-button
                matSuffix
                color="accent"
                (click)="this.onSignin()"
                [disabled]="
                    !(this.poller.serverResponding$ | async) ||
                    (this.model$ | ngrxPush) === ''
                "
            >
                <mat-icon fontSet="mdi" fontIcon="mdi-login"></mat-icon>
            </button>
        </mat-form-field>

        <mat-nav-list>
            <mat-list-item
                *ngFor="let device of this.devices$ | async"
                (click)="this.onSignin(device.ip)"
            >
                <div>{{ device.name }}</div>
                <mat-icon fontSet="mdi" fontIcon="mdi-login"></mat-icon>
            </mat-list-item>
        </mat-nav-list>

        <div class="scan" *appOnlyDisplayOn="'PHONE'">
            <ng-container *ngIf="this.currentScans > 0">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <div class="text">Scanning for Boulder Devices...</div>
            </ng-container>
        </div>
        <div>
            <button
                mat-flat-button
                *appOnlyDisplayOn="'PHONE'"
                (click)="this.scanNetwork()"
            >
                Rescan
            </button>
        </div>
        <div class="setup-wireless">
            <button mat-stroked-button routerLink="/provisioning/joinnetwork">
                Setup Wireless Network
            </button>
        </div>
    </div>
    <div class="version">App Version: {{ this.version }}</div>
    <mat-card *ngIf="this.disconnected" class="error">
        Unable to connect to your Boulder Device. Please check your network.
    </mat-card>
</ng-template>

<ng-template #onDevice>
    <img src="assets/img/boulder-logo-splash.png" />
    <div class="initmsg">
        <ng-container *ngIf="!this.disconnected; else clock">
            Initializing System ...
        </ng-container>
    </div>
</ng-template>

<ng-template #clock>
    <mat-icon>access_time</mat-icon>
</ng-template>
