import { serverUpdateAll } from '@amp/store/all/actions';
import { resetDeviceInfo } from '@amp/store/global-actions';
import { createReducer, on } from '@ngrx/store';
import { get as _get } from 'lodash-es';
import { startAsyncAction } from './actions';


export interface ActionsInProgressState {
    actions_in_progress: number;
}

export const initialState: ActionsInProgressState = {
    actions_in_progress: 0,
}
const aipPath = 'all.actions_in_progress.actions_in_progress';

export const actionsInProgressReducer = createReducer(
    initialState,
    on(serverUpdateAll, (state, { all }) => {
        const aip = _get(all, aipPath, 0);

        return { ...state, actions_in_progress: aip }
    }),
    on(startAsyncAction, (state) => ({ ...state, actions_in_progress: state.actions_in_progress + 1 })),
    on(resetDeviceInfo, () => initialState),
);
