import { createReducer, on } from "@ngrx/store";
import { ApiType } from "../all/actions";
import { resetDeviceInfo } from "../global-actions";
import { setSupportedApis } from "./actions";

export interface SettingsState {
    supportedApis: ReadonlyArray<ApiType>,
    loadedApi: boolean;
    settingsSpecVersion: number,
};

const initialSettingsState: SettingsState = {
    supportedApis: [],
    loadedApi: false,
    settingsSpecVersion: 1,
};

export const settingsReducer = createReducer(
    initialSettingsState,
    on(setSupportedApis, (state, { supportedApis, settingsSpecVersion }) => ({ ...state, supportedApis, loadedApi: true, settingsSpecVersion })),
    on(resetDeviceInfo, () => initialSettingsState),
);