import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
@Component({
    selector: 'app-barcode-scanner',
    templateUrl: './barcode-scanner.component.html',
    styleUrls: ['./barcode-scanner.component.scss']
}) export class BarcodeScannerComponent implements OnInit {
    @Output() barcode: EventEmitter<string> = new EventEmitter();
    constructor(
        private scanner: BarcodeScanner,
    ) { }

    ngOnInit() {
    }

    async onClickBarcode() {
        const scan = await this.scanner.scan({
            prompt: "Scan QR code",
            resultDisplayDuration: 0,
            orientation: 'portrait',
            formats: 'QR_CODE',
        });

        this.barcode.emit(scan.text);
    }
}
