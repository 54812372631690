import { NOT_SUPPORTED, serverUpdateAll, StandardizedDeviceAllApi, T_NS } from "@amp/store/all/actions";
import { Input } from "@amp/store/inputs/actions";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { get, values } from "lodash-es";
import { firstValueFrom, lastValueFrom, NEVER, Observable } from "rxjs";
import { first, map } from 'rxjs/operators';
import { SettingsService } from "../settings.service";
import { BADeviceApi, notSupported, TransportState } from "./all-apis";


@Injectable({
    providedIn: 'root',
}) export class PreampApi implements BADeviceApi {
    constructor(private store: Store, private settings: SettingsService, private http: HttpClient) { }



    async getAll() {
        const all = await this._get('all/all');

        const serial = get(all, 'all.serial_number', null);

        const model = get(all, 'all.model', '');
        // the api is a bit ugly with this. Basically anything less then -100 means -Inf
        // it does things like set -101 -> get -100.10001, mute vol -50 -> get -150

        // for our internal api -101 very explicitly means -Inf. There is no such thing as -100.1 etc
        const rawVolume = get(all, 'all.master_volume', -50);
        const volume = rawVolume < -100 ? -101 : rawVolume;
        const mute = get(all, 'all.mute', false);
        const maxVolume = get(all, 'all.max_volume', -20);
        const volumeDefault = get(all, 'all.default_volume', -50);
        const standby = get(all, 'all.standby', false);

        const hasVersionUpdate = get(all, 'all.update_available', false);
        let updateVersion = null;
        let deviceMacAddress: string | T_NS = NOT_SUPPORTED;
        let deviceIpAddress: string | T_NS = NOT_SUPPORTED;
        let updateProgressPercent = '';
        if (hasVersionUpdate) {
            updateVersion = "Unknown";
        }

        const deviceTable = get(all, 'all.device_table', {});

        const allDevices = values(deviceTable).flatMap(devices => devices);

        const currentDevices = allDevices.filter(device => device.serial === serial);

        if (currentDevices.length > 0) {
            if(hasVersionUpdate) {
                updateVersion = currentDevices[0].available_version;
            }
            deviceIpAddress = currentDevices[0].ip;
            deviceMacAddress = currentDevices[0].mac;
            updateProgressPercent = currentDevices[0].update_percent;
        }


        const updateInProgress = get(all, 'all.update_in_progress', false);
        const updateProgressString = get(all, 'all.update_status', '');

        const actionsInProgress = get(all, 'all.actions_in_progress.actions_in_progress', 0);
        const volumeStepSize = get(all, 'all.volume_resolution', 1);

        const inputs: ReadonlyArray<Input> = get(all, 'all.inputs', []).map(
            (input, orderNumber) => ({
                name: input.name,
                index: input.index,
                type: 'Analog',
                trim: input.trim,
                image_id: 'DEFAULT',
                theater_mode: input.theater_mode,
                visible: true,
                orderNumber,
            })
        );
        const selectedInputIndexId = get(all, 'all.current_input', null);

        const standardAll: StandardizedDeviceAllApi = {
            model,
            volume,
            inputs,
            selectedInputIndexId,
            showPopQr: NOT_SUPPORTED,
            isProvisioning: NOT_SUPPORTED,
            actionsInProgress,

            // from update.service
            updateVersion,
            updateInProgress,
            updateProgressString,
            updateProgressPercent,

            // from nowplaying.service
            transportState: NOT_SUPPORTED,

            // from settings.service
            showDB: true,
            mute,
            soft: NOT_SUPPORTED,
            maxVolume,
            volumeDefault,

            deviceMacAddress,
            deviceIpAddress,
            deviceSSID: NOT_SUPPORTED,
            deviceNetworkStatus: NOT_SUPPORTED,

            standby,

            currentServerIconUri: NOT_SUPPORTED,
            currentServerName: NOT_SUPPORTED,

            headphone_out: NOT_SUPPORTED,
            dac_mode: NOT_SUPPORTED,
            bluetooth_connected: NOT_SUPPORTED,
            bluetooth_paired: NOT_SUPPORTED,
            playlist: NOT_SUPPORTED,
            media_server: NOT_SUPPORTED,
            current_track: NOT_SUPPORTED,

            // new
            volumeStepSize,
        }

        this.store.dispatch(serverUpdateAll({ all: standardAll, apiType: 'preamp' }));

        return all;
    }

    async postVolume({ volume }: { volume: number }): Promise<void> {
        this._post('master_volume/master_volume', { master_volume: volume });
    }

    async postInputs({ input_index }: { input_index: number }): Promise<void> {
        this._post('input/current_input_device', { index: input_index });
    }

    async getInputImageUrl(data: { image_id: string; }): Promise<string> {
        return './assets/img/input-analog-large.png';
    }

    @notSupported
    getBrowseObs(data: { params: { container_id: string; start_position: number; }; }): Observable<any> {
        return NEVER;
    }

    async _rawEndpointUpdate(endpoint: "POST" | "PUT", path: string, args: any): Promise<void> {
        if (endpoint === "POST") {
            this._post(path, args);
        } else {
            this._put(path, args);
        }
    }

    async getProofOfPossession() {
        // the cast is odd - but seems to make ts happy
        return { proof_of_possession: NOT_SUPPORTED as typeof NOT_SUPPORTED };
    }

    async getHasDisplay() {
        return { has_display: true }; // all preamps have a display
    }

    @notSupported
    async getTransportState(): Promise<{ newTransportState: TransportState }> {
        return { newTransportState: 'Stopped' }; // sensible default
    }

    async postMute({ mute }): Promise<void> {
        this._post('mute/mute', { mute });
    }

    @notSupported
    async postInputImage(fromData: FormData): Promise<void> { }

    async postStandby(standby: { standby: boolean }): Promise<void> {
        this._put('standby/standby');
    }

    async postVolumeDefault({ volume_default }) {
        this._post('master_volume/default_volume', { default_volume: volume_default });
    }

    @notSupported
    async postShowPopQR(data): Promise<void> { }

    @notSupported
    async postResetWifiNetwork() { }

    @notSupported
    async postPlay(play: { playlist_id: number }): Promise<void> { }

    @notSupported
    async postPause(): Promise<void> { }

    @notSupported
    async postNext(): Promise<void> { }

    @notSupported
    async postPrevious(): Promise<void> { }

    @notSupported
    async postMediaServer(data): Promise<void> { }

    @notSupported
    async postPlaylist(data): Promise<void> { }

    @notSupported
    async postPlaylistAppendContainer(data): Promise<void> { }

    @notSupported
    async postHeadphoneOut(data): Promise<{ headphone_out: boolean }> {
        return { headphone_out: false }; // sensible default
    }

    @notSupported
    async postClearFailover(): Promise<void> { }

    @notSupported
    async postRestoreWifiNetwork(): Promise<void> { }

    @notSupported
    async putResetInputImage(data): Promise<void> { }

    async putStartUpdate(): Promise<void> {
        this._put('update/update_available');
    }

    @notSupported
    async putBluetoothUnpair(): Promise<void> { }

    @notSupported
    async deletePlaylistDeleteAll(): Promise<void> { }



    private async _get(apiSuffix: string): Promise<any> {
        const baseUrl = await this.getBaseUrl();
        return lastValueFrom(this.http.get(`${baseUrl}/${apiSuffix}`));
    }

    private async _post(apiSuffix: string, queryArgs?: { [qArg: string]: any }) {
        const baseUrl = await this.getBaseUrl();
        return lastValueFrom(this.http.post(`${baseUrl}/${apiSuffix}`, undefined, { params: queryArgs }));
    }

    private async _put(apiSuffix: string, queryArgs?: { [qArg: string]: any }) {
        const baseUrl = await this.getBaseUrl();
        return lastValueFrom(this.http.put(`${baseUrl}/${apiSuffix}`, undefined, { params: queryArgs }));
    }

    private async getBaseUrl() {
        return firstValueFrom(this.settings.loadedStorage$.pipe(
            first(ls => ls),
            map(() => `http://${this.settings.server$.value}/api`),
        ));
    }
}
