import { resetDeviceInfo } from '@amp/store/global-actions';
import { createReducer, on } from '@ngrx/store';
import { ApiType, NOT_SUPPORTED, StandardizedDeviceAllApi, serverUpdateAll, setMute, setStandby, setTransportState, setVolume, updateHeadphoneOut } from './actions';


// it should follow the all state fairly closely. but some more complicted fields have their own store areas
export type AllState = {
    apiType: ApiType,
} & Omit<StandardizedDeviceAllApi, 'playlist' | 'current_track' | 'media_server' | 'inputs' | 'selectedInputIndexId' | 'actionsInProgress'>;

export const initialState: AllState = {
    apiType: 'preamp',

    model: '',
    volume: -50,
    volumeStepSize: 1,

    headphone_out: NOT_SUPPORTED,
    dac_mode: NOT_SUPPORTED,
    bluetooth_connected: NOT_SUPPORTED,
    bluetooth_paired: NOT_SUPPORTED,
    showPopQr: NOT_SUPPORTED,
    isProvisioning: NOT_SUPPORTED,

    // settings.service things
    showDB: true,
    mute: NOT_SUPPORTED,
    soft: NOT_SUPPORTED,
    maxVolume: 0,
    volumeDefault: -50,
    deviceMacAddress: 'loading...',
    deviceIpAddress: 'loading...',
    deviceSSID: 'loading...',
    deviceNetworkStatus: 'loading...',
    standby: false,
    currentServerIconUri: NOT_SUPPORTED,
    currentServerName: NOT_SUPPORTED,

    // update.service things
    updateVersion: null,
    updateInProgress: false,
    updateProgressString: 'Updating',
    updateProgressPercent: '50', // string not number!

    // nowplaying.service things
    transportState: null,
};



let emptyModelWarned = false;

// all does not actually store the data ourselves
export const allReducer = createReducer(
    initialState,
    on(serverUpdateAll, (state, { all: newAll, apiType }) => {
        const { volume, model, headphone_out, dac_mode, bluetooth_connected, bluetooth_paired,
            showDB, mute, soft, maxVolume, volumeDefault, deviceMacAddress, deviceIpAddress, deviceNetworkStatus,
            deviceSSID, standby, currentServerIconUri, currentServerName, transportState, updateVersion,
            updateInProgress, updateProgressPercent, updateProgressString, showPopQr, isProvisioning,
            volumeStepSize } = newAll;

        if (model === '' && !emptyModelWarned) {
            console.error("The api for all.model.model is ''. This is not allowed");
            emptyModelWarned = true;
        }

        const newState: AllState = {
            apiType,

            volume,
            volumeStepSize,
            model,
            headphone_out,
            dac_mode,
            bluetooth_connected,
            bluetooth_paired,
            isProvisioning,
            showPopQr,

            // setings.service
            showDB,
            mute,
            soft,
            maxVolume,
            volumeDefault,
            deviceMacAddress,
            deviceIpAddress,
            deviceSSID,
            deviceNetworkStatus,
            standby,
            currentServerIconUri,
            currentServerName,

            // update.service
            updateInProgress,
            updateProgressPercent,
            updateProgressString,
            updateVersion,

            // nowplaying.service
            transportState,
        };
        return newState;
    }),
    on(setVolume, (state, { volume }) => ({ ...state, volume })),
    on(setMute, (state, { mute }) => ({ ...state, mute })),
    on(setTransportState, (state, { transportState }) => ({ ...state, transportState })),
    on(updateHeadphoneOut, (state, { headphone_out }) => ({ ...state, headphone_out })),
    on(setStandby, (state, { standby }) => ({ ...state, standby })),
    on(resetDeviceInfo, () => initialState),
);
