import { DisplayTypeService } from '@amp/services/display-type.service';
import { MenuActionsService } from '@amp/services/menu-actions.service';
import { ServerService } from '@amp/services/server.service';
import { Component } from '@angular/core';

@Component({
  selector: 'amp-network-failover',
  templateUrl: './network-failover.component.html',
  styleUrls: ['./network-failover.component.scss']
})
export class NetworkFailoverComponent {
  constructor(
    private server: ServerService,
    public displayType: DisplayTypeService,
    public menu: MenuActionsService,
  ) { }


  async ack() {
    void await this.server.postClearFailover();
  }

  async reconnect() {
    void await this.server.postRestoreWifiNetwork();
  }
}
