import { T_NS } from "@amp/store/all/actions";
import { Observable } from "rxjs";

export type TransportState = 'Playing' | 'Paused' | 'Stopped';


export function notSupported(target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args) {
        console.error(`The ${target.constructor.name} api does not support ${propertyKey}`);
        return originalMethod.apply(this, args);
    }

    return descriptor;
}

export abstract class BADeviceApi {
    // do NOT use the return value of getAll - its the raw api data. getAll() should update the data store with the standardizedAllApi
    abstract getAll(): Promise<Object>;

    abstract getProofOfPossession(): Promise<{ proof_of_possession: string | T_NS }>;
    abstract getHasDisplay(): Promise<{ has_display: boolean }>;
    abstract getTransportState(): Promise<{ newTransportState: TransportState }>;

    abstract getBrowseObs(data: { params: { container_id: string, start_position: number } }): Observable<any>;

    abstract getInputImageUrl(data: { image_id: string }): Promise<string>;

    abstract postInputs(data: { input_index: number }): Promise<void>;
    abstract postVolume(data: { volume: number }): Promise<void>;
    abstract postMute(data: { mute: boolean }): Promise<void>;
    abstract postInputImage(formData: FormData): Promise<void>;
    abstract postStandby(data: { standby: boolean }): Promise<void>;
    abstract postVolumeDefault(data: { volume_default: number }): Promise<void>;
    abstract postShowPopQR(data: { show_pop_qr: boolean }): Promise<void>;
    abstract postResetWifiNetwork(): Promise<void>;
    abstract postPlay(data?: { playlist_id: number }): Promise<void>;
    abstract postPause(): Promise<void>;
    abstract postNext(): Promise<void>;
    abstract postPrevious(): Promise<void>;
    abstract postMediaServer(data: { server_uuid: string }): Promise<void>;
    abstract postPlaylist(data: { container_id?: number, play_when_done?: boolean, insert_after?: number }): Promise<void>;
    abstract postPlaylistAppendContainer(data: { container_id?: number, play_when_done?: boolean, insert_after?: number }): Promise<void>;
    abstract postHeadphoneOut(data: { headphone_out: boolean }): Promise<{ headphone_out: boolean }>;
    abstract postClearFailover(): Promise<void>;
    abstract postRestoreWifiNetwork(): Promise<void>;

    abstract putResetInputImage(data: { input_index: number }): Promise<void>;
    abstract putStartUpdate(): Promise<void>;
    abstract putBluetoothUnpair(): Promise<void>;

    abstract deletePlaylistDeleteAll(): Promise<void>;

    abstract _rawEndpointUpdate(protocol: 'POST' | 'PUT', path: string, args: any): Promise<void>;
};