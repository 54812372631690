import { BrowseService, GetFileResult } from '@amp/browse/browse.service';
import { ServerService } from '@amp/services/server.service';
import { BrowseState } from '@amp/store/browse/reducer';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { getFiles, gotAllFiles, gotFiles, gotoBreadcrumb, isNewFileOpts, noop } from './actions';
import { selectFolderBrowseState } from './selectors';

@Injectable({
    providedIn: 'root',
}) export class BrowseEffects {


    // effects
    getFiles$ = createEffect(() => this.actions$.pipe(
        ofType(getFiles),
        mergeMap(({ mediaServer, path, opts }) => {
            if (mediaServer === null) { return of(gotAllFiles()) }

            const offset = isNewFileOpts(opts) ? 0 : opts.offset;
            return this.browseService.getFiles(mediaServer, path, offset).pipe(
                map(r => gotFiles({ mediaServer, path, offset, newFiles: r.media_items, next: this.makeNextUrl(r) })),
                catchError(e => this.onError(e)),
            )
        }),
    ));

    gotoBreadcrumb$ = createEffect(() => this.actions$.pipe(
        ofType(gotoBreadcrumb),
        map(({ breadcrumb }) => {
            return getFiles({ ...breadcrumb, opts: { displayName: breadcrumb.displayName } })
        }),

    ));

    gotFiles$ = createEffect(() => this.actions$.pipe(
        ofType(gotFiles),
        concatMap(action => of(action).pipe(
            withLatestFrom(this.store.pipe(select(selectFolderBrowseState))),
        )),
        map(([gotData, browseState]) => {
            if (!gotData.next) {
                return gotAllFiles();
            } else if (this.noLongerCare(gotData, browseState)) {
                return noop();
            } else {
                return getFiles({ ...gotData, opts: { offset: +gotData.next.searchParams.get('start_position') } });
            }
            console.log(gotData.path, browseState.path, gotData.offset, browseState.offset);
        }),
    ));

    // [helper] functions
    noLongerCare(gotData, browseState): boolean {
        return !isEqual(gotData.mediaServer, browseState.mediaServer) || gotData.path !== browseState.path || gotData.offset !== browseState.offset;
    }

    onError(error) {
        console.error('error:', error);
        this.snackbar.open(`An error has occurred getting files`, 'Ok', { duration: 10 * 1000 });
        return EMPTY;
    }

    makeNextUrl(response: GetFileResult): URL {
        if (!response.next) {
            return null;
        }
        return new URL(response.next, this.server.getBaseUrl());
    }

    constructor(
        private actions$: Actions,
        private browseService: BrowseService,
        private server: ServerService,
        private store: Store<BrowseState>,
        private snackbar: MatSnackBar,
    ) { };
}
