import { DisplayTypeService } from '@amp/services/display-type.service';
import { InputService, isNetwork, isRemoteService } from '@amp/services/input.service';
import { ServerService } from '@amp/services/server.service';
import { Input, setSelectedInput } from '@amp/store/inputs/actions';
import { selectAllInputs, selectSelectedInput } from '@amp/store/inputs/selectors';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { Subscription, combineLatest, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

const mutex = Symbol('input swiper');


@Component({
    selector: 'app-input-show',
    templateUrl: './input-show.component.html',
    styleUrls: ['./input-show.component.scss'],
}) export class InputShowComponent {

    public swiperConfig: SwiperOptions = {
        centeredSlides: true,
        slidesPerView: 3,
        simulateTouch: true,
        touchMoveStopPropagation: false,
        touchStartPreventDefault: false,
        // we can only transition if we swipe at least 1/4 of a slide
        shortSwipes: false,
        longSwipesRatio: .25,

        loop: true,
        loopedSlides: 3,
        slideToClickedSlide: true,
        runCallbacksOnInit: false,

        onAny: (e) => console.warn(e),
    }

    allInputs$ = this.store.select(selectAllInputs).pipe(
        map(inputs => inputs.filter(input => input.visible)),
    );
    selectedInput$ = this.store.select(selectSelectedInput);

    private subs: Array<Subscription> = [];

    @ViewChild(SwiperComponent) swiper?: SwiperComponent;

    constructor(
        public input: InputService,
        public dt: DisplayTypeService,
        private server: ServerService,
        private store: Store,
        private router: Router,
    ) { }


    ngOnInit() {
    }

    private afterLoopFix = false;
    ngAfterViewInit() {
        this.subs.push(
            combineLatest([
                this.allInputs$,
                this.selectedInput$,
            ]).subscribe(([allInputs, selectedInput]) => {
                if (!selectedInput) {
                    return;
                }

                const currentIndex = this.swiper.swiperRef.realIndex;
                const loopIndex = this.findSwiperIndex(allInputs, selectedInput);

                if (loopIndex !== currentIndex) {
                    this.swiper.swiperRef.slideToLoop(loopIndex, undefined, false);
                }
            }),
        );
    }


    private findSwiperIndex(allInputs: ReadonlyArray<Input>, selectedInput: Input): number {
        return allInputs.findIndex(i => i.index === selectedInput.index);
    }

    private findSwiperSelection(allInputs: ReadonlyArray<Input>, loopIndex: number): Input {
        return allInputs[loopIndex];
    }


    log(data: any) {
        console.log(data, this.swiper.swiperRef.realIndex, this.afterLoopFix);

    }

    ngOnDestroy() {
        for (const sub of this.subs) {
            sub.unsubscribe();
        }
        this.subs = [];
    }


    // loopfix / slidechange / touchend are a bit ugly. what we want is to update the model when the user changes stuff
    // that is *mostly* touchend. However because we are looping there is a catch. When stuff loops we trigger our touch
    // but the realindex is still the old index. Then we get a loopfix, again the same index. Then we get a slideChange
    // normally we do NOT want to pay attention to slidechange since it includes automatic slidechange events, but in this
    // case we want one event
    public onLoopFix() {
        this.afterLoopFix = true;
    }

    public onSlideChange() {
        if (this.afterLoopFix) {
            this.afterLoopFix = false;
            this.onTouchEnd();
        }
    }

    private touchHasMoved = false;
    private lastInputPressed = null as null | Input;
    public onSlidePointerDown(inputPressed: Input) {
        this.touchHasMoved = false;
        this.lastInputPressed = inputPressed;
    }

    public onTouchMove() {
        this.touchHasMoved = true;
    }

    public async onTouchEnd() {
        const allInputs = await firstValueFrom(this.allInputs$);
        const loopIndex = this.swiper.swiperRef.realIndex;

        const newInput = this.findSwiperSelection(allInputs, loopIndex);

        this.makeActiveInput(newInput);
    }


    public async makeActiveInput(newInput: Input) {
        const inputPressed = this.lastInputPressed;
        const localTouchHasMoved = this.touchHasMoved;
        this.lastInputPressed = null;
        this.touchHasMoved = false;

        if (!this.swiper) { return }
        const oldActiveInput = await firstValueFrom(this.selectedInput$);

        if (isEqual(newInput, oldActiveInput)) {
            if (!localTouchHasMoved && inputPressed !== null && (isNetwork(inputPressed) || isRemoteService(inputPressed))) {
                this.router.navigate(["/nowplaying_player"]);
            }
            return
        }

        const { index: input_index } = newInput;

        void /* await */ this.server.postInputs({ input_index });

        this.store.dispatch(setSelectedInput({ inputIndex: newInput.index }));
    }
}
