import { selectModelName } from '@amp/store/all/selector';
import { resetDeviceInfo } from '@amp/store/global-actions';
import { version } from '@amp/version';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DiscoveryService } from '../services/discovery.service';
import { DisplayTypeService } from '../services/display-type.service';
import { PollerService } from '../services/poller.service';
import { SettingsService } from '../services/settings.service';

declare const serviceDiscovery;
declare const WifiWizard2;
declare const ssdp;

type SigninState = { disconnect: boolean };
type Device = {
    LOCATION: string;
    ST: string;
    Server: string;
    USN: string;
    xml: string;
};
type ServiceData = {
    ip: string;
    name: string;
};

@Component({
    selector: 'signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss'],
})
export class SigninComponent {
    public server: FormControl;
    public disconnected: boolean = false;
    public version = version;
    public model$ = this.store.select(selectModelName);

    public currentScans = 0;
    public devices$ = new BehaviorSubject([] as ReadonlyArray<ServiceData>);

    constructor(
        public settings: SettingsService,
        public displayType: DisplayTypeService,
        private discovery: DiscoveryService,
        private router: Router,
        public poller: PollerService,
        private route: ActivatedRoute,
        private store: Store
    ) {}

    private view2ModelSub: Subscription = null;
    private model2ViewSub: Subscription = null;

    ngOnInit() {
        // an xfe should autologin
        if (this.displayType.isDevice()) {
            this.poller.serverResponding$
                .pipe(first((sr) => sr))
                .subscribe(async () => {
                    await this.router.navigate(['/input/show']);
                    this.settings.loggedIn$.next(true);
                });
        }

        this.store.dispatch(resetDeviceInfo());
        // we only need one way binding - this cant be changed by the system
        this.server = new FormControl(this.settings.server$.value);
        this.view2ModelSub = this.server.valueChanges.subscribe((server) => {
            this.setServerIp(server);
        });
        this.model2ViewSub = this.settings.server$.subscribe((newModel) => {
            if (this.server.value !== newModel) {
                this.server.setValue(newModel);
            }
        });

        const qpIp = this.route.snapshot.queryParams.ip;
        if (qpIp) {
            this.settings.loadedStorage$
                .pipe(first((ls) => ls))
                .toPromise()
                .then(() => {
                    this.settings.server$.next(qpIp);
                });
        }

        this.disconnected = this.settings.loggedIn$.value;

        this.scanNetwork();
    }

    private setServerIp(serverIp) {
        // all settings are prob invalid now - but this is the only one we actually use
        this.settings.server$.next(serverIp);
    }

    async isWifiDirect(): Promise<boolean> {
        try {
            // we dont actually care about location - but its needed for ssid since ios 13
            void (await new Promise((resolve, reject) =>
                navigator.geolocation.getCurrentPosition(resolve, reject)
            ));
            const ssid = await WifiWizard2.getConnectedSSID();
            return /^BoulderAmplifiers-(.*)$/.test(ssid);
        } catch (e) {
            console.warn('could not get wifi ssid; is not a wifi direct', e);
            return false;
        }
    }

    // null means you already have a discovery already running
    async getServiceDiscovery(): Promise<ReadonlyArray<ServiceData> | null> {
        try {
            const devices = await this.discovery.getServices();
            if (devices === null) {
                return null;
            }
            const parser = new DOMParser();
            const deviceMap = devices
                .map((device) => {
                    try {
                        const ip =
                            device.location.match(/http:\/\/([^:]+):\d+/)[1];
                        const xmlDoc = parser.parseFromString(
                            device.xml,
                            'text/xml'
                        );
                        const name =
                            xmlDoc.getElementsByTagName('friendlyName')[0]
                                .textContent;
                        if (
                            !['866', '812', '1110', '3010'].includes(
                                xmlDoc.getElementsByTagName('modelNumber')[0]
                                    .textContent
                            )
                        ) {
                            return null;
                        }
                        return {
                            ip,
                            name,
                        };
                    } catch (e) {
                        console.error(
                            'could not correctly parse ',
                            device,
                            ' error was',
                            e
                        );
                        return null;
                    }
                })
                .filter((d) => d !== null)
                .sort((a, b) => a.name.localeCompare(b.name));

            return deviceMap;
        } catch (e) {
            console.error('error while scanning for upnp', e);
            return [];
        }
    }

    async scanNetwork() {
        this.currentScans += 1;
        try {
            // NOT awaited - this is the prmomise itself; this is a minor optimization
            const serviceDiscoveryPromise = this.getServiceDiscovery();
            let deviceMap: ReadonlyArray<ServiceData>;

            const isWifiDirect = await this.isWifiDirect();
            if (isWifiDirect) {
                deviceMap = [
                    {
                        ip: '192.168.0.2',
                        name: 'Boulder Amplifiers WiFi Direct',
                    },
                ];
            } else {
                const d = await serviceDiscoveryPromise;
                if (!d) {
                    return;
                }
                deviceMap = d;
            }

            this.devices$.next(deviceMap);
        } catch (e) {
            console.error('error while scanning for upnp', e);
            this.devices$.next([]);
        } finally {
            this.currentScans -= 1;
        }
    }

    async onSignin(serverIp = null) {
        if (!!serverIp) {
            this.setServerIp(serverIp);
        }

        void (await this.router.navigate(['/input/show']));
        this.settings.loggedIn$.next(true);
    }

    ngOnDestroy() {
        if (this.view2ModelSub) {
            this.view2ModelSub.unsubscribe();
        }
        if (this.model2ViewSub) {
            this.model2ViewSub.unsubscribe();
        }
    }
}
