import { DisplayTypeService } from '@amp/services/display-type.service';
import { DistinctBehaviorSubject } from '@amp/services/distinctbehaviorsubject';
import { InputService } from '@amp/services/input.service';
import { ServerService } from '@amp/services/server.service';
import { SettingsService } from '@amp/services/settings.service';
import { ConfirmDialogComponent } from '@amp/settings/confirm-dialog/confirm-dialog.component';
import { selectApiType, selectIsUpdateAvailable, selectShowDB } from '@amp/store/all/selector';
import { Input } from '@amp/store/inputs/actions';
import { selectAllInputs, selectInputById } from '@amp/store/inputs/selectors';
import { selectSettingsSpecVersion } from '@amp/store/settings/selectors';
import { version } from '@amp/version';
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlider } from '@angular/material/slider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Camera, CameraOptions, PictureSourceType } from '@awesome-cordova-plugins/camera/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Store } from '@ngrx/store';
import { get as _get, isEqual, size as _size } from 'lodash-es';
import { BehaviorSubject, firstValueFrom, fromEvent, lastValueFrom, merge, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { formatVolume } from './possiblevolume.pipe';


export type SettingsSpec = Array<Section>;
type Section = {
    display: string,
    settings: Array<Setting>,
};
type Setting = EnumSetting | RangeSetting | TextSetting | InputSetting | ImageSetting | ScreenSetting | InfoSetting | ButtonSetting | BalanceRangeSetting;


// v1 of the api has a 'endpoint'. There are exceptions (which can be seen in its own function below) but that basically means
// GET all => _get(all, 'all.${endpoint}.${endpoint}'
// POST api/${endpoint}/${endpoint}?${endpoint}=${value}
// in order to support the preamp we need to be significantly more flexible
// thus we have a all_json_path and a post_api_path
// both are optional on a setting. If either is not specified we default to v1 use patterns. otherwise
// GET all => _get(all, '${all_json_path}')
// POST api/${post_api_path}?${endpoint}=${value}
// all_json_path is a json path; ie use dots
// post_api_path is a url fragment; ie use slashes
export type BaseSetting = {
    type: string,
    display: string,
    endpoint: string,
    all_json_path?: string, // if not provided defaults to ${endpoint}.${endpoint}
    // post_url_path can now actually be a PUT endpoint if you specify the config field 'use_put_for_update'
    // we retain the old name for backwards compatibility
    post_url_path?: string, // if not provided defaults to ${endpoint}
    // config comes in as Array<string>?; by mapping it to an array we can do
    // lookups in the template by attempting the reference -> true | undefined
    config: { [key: string]: true };
};
type EnumSetting = {
    type: 'enum',
    options: Array<[string, string]>, // [HumanText, MachineValue]
} & BaseSetting;
const isEnum = (s: Setting): s is EnumSetting => s.type === 'enum';

type RangeSetting = {
    type: 'range',
    min?: number,
    max?: number,
} & BaseSetting;
const isRange = (s: Setting): s is RangeSetting => s.type === 'range';

type BalanceRangeSetting = {
    subtype: 'balance',
} & RangeSetting;
const isBalanceRange = (s: Setting): s is BalanceRangeSetting => isRange(s) && (s as any).subtype === 'balance'

type TextSetting = {
    type: 'text',
} & BaseSetting;
const isText = (s: Setting): s is TextSetting => s.type === 'text';

type ImageSetting = {
    type: 'image',
} & BaseSetting;
const isImage = (s: Setting): s is ImageSetting => s.type === 'text'

type InputSetting = {
    type: 'inputSettings',
    settings: Array<TextSetting | RangeSetting | ImageSetting>,
};
const isInput = (s: Setting): s is InputSetting => s.type === 'inputSettings';

type InfoSetting = {
    type: 'info',
} & BaseSetting;
const isInfo = (s: Setting): s is InfoSetting => s.type === 'info';

export type ScreenSetting = {
    type: 'screen',
    display: string,
    config: Array<string>,
}
const isScreen = (s: Setting): s is ScreenSetting => s.type === 'screen';

type ButtonSetting = {
    type: 'button',
} & BaseSetting;
const isButton = (s: Setting): s is ButtonSetting => s.type === 'button';

// 'endpoint' is not necessarily unique (in its section) now;
export const getControlId = (setting: BaseSetting) => {
    return `${setting.endpoint}:${setting.all_json_path}:${setting.post_url_path}`;
}

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
}) export class SettingsComponent implements OnInit {
    public isBalanceRange = isBalanceRange;

    public settingsForm$: BehaviorSubject<FormGroup> = new BehaviorSubject(new FormGroup({}));
    public settingsSpec$: BehaviorSubject<SettingsSpec> = new BehaviorSubject([]);


    // subscriptions that will need to be unsubscribed when we exit
    // any subscriptions for inputs go in inputSubscriptions as those
    // need to be redone any time inputs change;
    // TODO: is inputSubscriptions an over-complication?
    private subscriptions: Array<Subscription> = [];
    private inputSubscriptions: Array<Subscription> = []


    public images: { [inputId: string]: Observable<string> } = {};
    //public route: { [endpoint: string]: string } = {};

    public infoWatchers: { [endpoint: string]: Observable<string> } = {};

    public volumeInDb = false;

    @ViewChildren(MatSlider, { read: ElementRef }) private sliders: QueryList<ElementRef>;

    showDB$ = this.store.select(selectShowDB);
    isUpdateAvailable$ = this.store.select(selectIsUpdateAvailable);
    allInputsIds$ = this.store.select(selectAllInputs).pipe(
        map(inputs => inputs.map(i => i.index)),
        distinctUntilChanged(isEqual),
    );

    constructor(
        public server: ServerService,
        public inputs: InputService,
        public displayType: DisplayTypeService,
        private settings: SettingsService,
        private store: Store,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private camera: Camera,
        private file: File,
        private snackbar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.doAsyncSetup();
        this.subscriptions.push(
            this.store.select(selectShowDB).subscribe(inDb => this.volumeInDb = inDb)
        );
    }

    private sliderSubs: Array<Subscription> = []
    ngAfterViewInit() {
        const mouseUp = merge(
            fromEvent(document.body, 'mouseup'),
            fromEvent(document.body, 'touchend'),
        );
        this.sliders.changes.subscribe(() => {
            for (const sub of this.sliderSubs) {
                sub.unsubscribe();
            }
            this.sliderSubs = []


            this.sliders.map(slider => {
                this.sliderSubs.push(
                    merge(
                        fromEvent(slider.nativeElement, 'mousedown'),
                        fromEvent(slider.nativeElement, 'touchstart'),
                    ).pipe(
                        switchMap(() => mouseUp)
                    ).subscribe(
                        (e: any) => slider.nativeElement.blur()
                    )
                )
            });
        });
    }

    ngOnDestroy() {
        for (let subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
        for (let subscription of this.inputSubscriptions) {
            subscription.unsubscribe();
        }
        for (const sub of this.sliderSubs) {
            sub.unsubscribe();
        }
    }

    async doAsyncSetup() {
        void await firstValueFrom(this.settings.loggedIn$.pipe(first(li => li)));
        this.settingsSpec$.next(await this.server.getSettingsSpec() as SettingsSpec);

        let group = new FormGroup({});

        for (let section of this.settingsSpec$.value) {
            const boundSection = section;
            for (let control of boundSection.settings) {
                if (!isInput(control) && !isScreen(control)) {
                    this.convertSetting(control);
                }

                if (isRange(control) || isEnum(control)) {
                    const boundControl = control;
                    const watcher = await this.getAllWatcherAsync(control);
                    const fc = new FormControl(watcher.value);
                    const m2vSub = watcher.subscribe(val => {
                        if (val !== fc.value) {
                            fc.setValue(val, { emitEvent: false });
                        }
                    });
                    this.subscriptions.push(m2vSub);

                    const v2mSub = fc.valueChanges.pipe(
                        debounceTime(100),
                    ).subscribe(async val => {

                        // casting disables the typechecking; if the api for
                        // settings is wrong this will error
                        try {
                            const postPoint = this.getUpdateEndpoint(control as BaseSetting);

                            await this.server._rawEndpointUpdate(
                                this.getUpdateProtocol(control as BaseSetting),
                                postPoint,
                                {
                                    [boundControl.endpoint]: val,
                                });
                        } catch (e) {
                            // if the server rejects our post call for *any* reason set back to model's version
                            // we need to do this because the normal watcher only would trigger on a change
                            // with a server fail its likely things didn't change
                            fc.setValue(watcher.value, { emitEvent: false });

                            if (e.status === 400 && boundControl.endpoint === 'volume_default') {
                                // volumeDefault may be rejected depending on volumeMax's setting
                            } else {
                                console.error(`Unable to POST to ${boundControl.endpoint}. Is the api ok?`);
                            }
                        }
                    });
                    this.subscriptions.push(v2mSub);

                    const controlId = getControlId(control);
                    group.addControl(controlId, fc);

                } else if (isInput(control)) {
                    const boundSettings = control.settings;
                    // inputs add to form. So wait till the form is ready, then wait for
                    // inputs to be ready. Then alter the form.
                    const sub = this.settingsForm$.pipe(
                        first(form => _size(form.controls) > 0),
                        switchMap(() => this.inputs.options$),
                        first(inputs => inputs.length > 0),
                    ).subscribe(inputs => this.setupInputs(inputs, boundSettings))
                    this.subscriptions.push(sub);
                } else if (isScreen(control)) {
                    // noop
                } else if (isInfo(control)) {
                    const endpoint = getControlId(control);
                    this.infoWatchers[endpoint] = await this.getAllWatcherAsync(control);
                }
            }
        }

        this.settingsForm$.next(group);
    }

    // This MUST be called on any input that can have config on it
    convertSetting(setting: BaseSetting): void {
        if (!Array.isArray(setting.config) && !(typeof setting.config === 'undefined')) {
            return;
        }
        const configArray: Array<string> = setting.config as any as Array<string> || [];
        const configMap: { [key: string]: true } = configArray.reduce((obj, key) => { obj[key] = true; return obj; }, {});
        setting.config = configMap;
    }

    setupInputs(inputs: Array<Input>, settings: Array<RangeSetting | TextSetting | ImageSetting>) {
        this.settingsForm$.value.removeControl('__inputs__');

        for (let is of this.inputSubscriptions) {
            is.unsubscribe()
        }
        this.inputSubscriptions = [];

        const newInputGroups = new FormGroup({});
        inputs.map(input => {
            const inputSetting = new FormGroup({});
            settings.map(async (setting) => {
                this.convertSetting(setting)
                const watcher = await this.getAllWatcherAsync(setting, { input });
                const fc = new FormControl(watcher.value);
                const m2vSub = watcher.subscribe(val => {
                    if (val !== fc.value) {
                        fc.setValue(val, { emitEvent: false });
                    }
                });
                this.inputSubscriptions.push(m2vSub);


                const v2mSub = fc.valueChanges.pipe(
                    debounceTime(100),
                ).subscribe(async val => {
                    // casting disables the typechecking; if the api for
                    // settings is wrong this will error
                    const postPoint = this.getUpdateEndpoint(setting);
                    const inputIndexQueryArg = (await firstValueFrom(this.store.select(selectApiType)) === 'preamp') ? 'index' : 'input_index';

                    try {
                        await this.server._rawEndpointUpdate(
                            this.getUpdateProtocol(setting),
                            postPoint,
                            {
                                [inputIndexQueryArg]: input.index,
                                [setting.endpoint]: val,
                            });
                    } catch (e) {
                        fc.setValue(watcher.value, { emitEvent: false });

                        console.error(`Unable to POST to ${postPoint}. Is the api ok?`);
                    }
                });
                this.inputSubscriptions.push(v2mSub);

                if (isImage(setting)) {
                    this.images[`${input.index}`] = this.inputs.getImageUrl$(input);
                }

                const controlId = getControlId(setting);
                inputSetting.addControl(controlId, fc);
            })

            newInputGroups.addControl(`${input.index}`, inputSetting);
        });

        this.settingsForm$.value.registerControl('__inputs__', newInputGroups);
    }

    async getInputById(id: number): Promise<Input> {
        return await firstValueFrom(this.store.select(selectInputById(id)));
    }

    async onButtonSettingClick(setting: ButtonSetting) {
        if (setting.config.factory_defaults || setting.config.upload_logs) {
            const doFactory = await lastValueFrom(this.dialog.open(ConfirmDialogComponent, { data: { title: setting.display } }).afterClosed());
            if (doFactory) {
                const endpoint = this.getUpdateEndpoint(setting);
                try {
                    // normally we would want to set the message *after* we send the update but we want to ensure the
                    // message gets to the user before the backend is taken down
                    if (setting.endpoint === "factory_defaults") {
                        this.snackbar.open('Restoring factory defaults', 'Ok', { duration: 30 * 1000 });
                    }

                    await this.server._rawEndpointUpdate(this.getUpdateProtocol(setting), endpoint, {});

                    if (setting.config.upload_logs) {
                        this.snackbar.open("Logs have been sent to Boulder", "Ok", { duration: 15 * 1000 });
                    }
                } catch (e) {
                    console.error('Could not reset to factory defaults ', setting, ' error was ', e);
                }
            }
        } else {
            console.error("We don't know how to handle a the button setting of", setting);
        }
    }

    private async getAllWatcherAsync(setting: BaseSetting,
        { defaultValue = "Loading ...", input = null }:
            { defaultValue?: any, input?: null | Input } = {},
    ): Promise<BehaviorSubject<any>> {
        const settingsSpecVersion = await firstValueFrom(this.store.select(selectSettingsSpecVersion));
        if (settingsSpecVersion === 1) {
            return this.getSettingsSpecV1AllWatcher(setting.endpoint, setting.config, { defaultValue, input });
        } else {
            const bs = new BehaviorSubject(defaultValue);
            const allJsonPath = setting.all_json_path ?? `${setting.endpoint}.${setting.endpoint}`;

            if (setting.config?.app_version) {
                return new BehaviorSubject(version);

            } else if (input) {
                // you should basically always use a all_json_path for inputs but we don't special case it
                // so the api remains consistent
                this.subscriptions.push(
                    this.settings.rawAllData$.pipe(
                        map(allData => _get(allData, 'all.inputs', [])),
                        map(allInputs => allInputs.filter(i => i.index === input.index)[0] ?? {}),
                        map(inputData => _get(inputData, allJsonPath, defaultValue)),
                        distinctUntilChanged(),
                    ).subscribe(bs),
                );
            } else {
                this.subscriptions.push(
                    this.settings.rawAllData$.pipe(
                        map(all => _get(all, allJsonPath, defaultValue)),
                        distinctUntilChanged(),
                    ).subscribe(bs)
                );
            }
            return bs;
        }
    }


    // given an endpoint generate an observable for that path from our copy of the observable data
    private getSettingsSpecV1AllWatcher(
        endpoint: string,
        overrides: { [key: string]: true },
        { defaultValue = "Loading ...", input = null }:
            { defaultValue?: any, input?: null | Input } = {},
    ): BehaviorSubject<any> {
        const bs = new DistinctBehaviorSubject(defaultValue);
        let sub;
        if (input) {
            if (endpoint === 'rename_input') {
                endpoint = 'name';
            } else if (endpoint === 'input_trim') {
                endpoint = 'trim';
            } else if (endpoint === 'input_image') {
                endpoint = 'image_id';
            } else if (endpoint === 'input_theater_mode') {
                endpoint = 'theater_mode';
            }
            sub = this.inputs.options$.pipe(
                map(options => options.filter(option => option.index === input.index)[0]),
                map(input => _get(input, endpoint, defaultValue)),
            ).subscribe(bs)
        } else {
            let path = `all.${endpoint}.${endpoint}`;

            if (overrides.version) {
                path = 'all.version.cp_version'; // todo - check renderer version too
            } else if (overrides.app_version) {
                return new BehaviorSubject(version);
            }

            sub = this.settings.rawAllData$.pipe(
                map(all => _get(all, path, defaultValue)),
                distinctUntilChanged(),
            ).subscribe(bs);
        }
        this.subscriptions.push(sub);

        return bs;
    }

    getUpdateEndpoint(setting: BaseSetting) {
        return setting.post_url_path ?? setting.endpoint;
    }

    getUpdateProtocol(control: BaseSetting): "POST" | "PUT" {
        const usePut = (control as BaseSetting).config.use_put_for_update ?? false;
        return usePut ? "PUT" : "POST";
    }

    get c() { return this.settingsForm$.value.controls; }

    get __inputs__() { return this.c.__inputs__ as FormGroup; }

    genThumbLabel(isVolume: boolean, showDB: boolean) {
        return (val: number) => {
            return formatVolume(val, showDB, isVolume);
        }
    }

    // stuff for image upload
    onClickTakePicture(inputData: Input) {
        void /* await */ this.genericPhoneUpload(inputData, PictureSourceType.CAMERA);
    }

    onClickUseGallery(inputData: Input) {
        void /* await */ this.genericPhoneUpload(inputData, PictureSourceType.SAVEDPHOTOALBUM);
    }

    async genericPhoneUpload(inputData: Input, sourceType: PictureSourceType) {
        try {
            const picturePath = await this.takePicture(sourceType);
            this.snackbar.open("Processing image", "Ok", {duration: 20 * 1000});
            void await this.uploadImageForInput(picturePath, inputData);
        } catch (e) {
            console.error("Error uploading an image to server; may have additional logging", e);
        }
    }

    async onUseDefaultClick({ index: input_index }: Input) {
        void await this.server.putResetInputImage({ input_index });
        // we don't have the default image locally - so we will have to wait
        // for the server. However it should be quick as there is not upload
        // or image processing
    }

    async takePicture(sourceType: PictureSourceType): Promise<string> {
        const options: CameraOptions = {
            destinationType: this.camera.DestinationType.FILE_URI,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            correctOrientation: true,
            sourceType,
        }

        try {
            return await this.camera.getPicture(options);
        } catch (e) {
            console.error('Camera plugin error:', e);
            throw e;
        }
    }



    async uploadImageForInput(imageFilePath: string, inputData: Input): Promise<void> {
        console.log('going to post input image to server');
        const { index: input_index } = inputData;

        const resizedImageData = await this.resizeJPEG(imageFilePath, 600);

        const formData = new FormData();
        formData.append('input_index', `${input_index}`);
        formData.append('input_image', resizedImageData, 'upload.jpeg');
        try {
            await this.server.postInputImage(formData);
            console.log('finished posting input image - no errors');
        } catch (e) {
            console.error('Error posting input image', e);
            throw e;
        }
    }

    async resizeJPEG(fileName: string, maxDimension = 1200): Promise<Blob> {
        const entry = await this.file.resolveLocalFilesystemUrl(fileName);

        const folder: string = await new Promise((resolve) => entry.getParent((entryCallback) => resolve(entryCallback.nativeURL)));
        const file = entry.name;

        let dataUrl;
        try {
            dataUrl = await this.file.readAsDataURL(folder, file);
        } catch (e) {
            console.error("loading of image didn't work:", e);
            throw e;
        }

        // Load the image
        return new Promise((resolve) => {
            const image = new Image();
            image.onload = function (imageEvent) {

                // Resize the image
                const canvas = document.createElement('canvas');
                let width = image.width,
                    height = image.height;
                if (width > height) {
                    if (width > maxDimension) {
                        height *= maxDimension / width;
                        width = maxDimension;
                    }
                } else {
                    if (height > maxDimension) {
                        width *= maxDimension / height;
                        height = maxDimension;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                canvas.toBlob(resolve, "image/jpeg");
            }
            image.src = dataUrl;
        });
    }
}
