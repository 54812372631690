import { Injectable } from '@angular/core';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';

type IosDiscoveryDevice = {
    Location: string;
};

type AndroidDiscoveryDevice = {
    LOCATION: string;
    xml: string;
};

type DiscoveryReturn = Array<IosDiscoveryDevice | AndroidDiscoveryDevice>;

type ServiceDiscovery = {
    getNetworkServices(
        string,
        successCb: (data: DiscoveryReturn) => void,
        errorCb: (error: Error) => void
    );
};

export type NormalizedService = {
    location: string;
    xml: string;
};

export type NormalizedServices = Array<NormalizedService>;

declare const serviceDiscovery: ServiceDiscovery;
declare const device: {
    platform: 'Android' | 'iOS' | 'amazon-fireos';
};

export const reUrl = /http:\/\/([^:]+):\d+/;

@Injectable({
    providedIn: 'root',
})
export class DiscoveryService {
    constructor(private http: HTTP) {}

    private async getIosXml(device: IosDiscoveryDevice): Promise<string> {
        try {
            console.warn('trying to get data for ', device);
            // dlna stuff with no cors headers. Hence I need to break out of js to do their requests
            const httpData = await this.http.get(
                device.Location,
                { responseType: 'text' },
                {}
            );
            return httpData.data;
        } catch (e) {
            console.log(`Could not get data from ${device.Location}`, e);
            return Promise.resolve('');
        }
    }

    isGettingServices = false;
    async getServices(): Promise<NormalizedServices | null> {
        if (typeof serviceDiscovery === 'undefined') {
            console.warn(
                'serviceDiscovery is not loaded. Only works on phones'
            );
            return [];
        }

        // you only want to run one instance of getNetworkServices at a time
        if (this.isGettingServices) {
            return null;
        }

        let services: NormalizedServices = [];

        try {
            const rawServices: DiscoveryReturn = await new Promise(
                (resolve, reject) =>
                    serviceDiscovery.getNetworkServices(
                        'urn:av-boulderamp-com:service:BoulderNetDevice:1',
                        resolve,
                        reject
                    )
            );

            if ('iOS' === device.platform) {
                services = (
                    await Promise.all([
                        ...rawServices.map((s: IosDiscoveryDevice) =>
                            Promise.all([this.getIosXml(s), Promise.resolve(s)])
                        ),
                    ])
                ).map(([xml, s]: [string, IosDiscoveryDevice]) => {
                    return { location: s.Location, xml };
                });
            } else if (
                'Android' === device.platform ||
                'amazon-fireos' === device.platform
            ) {
                services = rawServices.map((s: AndroidDiscoveryDevice) => {
                    return { location: s.LOCATION, xml: s.xml };
                });
            } else {
                console.error(`${device.platform} is not supported!`);
                services = [];
            }
        } catch (e: any) {
            console.error(`Discovery has failed `, e);
        } finally {
            this.isGettingServices = false;
        }

        return services;
    }
}
