import { startAsyncAction } from '@amp/store/actions-in-progress/actions';
import { ActionsInProgressState } from '@amp/store/actions-in-progress/reducer';
import { selectActionsInProgress } from '@amp/store/actions-in-progress/selectors';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, skipWhile, switchMap, tap } from 'rxjs/operators';


@Injectable()
export class AsyncInterceptor implements HttpInterceptor {
    private aip: BehaviorSubject<number> = new BehaviorSubject(0);

    constructor(
        private store: Store<ActionsInProgressState>,
    ) {
        this.store.pipe(
            select(selectActionsInProgress),
        ).subscribe(this.aip);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isAsyncCall(request)) {
            return this.aip.pipe(
                // because skipwhile is using a function to check the actual .value (sync)
                // we know that only one of these can return at a time
                skipWhile(() => {
                    const isOk = this.aip.value === 0;
                    // this will be set by the startAsyncAction() below but i need to *know* its being
                    // changed synchroniously
                    if (isOk) {
                        this.aip.next(this.aip.value + 1);
                    }
                    return !isOk; // skipWhile
                }),
                first(),
                tap(() => this.store.dispatch(startAsyncAction())),
                // im a bit nervous that a poll could come in before we get to the pollInterceptor & disable
                // that ...; perhaps it doesnt matter so much because in a multi client situation we would
                // have no way to syncronously garuntee requests anyway
                switchMap(() => next.handle(request)),
            );
        } else {
            return next.handle(request);
        }
    }

    isAsyncCall(request: HttpRequest<any>): boolean {
        return (request.method === 'DELETE' && /playlist_delete_all$/.test(request.url)) ||
            (request.method === 'POST' && (
                /playlist_append_container$/.test(request.url) ||
                /playlist$/.test(request.url)
            ));
    }
}

export const asyncInterceptorProvider = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AsyncInterceptor,
        multi: true,
    }
]
