<div class="swiper-slider">
    <div class="blabs-swiper-wrapper">
        <swiper
            [config]="this.swiperConfig"
            [index]="this.slideIndex$ | async"
            (transitionEnd)="this.onTransitionEnd()"
            (activeIndexChange)="this.onIndexChange()"
            (touchStart)="this.onTouchStart()"
            (touchEnd)="this.onTouchEnd()"
        >
            <ng-template
                swiperSlide
                *ngFor="let volumeSlide of volumeSlides"
                class="volume-slide"
            >
                <div class="volume-number">
                    <div>
                        {{
                            volumeSlide | possiblevolume: (this.showDB$ | async)
                        }}
                    </div>
                </div>
            </ng-template>
        </swiper>
    </div>
    <div class="marker"></div>
    <div></div>
</div>
