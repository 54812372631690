import { Component, HostBinding } from '@angular/core';
import { DisplayTypeService } from './services/display-type.service';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(
        private displayType: DisplayTypeService
    ) { }

    @HostBinding('class.device') _d: boolean = this.displayType.isDevice();
    @HostBinding('class.phone') _p: boolean = this.displayType.isPhone();
    // aka includes regular web server
    @HostBinding('class.notdevice') _nd: boolean = !this.displayType.isDevice();
}
