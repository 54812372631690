import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { isEqual, values } from "lodash-es";
import { serverUpdateAll } from "../all/actions";
import { resetDeviceInfo } from "../global-actions";
import { Input, setSelectedInput } from "./actions";

export interface InputsState extends EntityState<Input> {
    selectedInputIndexId: number | null;
}

export const inputsAdapter = createEntityAdapter<Input>({
    selectId: (input: Input) => input.index,
    sortComparer: (a: Input, b: Input) => a.orderNumber - b.orderNumber,
});

const initialInputsState = inputsAdapter.getInitialState({ selectedInputIndex: null });

export const inputsReducer = createReducer(
    initialInputsState,
    on(serverUpdateAll, (state, { all: newAll }) => {
        const { inputs, selectedInputIndexId } = newAll;

        // we want to be careful on the updates
        // store.entity stores its data as values keyed on the selectId. Which kills the sort order
        // we have stored sort order as orderNumber so converting to set will allow for a full
        // compare
        let newState;
        if (isEqual(new Set(values(state.entities)), new Set(inputs))) {
            newState = { ...state, selectedInputIndexId: selectedInputIndexId };
        } else {
            newState = inputsAdapter.setAll(inputs as any, { ...state, selectedInputIndexId })
        }

        return newState;
    }),
    on(setSelectedInput, (state, { inputIndex }) => {
        return { ...state, selectedInputIndexId: inputIndex }
    }),
    on(resetDeviceInfo, () => initialInputsState),
)