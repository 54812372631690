import { Breadcrumb } from '@amp/browse/types';
import { gotoBreadcrumb } from '@amp/store/browse/actions';
import { BrowseState } from '@amp/store/browse/reducer';
import { selectBreadcrumbs } from '@amp/store/browse/selectors';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
    selector: 'amp-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
}) export class BreadcrumbsComponent implements OnInit {

    public breadcrumbs$ = this.store.select(selectBreadcrumbs);


    constructor(
        private store: Store<BrowseState>,
    ) {
    }

    ngOnInit(): void {

    }

    async onClickBreadcrumb(breadcrumb: Breadcrumb) {
        this.store.dispatch(gotoBreadcrumb({ breadcrumb }));
    }

}
