import { Breadcrumb, BrowseFile } from '@amp/browse/types';
import { resetDeviceInfo } from '@amp/store/global-actions';
import { MediaServer } from '@amp/types/media-server.data.types';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getFiles, gotAllFiles, gotFiles, gotoBreadcrumb, isNewFileOpts, markFileIconBroken } from './actions';



type browseFetchStatus = 'success' | 'requestedFiles' | 'requestedMoreFiles';

export interface BrowseState {
    desiredMediaServer: MediaServer, // should be eventually consistant with store.media_server.currentServer
    offset: number;
    path: string;
    gettingMoreFiles: boolean;
    filesES: EntityState<BrowseFile>;
    breadcrumbs: Readonly<Array<Breadcrumb>>;
}

export const filesAdapter: EntityAdapter<BrowseFile> = createEntityAdapter<BrowseFile>();

export const initialState: BrowseState = {
    desiredMediaServer: null,
    path: '0',
    offset: 0,
    gettingMoreFiles: false,
    filesES: filesAdapter.getInitialState(),
    breadcrumbs: [{ mediaServer: null, displayName: "Media Servers", path: null }],
};

export const browseReducer = createReducer(
    initialState,

    on(getFiles, (state, { mediaServer, path, opts }) => {
        if (isNewFileOpts(opts)) {
            const breadcrumbs = state.breadcrumbs.slice();
            breadcrumbs.push({ ...opts, path, mediaServer });
            return { ...state, desiredMediaServer: mediaServer, path, offset: 0, filesES: filesAdapter.removeAll(state.filesES), breadcrumbs, gettingMoreFiles: true };
        } else {
            return { ...state, ...opts };
        }
    }),
    on(gotFiles, (state, { mediaServer, path, offset, newFiles }) => {
        // if the media server, path or offset have changed we are no longer intrested
        if (mediaServer.uuid !== state.desiredMediaServer?.uuid || path !== state.path || offset !== state.offset) {
            return state;
        } else {
            return { ...state, filesES: filesAdapter.addMany(newFiles, state.filesES) };
        }
    }),
    on(gotAllFiles, (state) => ({ ...state, gettingMoreFiles: false })),


    on(gotoBreadcrumb, (state, { breadcrumb }) => {
        const bIdx = state.breadcrumbs.findIndex(b => b.mediaServer?.uuid === breadcrumb.mediaServer?.uuid && b.displayName === breadcrumb.displayName && b.path === breadcrumb.path);

        const newBreadcrumbs = state.breadcrumbs.slice(0, bIdx);

        return { ...state, breadcrumbs: newBreadcrumbs };
    }),

    on(markFileIconBroken, (state, { file }) => {
        return { ...state, filesES: filesAdapter.updateOne({ id: file.id, changes: { useDefault: true } }, state.filesES) };
    }),

    on(resetDeviceInfo, () => initialState),
);
