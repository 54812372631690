import { gotoBreadcrumb } from '@amp/store/browse/actions';
import { BrowseState } from '@amp/store/browse/reducer';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
    selector: 'amp-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
}) export class BreadcrumbComponent {
    @Input('breadcrumb') breadcrumb;

    constructor(
        private store: Store<BrowseState>,
    ) { }

    async onClickBreadcrumb() {
        this.store.dispatch(gotoBreadcrumb({ breadcrumb: this.breadcrumb }));
    }
}
