import { CurrentnetworkComponent } from '@amp/provisioning/currentnetwork/currentnetwork.component';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';

declare const WifiWizard2;

@Component({
    selector: 'app-joinnetwork',
    templateUrl: './joinnetwork.component.html',
    styleUrls: ['./joinnetwork.component.scss']
})
export class JoinnetworkComponent {
    @ViewChild(CurrentnetworkComponent) private currentNetwork: CurrentnetworkComponent;
    public networkLooksOk$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private router: Router,
    ) { }

    sub = null;
    ngAfterViewInit() {
        this.currentNetwork.network.pipe(
            map(network => /BoulderAmplifiers/.test(network)),
        ).subscribe(this.networkLooksOk$);

        this.networkLooksOk$.pipe(
            first(ok => ok),
        ).subscribe(() => {
            this.router.navigate(['/provisioning/getvalues']);
        });
    }
}
