import { DisplayTypeService } from '@amp/services/display-type.service';
import { InputService } from '@amp/services/input.service';
import { ServerService } from '@amp/services/server.service';
import { SettingsService } from '@amp/services/settings.service';
import { NOT_SUPPORTED, setMute, setVolume, updateHeadphoneOut } from '@amp/store/all/actions';
import { selectDacMode, selectHeadphoneOut, selectMute, selectSupportsNegInfVolume, selectVolume } from '@amp/store/all/selector';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash-es';
import { combineLatest, firstValueFrom, Subscription } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
}) export class FooterComponent implements OnInit {
    private subs: Array<Subscription> = [];
    public volume: FormControl = new FormControl(-50);
    public headphoneOut$ = this.store.select(selectHeadphoneOut);
    public headphoneOutSupported$ = this.headphoneOut$.pipe(map(ho => ho !== NOT_SUPPORTED));
    public mute$ = this.store.select(selectMute);
    hasNegInf$ = this.store.select(selectSupportsNegInfVolume);

    public headphoneVisibility$ = this.store.select(selectDacMode).pipe(
        map(isDac => isDac === true ? 'hidden' : undefined),
    );
    public visibility$ = combineLatest([
        this.input.selected$.pipe(startWith(null)),
        this.store.select(selectDacMode),
    ]).pipe(
        map(([i, dacMode]) => {
            const inTheaterMode = _get(i, 'theater_mode', false);
            const dacState = dacMode === true; // want 'UNKNOWN' to be falsy here
            return inTheaterMode || dacState;
        }),
        map(hideElments => hideElments ? 'hidden' : undefined),
    );

    constructor(
        public settings: SettingsService,
        private server: ServerService,
        private displayType: DisplayTypeService,
        private store: Store,
        private input: InputService,
    ) { }

    ngOnInit() {
        this.subs.push(
            this.volume.valueChanges.pipe(
                switchMap(async (uiVolume) => {
                    const storeVolume = await firstValueFrom(this.store.select(selectVolume));
                    return { storeVolume, uiVolume };
                }),
                filter(({ storeVolume, uiVolume }) => storeVolume !== uiVolume),
            ).subscribe(
                async ({ uiVolume }) => {
                    const oldVolume = await firstValueFrom(this.store.select(selectVolume));

                    try {
                        this.store.dispatch(setVolume({ volume: uiVolume }));
                        void await this.server.postVolume({ volume: uiVolume });
                    } catch (e) {
                        this.store.dispatch(setVolume({ volume: oldVolume }));
                    }
                }
            )
        );

        this.subs.push(
            this.store.select(selectVolume).subscribe(volume => {
                this.volume.patchValue(volume, { emitEvent: false });
            })
        );


    }
    ngOnDestroy() {
        for (const sub of this.subs) {
            sub.unsubscribe();
        }
    }

    // note there is an issue here. We know we have muted. We *dont* know the new volume
    // todo: use the poller to autoissue a new poller on ANY post/delete/put. is the server
    // sync with such things?
    async onClickMute() {
        if (this.displayType.isDevice()) {
            return
        }
        const currentMute = await firstValueFrom(this.store.select(selectMute));
        const mute = !currentMute;
        this.store.dispatch(setMute({ mute }));

        void await this.server.postMute({ mute });
        this.server.getAll();
    }

    // when POST headphone_out the server may not honor our request depending on dac mode
    // either way its response is the new value for headphone_out. so go ahead and set that now
    // instead of waiting for the /all poll
    async onClickHeadphone() {
        // we know it cant be NOT_SUPPORTED to even display this button
        const requestedHeadphoneOut = !(await firstValueFrom(this.store.select(selectHeadphoneOut)));
        const { headphone_out: serverHeadphoneOut } = await this.server.postHeadphoneOut({ headphone_out: requestedHeadphoneOut });
        this.store.dispatch(updateHeadphoneOut({ headphone_out: serverHeadphoneOut }));
    }
}
