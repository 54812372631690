import { DisplayTypeService } from '@amp/services/display-type.service';
import { MenuActionsService } from '@amp/services/menu-actions.service';
import { ServerService } from '@amp/services/server.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
    selector: 'app-standby',
    templateUrl: './standby.component.html',
    styleUrls: ['./standby.component.scss', '../common/header/header.common.component.scss']
})
export class StandbyComponent implements OnInit {

    constructor(
        public dt: DisplayTypeService,
        private router: Router,
        private server: ServerService,
        public menu: MenuActionsService,
    ) { }

    ngOnInit() {
    }

    userResumed = false;
    async resume() {
        void await this.server.postStandby({ standby: false });
        // no router link - instead we are listening to standby status as XFE

        this.userResumed = true;
        // we know we have sent the message but dont 100% rely on it coming up.
        // ie someone re-standbys before it comes up.
        setTimeout(() => {
            this.userResumed = false;
        }, 1000 * 20);
    }

}
