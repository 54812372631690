import { ServerService } from '@amp/services/server.service';
import { NOT_SUPPORTED } from '@amp/store/all/actions';
import { Component, OnInit } from '@angular/core';
import { get as _get } from 'lodash-es';
import QRCode from 'qrcode';
import { BehaviorSubject } from 'rxjs';




@Component({
    selector: 'app-popqrdisplay',
    templateUrl: './popqrdisplay.component.html',
    styleUrls: ['./popqrdisplay.component.scss']
}) export class PoPQRDisplayComponent implements OnInit {

    public qrText$: BehaviorSubject<string> = new BehaviorSubject(null);
    public qrImage$: BehaviorSubject<string> = new BehaviorSubject(null);

    constructor(
        private server: ServerService,
    ) { }

    ngOnInit() {
        // async
        this.doSetup();
    }

    async doSetup() {
        //  const popJson = await Promise.resolve({ 'proof_of_possession': '1234' });
        let popJson;
        try {
            popJson = await this.server.getProofOfPossession();
        } catch (error) {
            this.qrText$.next('ERROR: Could not get proof of possession');
            return
        }
        const pop = _get(popJson, "proof_of_possession", "ERROR: Proof of possession was malformed")

        // we should not route to this page in the first place, but if you do [manually?] dont break
        if (pop === NOT_SUPPORTED) {
            this.qrText$.next('This device does not have a Proof of Possession');
            this.qrImage$.next(null);
        } else {
            this.qrText$.next(pop);
            this.generateQRImage(pop);
        }
    }

    async generateQRImage(qrData: string) {
        // dark & light are inverted so it looks correct
        this.qrImage$.next(await QRCode.toDataURL(qrData, {
            width: 360,
            errorCorrectionLevel: 'high',
        }));
    }

}
