import { createFeatureSelector, createSelector } from '@ngrx/store';
import { storeName } from './actions';
import { PlaylistState } from './reducer';

const playlistSelector = createFeatureSelector<PlaylistState>(storeName);

export const selectOhTrackList = createSelector(
    playlistSelector,
    (state) => state.oh_track_list,
)

export const selectCurrentPlaylistTrackId = createSelector(
    playlistSelector,
    (state) => state.id,
);
