<div class="body">
    <swiper
        [config]="swiperConfig"
        (loopFix)="this.onLoopFix()"
        (slideChange)="this.onSlideChange()"
        (touchMove)="this.onTouchMove()"
        (touchEnd)="this.onTouchEnd()"
    >
        <ng-template
            swiperSlide
            class="slide"
            *ngFor="let input of allInputs$ | async; let i = index"
        >
            <div class="input" (pointerdown)="this.onSlidePointerDown(input)">
                <img class="input_image" [src]="input | inputImage$ | async" />

                <div
                    class="input_name"
                    [class.selected]="
                        (selectedInput$ | async)?.index === input.index
                    "
                >
                    {{ input.name }}
                </div>
            </div>
            <div
                class="input_select"
                [class.selected]="
                    (selectedInput$ | async)?.index === input.index
                "
            ></div>
        </ng-template>
    </swiper>
</div>
