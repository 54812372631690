import { Input } from '@amp/store/inputs/actions';
import { selectAllInputs, selectSelectedInput } from '@amp/store/inputs/selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ServerService } from './server.service';


export const isRoon = (input: Input | null): boolean => {
    return !!input && input.name === 'Roon Ready';
}

export const isRemoteService = (input: Input | null): boolean => {
    return !!input && ((input.name === 'Roon Ready') || (input.name === 'Bluetooth') || (input.name === 'Airplay'));
}

export const isNetwork = (input: Input | null): boolean => {
    return !!input && input.name === 'Network';
}

@Injectable({
    providedIn: 'root'
}) export class InputService {
    public options$ = this.store.select(selectAllInputs);
    public selected$ = this.store.select(selectSelectedInput);

    public isRoon$ = this.selected$.pipe(map(i => isRoon(i)));
    public isRemoteService$ = this.selected$.pipe(map(i => isRemoteService(i)));

    constructor(
        private server: ServerService,
        private store: Store,

    ) { }

    public getImageUrl$(input: Input): Observable<string> {
        return this.store.select(selectAllInputs).pipe(
            map((options) => options.find((i) => i.index === input.index)),
            switchMap((input) => {
                return from(this.server.getInputImageUrl({ image_id: input.image_id }));
            }),
        );
    }
}
