import { storeName } from '@amp/store/browse/actions';
import { BrowseEffects } from '@amp/store/browse/effects';
import { browseReducer } from '@amp/store/browse/reducer';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(storeName, browseReducer),
        EffectsModule.forFeature([BrowseEffects]),
    ]
}) export class BrowseModule { }
