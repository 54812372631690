import { PlaylistItem } from '@amp/playlist/playlist.types';
import { getArtists } from '@amp/services/now-playing.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getArtist'
}) export class GetArtistPipe implements PipeTransform {

    transform(value: PlaylistItem, ...args: unknown[]): unknown {
        return getArtists(value.people);
    }

}
