import { selectDefaultVolume, selectMaxVolume, selectVolume } from '@amp/store/all/selector';
import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { skip } from 'rxjs/operators';
import { InputService } from './input.service';
import { uiQuietPeriod } from './pollinterceptor.service';
import { ServerService } from './server.service';
import { SettingsService } from './settings.service';


@Injectable({
    providedIn: 'root'
}) export class PollerService {

    // autorouter item - use a reg behav subject
    public serverResponding$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private server: ServerService,
        ngZone: NgZone,
        private settings: SettingsService,
        private input: InputService,
        private store: Store,
    ) {
        ngZone.runOutsideAngular(() => {
            setInterval(
                () => {
                    ngZone.run(() => this.getAllValues())
                }, 250)
        })

        // maxVolume should alter its dependencies to cap them at the max volume
        this.store.select(selectMaxVolume).pipe(
            skip(1),
        ).subscribe(async mv => {
            const currentVolume = await firstValueFrom(this.store.select(selectVolume));
            const defaultVolume = await firstValueFrom(this.store.select(selectDefaultVolume));
            if (mv < currentVolume) {
                try {
                    /* void */ await this.server.postVolume({ volume: mv });
                } catch {
                    console.error('Volume is currently greater then max volume; setting volume downward failed');
                }
            }

            if (mv < defaultVolume) {
                try {
                    /* void */ await this.server.postVolumeDefault({ volume_default: mv });
                } catch {
                    console.error('Volume default is currently greater then max volume; setting max volume downward failed');
                }
            }

        })


        this.settings.server$.subscribe((serverName) => {
            this.serverResponding$.next(false);
            this.getAllValues();
        })
    }

    timeouts: number = 0;
    async getAllValues() {
        let all: any;
        try {
            const rawAllData = await this.server.getAll();
            this.timeouts = 0;
            this.settings.rawAllData$.next(rawAllData);
            this.serverResponding$.next(true);
        } catch (e) {
            console.log(e);
            if (!uiQuietPeriod) {
                this.timeouts++;
            }
            // be a bit more generous on timeouts
            if (this.timeouts > 5) {
                this.serverResponding$.next(false);
            }
        }
    }
}
