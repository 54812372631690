import { createFeatureSelector, createSelector } from "@ngrx/store";
import { storeName } from "./actions";
import { SettingsState } from "./reducer";

const selectSettingsState = createFeatureSelector<SettingsState>(storeName);

export const selectSupportedApis = createSelector(
    selectSettingsState,
    (sState) => sState.supportedApis
);

export const selectLoadedApi = createSelector(
    selectSettingsState,
    (sState) => sState.loadedApi,
);

export const selectSettingsSpecVersion = createSelector(
    selectSettingsState,
    (sState) => sState.settingsSpecVersion,
);