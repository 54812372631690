<mat-toolbar class="header" routerLink="/settings/network">
    <mat-icon>arrow_back</mat-icon>
    <h1>Reprovision WiFi</h1>
</mat-toolbar>
<form [formGroup]="this.form">
    <mat-form-field>
        <mat-label>Proof of Possession</mat-label>
        <input matInput formControlName="pop" />
        <app-barcode-scanner
            matSuffix
            (barcode)="this.c.pop.setValue($event)"
        ></app-barcode-scanner>

        <!-- <mat-error *ngIf="this.c.pop.invalid">Please enter your Proof of Possession code</mat-error> -->
    </mat-form-field>
    <mat-form-field>
        <!-- todo: handle unbroadcasted ssids -->
        <mat-label>Your WiFi</mat-label>
        <input matInput formControlName="ssid" [matAutocomplete]="ssidOpts" />
        <mat-autocomplete #ssidOpts="matAutocomplete">
            <mat-option
                *ngFor="let network of this.networks"
                [value]="network"
                >{{ network }}</mat-option
            >
        </mat-autocomplete>
        <mat-error *ngIf="this.c.ssid.invalid"
            >Please enter your wireless network's name</mat-error
        >
    </mat-form-field>
    <mat-form-field>
        <mat-label>WiFi Password</mat-label>
        <input matInput formControlName="password" type="password" />
        <mat-error *ngIf="this.c.password.invalid"
            >Please enter your WiFi's password</mat-error
        >
    </mat-form-field>
    <div>
        <button
            mat-flat-button
            color="accent"
            (click)="this.onReprovisionClick()"
            [disabled]="this.doingReprovision"
        >
            Reprovision
        </button>
        <div *ngIf="this.doingReprovision" class="doing-reprovision">
            <mat-spinner></mat-spinner>
            <div class="reprovision-message">
                Joining {{ this.c.ssid.value }}. This may take a few minutes.
            </div>
        </div>
    </div>
</form>
