<ng-container
    *ngFor="
        let b of this.breadcrumbs$ | async;
        index as index;
        count as count;
        last as last
    "
>
    <!-- ngSwitch doesnt actually have a break ... so we get to do some fun if nesting -->
    <ng-container *ngIf="index === 0; else case2">
        <amp-breadcrumb
            [breadcrumb]="b"
            class="media-servers"
            class="nonshrinkable"
        >
            <mat-icon
                inline
                fontSet="mdi"
                fontIcon="mdi-server-network"
            ></mat-icon>
        </amp-breadcrumb>
        <div class="divider nonshrinkable" *ngIf="index === 0 && !last">
            &nbsp;/&nbsp;
        </div>
    </ng-container>
    <ng-template #case2>
        <ng-container *ngIf="index === 1; else case3">
            <amp-breadcrumb [breadcrumb]="b" class="nonshrinkable">
                <!-- b.mediaServer.icon_uri -->
                <div
                    class="full"
                    *ngIf="!!b.mediaServer.icon_uri"
                    [style.background-image]="
                        'url(' + b.mediaServer.icon_uri + ')'
                    "
                    [class]="'ggl'"
                ></div>
                <mat-icon *ngIf="!b.mediaServer.icon_uri">folder</mat-icon>
            </amp-breadcrumb>
            <div class="divider nonshrinkable" *ngIf="!last">&nbsp;/&nbsp;</div>
        </ng-container>
    </ng-template>
    <ng-template #case3>
        <amp-breadcrumb
            [breadcrumb]="b"
            *ngIf="index === count - 1; else case4"
            class="folder1"
            >{{ b.displayName }}</amp-breadcrumb
        >
    </ng-template>
    <ng-template #case4>
        <ng-container *ngIf="index === count - 2; else case5">
            <amp-breadcrumb [breadcrumb]="b" class="folder2">{{
                b.displayName
            }}</amp-breadcrumb>
            <div class="divider nonshrinkable" *ngIf="!last">&nbsp;/&nbsp;</div>
        </ng-container>
    </ng-template>
    <ng-template #case5>
        <ng-container *ngIf="index === 2">
            <div class="nonclickable nonshrinkable">...</div>
            <div class="divider nonshrinkable" *ngIf="!last">&nbsp;/&nbsp;</div>
        </ng-container>
    </ng-template>
</ng-container>
