import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';


// mutex$ MUST be initialized to false
export const lock = function (mutex$: BehaviorSubject<boolean>) {
    return function (target, key, descriptor) {
        const origMethod = descriptor.value
        descriptor.value = async function (...args: any[]) {
            // when the mutex$ goes to unlocked all watchers are notified / scheduled
            // only one will actually get onto the stack [first]. It will take the lock.
            // it may yield at which point other people who got that orig lock will be
            // run. They will then see that the lock was taken and reschedule themselves
            // to go for the lock
            do {
                await mutex$.pipe(first((locked: boolean) => !locked)).toPromise()
            } while (mutex$.value)
            mutex$.next(true)

            let result = undefined
            try {
                result = await origMethod.apply(this, args)
            } catch (e) {
                mutex$.next(false)
                throw e
            }

            mutex$.next(false)
            return result
        }
        return descriptor;
    }
}
