import { playlistReducer } from '@amp/store/playlist/reducer';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { storeName } from './actions';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(storeName, playlistReducer),
    ]
}) export class PlaylistModule { }
