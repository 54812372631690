import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { DisplayType, DisplayTypeService } from './services/display-type.service';


@Directive({
    selector: '[appOnlyDisplayOn]'
}) export class OnlyDisplayOnDirective {
    private hasView = false;

    constructor(private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private dt: DisplayTypeService) { }

    //@HostListener('window.resize')
    @Input()
    set appOnlyDisplayOn(condition: DisplayType) {
        if (this.dt.conditionValid(condition) && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!this.dt.conditionValid(condition) && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }


}
