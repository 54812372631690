import { InputService } from '@amp/services/input.service';
import { Input } from '@amp/store/inputs/actions';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({
    name: 'inputImage$'
}) export class InputImage$Pipe implements PipeTransform {

    constructor(
        private inputService: InputService,
    ) { }

    transform(input: Input): Observable<string> {
        return this.inputService.getImageUrl$(input);
    }

}