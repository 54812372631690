<div>
    Now that we have joined the Boulder Device's network we need to enter in
    your Proof of Possession.
    <ng-container *ngIf="this.p.hasDisplay === true">
        &nbsp;Take a picture of the QR code on the Front Panel. You can use the
        photo button on the Proof of Possession entry box.
    </ng-container>

    <ng-container *ngIf="this.p.hasDisplay === false">
        &nbsp;Your device will speak a six digit code; this is your proof of
        possession. Enter it in the textbox below.
    </ng-container>
</div>

<mat-progress-bar
    *ngIf="this.p.hasDisplay === null"
    mode="indeterminate"
></mat-progress-bar>

<!-- spellcheck="false" is important here -->
<!-- https://stackoverflow.com/questions/45509709/how-to-disable-smart-quotes-for-textarea-fields-in-the-browser -->
<form>
    <mat-form-field>
        <mat-label>Proof of Possession</mat-label>
        <input
            matInput
            required
            [(ngModel)]="this.p.pop"
            name="pop"
            [errorStateMatcher]="this.getvaluesErrorState"
            autocorrect="off"
            autocapitalize="none"
            spellcheck="false"
        />
        <app-barcode-scanner
            matSuffix
            (barcode)="this.p.pop = $event"
            *ngIf="this.p.hasDisplay"
        ></app-barcode-scanner>
        <mat-error>Your proof of possession is required</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Your WiFi</mat-label>
        <input
            matInput
            required
            [(ngModel)]="this.p.ssid"
            [matAutocomplete]="ssidOpts"
            name="ssid"
            [errorStateMatcher]="this.getvaluesErrorState"
            autocorrect="off"
            autocapitalize="none"
            spellcheck="false"
        />
        <mat-autocomplete #ssidOpts="matAutocomplete">
            <mat-option
                *ngFor="let network of this.networks | async"
                [value]="network"
                >{{ network }}</mat-option
            >
        </mat-autocomplete>
        <mat-icon matSuffix>arrow_drop_down</mat-icon>
        <mat-error>Please enter your network's name here</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Password</mat-label>
        <input
            matInput
            [(ngModel)]="this.p.password"
            name="password"
            autocorrect="off"
            autocapitalize="none"
            spellcheck="false"
        />
    </mat-form-field>
</form>

<button mat-flat-button color="primary" (click)="onClickJoinNetwork()">
    Join network
</button>
