<app-header>
    <mat-menu #ampMenu="matMenu">
        <a
            (click)="this.menu.onClickSelectDevice()"
            *appOnlyDisplayOn="'NOTDEVICE'"
            ><button mat-menu-item>Select Boulder Device</button></a
        >
        <a routerLink="/settings"><button mat-menu-item>Settings</button></a>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="ampMenu">
        <mat-icon inline>menu</mat-icon>
    </button>
    <button mat-button routerLink="/input/show">
        <mat-icon inline svgIcon="amp:select"></mat-icon>
    </button>
    <button mat-button (click)="this.menu.onClickStandby()">
        <mat-icon inline>power_settings_new</mat-icon>
    </button>
</app-header>

<div class="body">
    <amp-breadcrumbs></amp-breadcrumbs>

    <cdk-virtual-scroll-viewport itemSize="50">
        <mat-action-list>
            <button
                mat-list-item
                *ngIf="
                    !(this.gettingMoreFiles$ | async) &&
                    (this.browsables$ | async).length < 1
                "
            >
                No files found.
            </button>

            <button
                mat-list-item
                *cdkVirtualFor="let file of this.browsables$ | async"
                (click)="this.onClickPath(file)"
            >
                <ng-container
                    *ngTemplateOutlet="
                        !!file.friendly_name ? MediaServer : File;
                        context: { file: file }
                    "
                ></ng-container>
            </button>
        </mat-action-list>
    </cdk-virtual-scroll-viewport>
</div>

<div class="loading-more-bar" *ngIf="this.gettingMoreFiles$ | async">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="actions">
    <button
        mat-button
        *ngIf="
            !(this.input.isRemoteService$ | async) &&
            !this.displayType.isDevice()
        "
        routerLink="/nowplaying_player"
    >
        <mat-icon inline>music_note</mat-icon>
    </button>

    <button
        mat-button
        *ngIf="
            !(this.input.isRemoteService$ | async) &&
            !this.displayType.isDevice()
        "
        routerLink="/playlist"
    >
        <mat-icon inline fontSet="mdi" fontIcon="mdi-playlist-music"></mat-icon>
    </button>
</div>

<app-footer></app-footer>

<ng-template #MediaServer let-server="file">
    <img
        mat-list-avatar
        [src]="server.icon_uri"
        (error)="this.onServerImgLoadError(server)"
        *ngIf="!!server.icon_uri; else BrokenFolderIcon"
    />
    <div class="filename">{{ server.friendly_name }}</div>
</ng-template>

<ng-template #BrokenFolderIcon>
    <mat-icon mat-list-avatar>folder</mat-icon>
</ng-template>

<ng-template #File let-file="file">
    <mat-menu #extraActionsMenu="matMenu">
        <button mat-menu-item (click)="addAndPlay(file)">Add and Play</button>
        <button mat-menu-item (click)="replaceAndPlay(file)">
            Replace and Play
        </button>
        <button mat-menu-item (click)="addAndPlayAfterCurrent(file)">
            Play Next
        </button>
    </mat-menu>

    <mat-icon mat-list-avatar *ngIf="!file.uri || file.useDefault">{{
        file.is_container ? 'folder' : 'music_note'
    }}</mat-icon>
    <img
        mat-list-avatar
        *ngIf="file.uri && !file.useDefault"
        [src]="file.didl | getAlbumUri"
        (error)="this.onFileImgLoadError(file)"
    />
    <div class="filename">{{ file.title }}</div>
    <button
        mat-icon-button
        (click)="$event.stopPropagation()"
        [matMenuTriggerFor]="extraActionsMenu"
    >
        <mat-icon>more_vert</mat-icon>
    </button>
    <button mat-icon-button class="add-all" (click)="this.add($event, file)">
        <mat-icon
            fontSet="mdi"
            fontIcon="mdi-music-note-plus"
            inline
        ></mat-icon>
    </button>
</ng-template>
