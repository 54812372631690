import { NOT_SUPPORTED, serverUpdateAll } from '@amp/store/all/actions';
import { resetDeviceInfo } from '@amp/store/global-actions';
import { MediaServer } from '@amp/types/media-server.data.types';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { markMediaServerIconBroken } from './actions';


export const serverInfoListAdapter = createEntityAdapter<MediaServer>({
    selectId: (ms) => ms.uuid,
});

export interface MediaServerState extends EntityState<MediaServer> {
    currentServerUUID: string | null;
    brokenServerIconUUIDS: Readonly<Array<string>>;
}

export const initialState: MediaServerState = serverInfoListAdapter.getInitialState({
    currentServerUUID: null,
    brokenServerIconUUIDS: [],
});

export const mediaServerReducer = createReducer(
    initialState,
    on(serverUpdateAll, (state, { all }) => {
        const { media_server: newMediaServerData } = all;

        // afe do not have this api
        if (!newMediaServerData || newMediaServerData === NOT_SUPPORTED) {
            return state;
        }

        let updatedState = serverInfoListAdapter.upsertOne(newMediaServerData.current_server, state);
        updatedState = serverInfoListAdapter.upsertMany(newMediaServerData.server_info_list, state);

        // sadly upsert (update) generates a *new* state even when the update is a noop; so we will have to check it
        // ourselves. If isEqual() return orig state. It is particuarly important here because this update happens
        // on every poll loop. We do NOT want to update the ui if we can avoid it
        if (isEqual(updatedState, state)) {
            updatedState = state;
        }
        return {
            ...updatedState, currentServerUUID: newMediaServerData.current_server?.uuid ?? null
        };
    }),

    on(markMediaServerIconBroken, (state, { mediaServer }) => {
        if (!state.brokenServerIconUUIDS.includes(mediaServer.uuid)) {
            return {
                ...state, brokenServerIconUUIDS: [...state.brokenServerIconUUIDS].concat(mediaServer.uuid)
            };
        } else {
            return state;
        }

    }),
    on(resetDeviceInfo, () => initialState),
);
