import { DisplayTypeService } from '@amp/services/display-type.service';
import { InputService } from '@amp/services/input.service';
import { MenuActionsService } from '@amp/services/menu-actions.service';
import { ServerService } from '@amp/services/server.service';
import { SettingsService } from '@amp/services/settings.service';
import { setTransportState } from '@amp/store/all/actions';
import { selectModelName, selectMute, selectShowDB, selectTransportState, selectVolume } from '@amp/store/all/selector';
import { CurrentTrackState } from '@amp/store/current-track/reducer';
import { selectCurrentTrack, selectTrackProgress } from '@amp/store/current-track/selectors';
import { selectServerInfoList } from '@amp/store/media-server/selectors';
import { TimeDeltaPipe } from '@amp/time-delta.pipe';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';


const isMouseEvent = (event: MouseEvent | TouchEvent): event is MouseEvent => (event as MouseEvent).screenX !== undefined

@Component({
    selector: 'app-player',
    templateUrl: './player.component.html',
    styleUrls: ['./player.component.scss', '../../common/header/header.common.component.scss']
}) export class PlayerComponent implements OnInit {
    volume: FormControl;
    private subs: Array<Subscription> = [];
    private timeDeltaPipe = new TimeDeltaPipe();
    public timeDelta = this.timeDeltaPipe.transform;
    public volume$ = this.store.select(selectVolume);
    public model$ = this.store.select(selectModelName);
    mute$ = this.store.select(selectMute);
    showDB$ = this.store.select(selectShowDB);

    // since there is no set track progress api might as well disable for all atm
    public progressEditable$ = new BehaviorSubject(false);
    public trackTime = new FormControl(0);

    public isPlaying$ = this.store.select(selectTransportState).pipe(
        map(s => s === 'Playing')
    );


    public currentTrack$ = this.store.pipe(select(selectCurrentTrack), distinctUntilChanged((a, b) => isEqual(a, b)));
    public mediaServers$ = this.store.pipe(select(selectServerInfoList));

    public showControls$ = this.input.selected$.pipe(
        map(input => !!input && !['Bluetooth', 'Airplay'].includes(input.name)),
    );

    constructor(
        public settings: SettingsService,
        public server: ServerService,
        public router: Router,
        public input: InputService,
        public displayType: DisplayTypeService,
        public store: Store<CurrentTrackState>,
        public menu: MenuActionsService,
    ) { }

    ngOnInit() {
        this.subs.push(
            this.store.pipe(select(selectTrackProgress)).subscribe(s => this.trackTime.patchValue(s, { emitEvent: false }))
        );

        this.subs.push(
            // this.trackTime.valueChanges.pipe(
            //     filter(s => s !== this.nowPlaying.trackProgress$.value)
            // ).subscribe(
            //     async (s: number) => {
            //         // the api endpoint doesnt exist yet
            //         // try {
            //         // this.nowPlaying.trackProgress$.next(s);
            //         // void await this.server.post('?i_dont_exist_yet?', {s});
            //         // } catch (e) { /* ?? */ }
            //     }
            // )
        );
    }

    ngOnDestroy() {
        for (const sub of this.subs) {
            sub.unsubscribe();
        }
    }

    gotoPlaylist() {
        this.router.navigate(['/playlist']);
    }

    private pointerDown = false;
    private startX: number;
    private startY: number;
    private pointerStartMs: number;
    onDown(event: MouseEvent | TouchEvent) {
        if (this.pointerDown) { return }

        this.pointerDown = true;
        this.pointerStartMs = new Date().valueOf();
        if (isMouseEvent(event)) {
            this.startX = event.screenX;
            this.startY = event.screenY;
        } else {
            const firstTouch = event.touches[0];
            this.startX = firstTouch.screenX;
            this.startY = firstTouch.screenY;
        }

        // touchEventUp does not have any location info; if there is no movement
        // make sure we have a end location defined
        this.endX = this.startX;
        this.endY = this.startY;
    }

    private endX: number;
    private endY: number;
    trackTouch(event: TouchEvent) {
        const firstTouch = event.touches[0];
        this.endX = firstTouch.screenX;
        this.endY = firstTouch.screenY;
    }

    onUp(event: MouseEvent | TouchEvent) {
        if (!this.pointerDown) { return }

        this.pointerDown = false;
        const endTime = new Date().valueOf();

        if (isMouseEvent(event)) {
            this.endY = event.screenY;
            this.endX = event.screenX;
        }
        const deltaX = this.startX - this.endX;
        const deltaY = this.startY - this.endY;

        // determine if its a horizontal swipe
        if (Math.abs(deltaY) > Math.abs(deltaX) || Math.abs(deltaX) < 40) {
            return;
        }

        if (this.displayType.isDevice()) {
            return;
        }

        this.gotoPlaylist();
    }

    onClickSelectDevice() {
        this.settings.loggedIn$.next(false);
        this.router.navigate(['/signin']);
    }

    onClickSkipPrevious() {
        this.server.postPrevious();
    }

    async onClickPlay() {
        this.store.dispatch(setTransportState({ transportState: 'Playing' }));
        this.server.postPlay();
    }

    onClickPause() {
        this.store.dispatch(setTransportState({ transportState: 'Paused' }));
        this.server.postPause();
    }

    onClickSkipNext() {
        this.server.postNext();
    }
}
