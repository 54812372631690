type Person = { name: string, role: string };

export const getArtists = (artists: Array<Person>) => {
    const names = Array.from(new Set(artists.map(p => p.name)));
    let artistStr = names[0];
    if (names.length > 1) {
        artistStr = `${names.slice(0, -1).join(', ')}, and ${names.slice(-1)}`;
    }
    return artistStr;
}


