import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeDelta'
}) export class TimeDeltaPipe implements PipeTransform {

    transform(value: number, ...args: any[]): any {
        const min = Math.floor(value / 60);
        const sec = value - (min * 60);
        return `${min}:${sec.toString().padStart(2, '0')}`
    }

}
