import { OhTrackListData } from '@amp/playlist/playlist.types';
import { TransportState } from '@amp/services/product-apis/all-apis';
import { CurrentTrackData } from '@amp/types/current-track.data.types';
import { MediaServerApiData } from '@amp/types/media-server.data.types';
import { createAction, props } from '@ngrx/store';
import { Input } from '../inputs/actions';

export const storeName = 'all';

export const NOT_SUPPORTED = Symbol('This api does not have this value');
export type T_NS = typeof NOT_SUPPORTED;

export type ApiType = 'preamp' | 'dac';

export type StandardizedDeviceAllApi = Readonly<{
    headphone_out: boolean | T_NS,
    dac_mode: boolean | T_NS,
    bluetooth_connected: boolean | T_NS,
    bluetooth_paired: boolean | T_NS,

    model: string,
    volume: number,

    inputs: ReadonlyArray<Input>,
    selectedInputIndexId: number | null, // should not be null but it can happen
    showPopQr: boolean | T_NS,
    isProvisioning: boolean | T_NS,

    actionsInProgress: number,
    volumeStepSize: number, // -0.5 && 1 are only allowed values


    // things that came from settings.service
    showDB: boolean,
    mute: boolean | T_NS,
    soft: boolean | T_NS,
    maxVolume: number,
    volumeDefault: number,
    deviceIpAddress: string | T_NS,
    deviceMacAddress: string | T_NS,
    deviceSSID: string | T_NS,
    deviceNetworkStatus: string | T_NS,
    standby: boolean,
    currentServerIconUri: string | T_NS,
    currentServerName: string | T_NS,

    // things that came from nowplaying.serivce
    transportState: TransportState | null | T_NS,

    // things that came from update.service
    updateVersion: string | null,
    updateInProgress: boolean,
    updateProgressString: string,
    updateProgressPercent: string, // string not number!

    current_track: CurrentTrackData | T_NS,
    playlist: {
        oh_track_list: OhTrackListData,
        id: number,
    } | T_NS,

    media_server: MediaServerApiData | T_NS,
}>

export const serverUpdateAll = createAction(`[${storeName}] server update`, props<{ all: StandardizedDeviceAllApi, apiType: ApiType }>());
export const updateHeadphoneOut = createAction(`${storeName}.updateHeadphoneOut`, props<{ headphone_out: boolean }>());

export const setVolume = createAction(`${storeName}.setVolume`, props<{ volume: number }>());

export const setMute = createAction(`${storeName}.mute`, props<{ mute: boolean }>());

export const setTransportState = createAction(`${storeName}.setTransportState`, props<{ transportState: TransportState }>());
export const setStandby = createAction(`${storeName}.setStandby`, props<{ standby: boolean }>());
