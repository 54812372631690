import { BrowseFile } from '@amp/browse/types';
import { selectCurrentMediaServer, selectServerInfoList } from '@amp/store/media-server/selectors';
import { MediaServer } from '@amp/types/media-server.data.types';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { storeName } from './actions';
import { BrowseState, filesAdapter } from './reducer';



export const selectBrowseState = createFeatureSelector<BrowseState>(storeName);

export const selectBreadcrumbs = createSelector(
    selectBrowseState,
    (state) => state.breadcrumbs,
);

export const selectPath = createSelector(
    selectBrowseState,
    (state) => state.path,
);

export const selectDesiredMediaServer = createSelector(
    selectBrowseState,
    (state) => state.desiredMediaServer,
);

const selectOffset = createSelector(
    selectBrowseState,
    (state) => state.offset,
);


export const selectFolderBrowseState = createSelector(
    selectCurrentMediaServer,
    selectPath,
    selectOffset,
    (mediaServer, path, offset) => ({ mediaServer, path, offset }),
);

const selectFilesES = createSelector(
    selectBrowseState,
    (state) => state.filesES,
);

const { selectAll: selectFiles } = filesAdapter.getSelectors(selectFilesES);
export const selectGettingMoreFiles = createSelector(
    selectBrowseState,
    (state) => state.gettingMoreFiles,
);

type Browsable = BrowseFile | MediaServer;
export const selectBrowsables = createSelector(
    selectDesiredMediaServer,
    selectServerInfoList,
    selectFiles,
    (desiredMediaServer, mediaServers, files): Array<Browsable> => !desiredMediaServer ? mediaServers : files
);
