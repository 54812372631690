import { createAction, props } from "@ngrx/store";

export const storeName = 'inputs';


export type Input = Readonly<{
    name: string,
    index: number,
    type: string,
    trim: number,
    //    current: boolean,
    image_id: string,
    theater_mode?: boolean,
    visible: boolean,
    orderNumber: number,
}>;


export const setSelectedInput = createAction(`${storeName}.setSelected`, props<{ inputIndex: number }>());