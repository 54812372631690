import { NOT_SUPPORTED, serverUpdateAll } from '@amp/store/all/actions';
import { resetDeviceInfo } from '@amp/store/global-actions';
import { CurrentTrackData } from '@amp/types/current-track.data.types';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

export interface CurrentTrackState {
    current_track: CurrentTrackData;
}

export const initialState: CurrentTrackState = {
    current_track: {
        track: {
            people: [],
            uri: '',
            cover_art_uri: '',
            title: 'Loading ...',
            album: null,
            duration: 0,
        },
        seconds: 0,
        bit_depth: null,
        sample_rate: null,
    }
}


export const currentTrackReducer = createReducer(
    initialState,
    on(serverUpdateAll, (state, { all }) => {
        const { current_track } = all;

        // afe
        if (!current_track || current_track === NOT_SUPPORTED) {
            return state;
        }

        const currentClone = cloneDeep(current_track);
        if (currentClone.bit_depth === 9) {
            currentClone.bit_depth = null;
            currentClone.sample_rate = null;
        }
        return { ...state, current_track: currentClone };
    }),
    on(resetDeviceInfo, () => initialState),
);
