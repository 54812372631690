import { ServerService } from '@amp/services/server.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { first, map, retry } from 'rxjs/operators';
import { ProvisioningService } from '../provisioning.service';

declare const WifiWizard2;

// constants for registration use
// because we are throwing up an ad-hoc network we know & can garuntee the ip is always the same
const ip = '192.168.0.2';
const port = '1234';
const url = `http://${ip}:${port}`;

class GetvaluesErrorState implements ErrorStateMatcher {
    public formSubmitted = false;

    isErrorState(control: FormControl) {
        const componentInError = control && control.invalid;
        return componentInError && (control.touched || this.formSubmitted);
    }
}

@Component({
    selector: 'app-getvalues',
    templateUrl: './getvalues.component.html',
    styleUrls: ['./getvalues.component.scss'],
})
export class GetValuesComponent implements OnInit {
    public networks: BehaviorSubject<Array<string>> = new BehaviorSubject([
        'Loading ...',
    ]);
    public getvaluesErrorState: GetvaluesErrorState = new GetvaluesErrorState();
    private serial: BehaviorSubject<string> = new BehaviorSubject(null);

    constructor(
        public p: ProvisioningService,
        private server: ServerService,
        private http: HttpClient,
        private router: Router
    ) {}

    async ngOnInit() {
        this.http
            .get(`${url}/ssids`)
            .pipe(
                retry(3),
                map((r: any) => r.ssids),
                first()
            )
            .subscribe(this.networks);

        // hasDisplay has three possiblities
        // newest / current: hasDisplay can be found on crypto_server.py
        // older: hasDisplay can be found on the normal cp server
        // oldest: no hasDisplay api exists. this implys that hasDisplay=true as all devices had a display at this point

        try {
            this.p.hasDisplay =
                (
                    await lastValueFrom(
                        this.http.get<any>(`${url}/has_display`).pipe(retry(3))
                    )
                )?.has_display ?? false;
        } catch {
            try {
                this.p.hasDisplay =
                    (await this.server.getHasDisplay())?.has_display ?? false;
            } catch (e) {
                this.p.hasDisplay = true;
            }
        }

        if (this.p.hasDisplay) {
            this.server.postShowPopQR({ show_pop_qr: true });
            this.server.postStandby({ standby: false });
        } else {
            /*no await */ lastValueFrom(
                this.http.put(`${url}/read-pop`, {}).pipe(retry(3))
            );
        }
    }

    async onClickJoinNetwork() {
        this.getvaluesErrorState.formSubmitted = true;

        if (this.p.pop.length < 1 || this.p.ssid.length < 1) {
            return;
        }

        // we can get the serial as the suffix part of the temp provisioning ssid
        const currentNetwork = await WifiWizard2.getConnectedSSID();
        this.p.serial = currentNetwork.match(/BoulderAmplifiers-(.*)/)[1];

        this.router.navigate([`/provisioning/waitsuccess`]);
    }
}
