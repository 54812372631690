import { BrowseFile } from '@amp/browse/types';
import { ServerService } from '@amp/services/server.service';
import { BrowseState } from '@amp/store/browse/reducer';
import { selectCurrentMediaServer, selectServerInfoList } from '@amp/store/media-server/selectors';
import { MediaServer } from '@amp/types/media-server.data.types';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError, timer } from 'rxjs';
import { first, map, mergeMap, retryWhen, switchMap } from 'rxjs/operators';



export class ChangedMediaServerError extends Error {
    constructor() { super('Changed media server'); }
}

export type GetFileResult = {
    media_items: Array<BrowseFile>,
    next: string,
}

@Injectable({
    providedIn: 'root'
}) export class BrowseService {
    // public mediaServer$: Subject<string> = new BehaviorSubject(undefined);
    // public currentContainerId$: Subject<string | Symbol> = new BehaviorSubject(undefined);

    // the case we know about this is when the media server is spinning up
    private dfeMediaServerError = 'dfe says the media server timed out';

    constructor(
        private server: ServerService,
        private store: Store<BrowseState>,
    ) { }

    getMediaServers(): Observable<Array<MediaServer>> {
        return this.store.pipe(select(selectServerInfoList));
    }

    getFiles(mediaServer: MediaServer, path: string, offset: number): Observable<GetFileResult> {
        return this.store.pipe(
            select(selectCurrentMediaServer),
            first(),
            switchMap(current => {
                if (current?.uuid === mediaServer?.uuid) {
                    return this.server.getBrowseObs({ params: { container_id: path, start_position: offset } }).pipe(
                        map(r => {
                            if (r.media_items === null) {
                                throw this.dfeMediaServerError;
                            } else {
                                return r;
                            }
                        }),
                        retryWhen(errors => errors.pipe(
                            mergeMap((error, i) => {
                                const retryAttempt = i + 1;

                                if (retryAttempt > 5 || error !== this.dfeMediaServerError) {
                                    return throwError(error);
                                }

                                console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt}s`);

                                return timer(retryAttempt * 1000);
                            }),
                        )),
                    );
                } else {
                    return throwError(new ChangedMediaServerError());
                }
            }),
        )
    }

    setMediaServer(mediaServer: MediaServer) {
        this.server.postMediaServer({ server_uuid: mediaServer.uuid });
    }

    getNextOffset(nextUrl: string) {
        return +new URL(nextUrl, this.server.getBaseUrl()).searchParams.get('start_position');
    }
}
