<mat-toolbar class="header" routerLink="/settings">
    <mat-icon>arrow_back</mat-icon>
    <h1>Proof of Possession</h1>
</mat-toolbar>

<div class="data">
    <img
        *ngIf="this.qrImage$ | async as qrImage"
        [src]="qrImage"
        class="image"
    />
    <div class="text">Or for manual entry: {{ this.qrText$ | async }}</div>
</div>
