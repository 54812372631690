import { AppRoutingModule } from '@amp/app-routing.module';
import { AppComponent } from '@amp/app.component';
import { BarcodeScannerComponent } from '@amp/barcode-scanner/barcode-scanner.component';
import { BrowseComponent } from '@amp/browse/browse.component';
import { FooterComponent } from '@amp/common/footer/footer.component';
import { HeaderComponent } from '@amp/common/header/header.component';
import { InputBaseComponent } from '@amp/input-base/input-base.component';
import { InputShowComponent } from '@amp/input-show/input-show.component';
import { NetworkReprovisionComponent } from '@amp/network-reprovision/network-reprovision.component';
import { NetworkSettingsComponent } from '@amp/network-settings/network-settings.component';
import { PlayerComponent } from '@amp/now-playing/player/player.component';
import { OnlyDisplayOnDirective } from '@amp/only-display-on.directive';
import { PlaylistComponent } from '@amp/playlist/playlist.component';
import { PoPQRDisplayComponent } from '@amp/popqrdisplay/popqrdisplay.component';
import { BaseComponent as ProvisioningBaseComponent } from '@amp/provisioning/base/base.component';
import { CurrentnetworkComponent } from '@amp/provisioning/currentnetwork/currentnetwork.component';
import { GetValuesComponent } from '@amp/provisioning/getvalues/getvalues.component';
import { JoinnetworkComponent } from '@amp/provisioning/joinnetwork/joinnetwork.component';
import { WaitSuccessComponent } from '@amp/provisioning/wait-success/wait-success.component';
import { asyncInterceptorProvider } from '@amp/services/asyncinterceptor.service';
import { AutorouterService } from '@amp/services/autorouter.service';
import { PollerService } from '@amp/services/poller.service';
import { pollInterceptorProvider } from '@amp/services/pollinterceptor.service';
import { SettingsService } from '@amp/services/settings.service';
import { ConfirmDialogComponent } from '@amp/settings/confirm-dialog/confirm-dialog.component';
import { PossibleVolumePipe } from '@amp/settings/possiblevolume.pipe';
import { ScreenSettingGetRoutePipe } from '@amp/settings/screen-setting-get-route.pipe';
import { SettingsComponent } from '@amp/settings/settings.component';
import { SigninComponent } from '@amp/signin/signin.component';
import { StandbyComponent } from '@amp/standby/standby.component';
import { RootStoreModule } from '@amp/store/root-store.module';
import { SwiperSliderComponent } from '@amp/swiper-slider/swiper-slider.component';
import { TimeDeltaPipe } from '@amp/time-delta.pipe';
import { GetArtistPipe } from '@amp/types/get-artist.pipe';
import {
    ConfirmUpdateDialog,
    UpdateComponent
} from '@amp/update/update.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, Router } from '@angular/router';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ReactiveComponentModule } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { combineLatest, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { SwiperModule } from 'swiper/angular';
import { BluetoothSettingsComponent } from './bluetooth-settings/bluetooth-settings.component';
import { BreadcrumbComponent } from './browse/breadcrumbs/breadcrumb/breadcrumb.component';
import { BreadcrumbsComponent } from './browse/breadcrumbs/breadcrumbs.component';
import { InputImage$Pipe } from './input-show/input-image.pipe';
import { NetworkFailoverComponent } from './network-failover/network-failover.component';
import { GetAlbumUriPipe } from './now-playing/get-album-uri.pipe';
import { GetTrackMediaServerPipe } from './now-playing/get-track-media-server.pipe';
import { CanChangeInputPipe } from './settings/can-change-input-image.pipe';
import { GetControlIdPipe } from './settings/get-control-id.pipe';
import { GetInput$ByInputIdPipe } from './settings/get-input-by-id.pipe';

@NgModule({
    declarations: [
        AppComponent,
        InputShowComponent,
        SigninComponent,
        InputBaseComponent,
        OnlyDisplayOnDirective,
        SettingsComponent,
        UpdateComponent,
        ConfirmUpdateDialog,
        NetworkSettingsComponent,
        StandbyComponent,
        NetworkReprovisionComponent,
        PoPQRDisplayComponent,
        BarcodeScannerComponent,
        ScreenSettingGetRoutePipe,
        ConfirmDialogComponent,
        PossibleVolumePipe,
        SwiperSliderComponent,
        JoinnetworkComponent,
        GetValuesComponent,
        WaitSuccessComponent,
        ProvisioningBaseComponent,
        CurrentnetworkComponent,
        TimeDeltaPipe,
        HeaderComponent,
        FooterComponent,
        PlaylistComponent,
        PlayerComponent,
        BrowseComponent,
        GetAlbumUriPipe,
        CanChangeInputPipe,
        GetArtistPipe,
        BreadcrumbsComponent,
        BreadcrumbComponent,
        GetTrackMediaServerPipe,
        BluetoothSettingsComponent,
        NetworkFailoverComponent,
        InputImage$Pipe,
        GetControlIdPipe,
        GetInput$ByInputIdPipe,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        ReactiveComponentModule,
        HttpClientModule,
        MatListModule,
        MatToolbarModule,
        MatIconModule,
        MatSliderModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatMenuModule,
        MatProgressBarModule,
        MatDialogModule,
        MatSnackBarModule,
        MatCardModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatExpansionModule,
        ScrollingModule,
        SwiperModule,
        DragDropModule,
        IonicStorageModule.forRoot(),
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            actionsBlocklist: ['server update'],
            maxAge: 25,
            logOnly: true,
        }),
        RootStoreModule,
    ],
    entryComponents: [ConfirmUpdateDialog, ConfirmDialogComponent],
    providers: [
        asyncInterceptorProvider,
        pollInterceptorProvider,
        Camera,
        BarcodeScanner,
        HTTP,
        File,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        private _poller: PollerService,
        private _autorouter: AutorouterService,
        private settings: SettingsService,
        private router: Router,

        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        // we want to wait for the first navigationEnd AND wait for loadedStorage
        // navigationEnd is transient, it will be supersceded by other events
        // loadedStorage is not -> it only goes one way; to true.
        this.router.events
            .pipe(
                first((e) => e instanceof NavigationEnd),
                switchMap((e) =>
                    combineLatest(of(e), this.settings.loadedStorage$)
                ),
                first(([_, ls]) => ls)
            )
            .subscribe(([e, _ls]) => {
                //this.settings.initScreen = (e as NavigationEnd).urlAfterRedirects
                if ((e as NavigationEnd).urlAfterRedirects !== '/signin') {
                    this.settings.loggedIn$.next(true);
                }
            });

        this.addAmpIcon('volume_off', 'assets/img/volume_off.svg');
        this.addAmpIcon('select', 'assets/img/select.svg');
        this.addAmpIcon('browse', 'assets/img/browse.svg');
        this.addAmpIcon('headphones_off', 'assets/img/headphones-off.svg');

        // icons as via a font-icon & svg do not play well together. So in places where we need to
        // be consistant load the relevent material icon as a svg too
        this.addMatIcon('volume_up', 'assets/img/volume_up.svg');
        this.addMatIcon('menu', 'assets/img/menu.svg');
        this.addMatIcon('logout', 'assets/img/logout.svg');
        this.addMatIcon(
            'power_settings_new',
            'assets/img/power_settings_new.svg'
        );
        this.addMatIcon('headphones', 'assets/img/headphones.svg');
    }

    private addAmpIcon(name: string, url: string) {
        this.iconRegistry.addSvgIconInNamespace(
            'amp',
            name,
            this.sanitizer.bypassSecurityTrustResourceUrl(url)
        );
    }

    private addMatIcon(name: string, url: string) {
        this.iconRegistry.addSvgIconInNamespace(
            'mat',
            name,
            this.sanitizer.bypassSecurityTrustResourceUrl(url)
        );
    }
}
