import { ServerService } from '@amp/services/server.service';
import { NOT_SUPPORTED } from '@amp/store/all/actions';
import { selectBluetoothConnected, selectBluetoothPaired } from '@amp/store/all/selector';
import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'amp-bluetooth-settings',
  templateUrl: './bluetooth-settings.component.html',
  styleUrls: ['./bluetooth-settings.component.scss']
})
export class BluetoothSettingsComponent {
  bluetoothPaired$ = this.store.select(selectBluetoothPaired);
  bluetoothPairingString$ = this.bluetoothPaired$.pipe(
    map(btPaired => btPaired === NOT_SUPPORTED ? 'Bluetooth not supported on this device' : btPaired ? 'Paired' : 'Not paired'),
  );

  bluetoothConnected$ = this.store.select(selectBluetoothConnected);
  bluetoothConnectedString$ = this.bluetoothConnected$.pipe(
    map(btConnected => btConnected === NOT_SUPPORTED ? 'Bluetooth not supported on this device' : btConnected ? 'Connected' : 'Not connected'),
  );

  constructor(
    private store: Store,
    private server: ServerService,
    private location: Location,
  ) { }

  onClickUnpair(): void {
    this.server.putBluetoothUnpair();
  }

  onClickBack(): void {
    this.location.back();

  }
}
