import { actionsInProgressReducer } from '@amp/store/actions-in-progress/reducer';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';


@NgModule({
    imports: [
        StoreModule.forFeature('actions_in_progress', actionsInProgressReducer),
    ],
}) export class ActionsInProgressModule { }
