import { runlock } from '@amp/runlock';
import { DisplayTypeService } from '@amp/services/display-type.service';
import { InputService } from '@amp/services/input.service';
import { MenuActionsService } from '@amp/services/menu-actions.service';
import { ServerService } from '@amp/services/server.service';
import { SettingsService } from '@amp/services/settings.service';
import { selectActionsInProgress } from '@amp/store/actions-in-progress/selectors';
import { setStandby, setTransportState } from '@amp/store/all/actions';
import { selectTransportState } from '@amp/store/all/selector';
import { getFiles, gotoBreadcrumb, markFileIconBroken } from '@amp/store/browse/actions';
import { selectBreadcrumbs, selectBrowsables, selectDesiredMediaServer, selectGettingMoreFiles } from '@amp/store/browse/selectors';
import { markMediaServerIconBroken } from '@amp/store/media-server/actions';
import { selectCurrentMediaServer } from '@amp/store/media-server/selectors';
import { selectCurrentPlaylistTrackId, selectOhTrackList } from '@amp/store/playlist/selectors';
import { MediaServer } from '@amp/types/media-server.data.types';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { select, Store } from '@ngrx/store';
import { isNull, omitBy } from 'lodash-es';
import { EMPTY, firstValueFrom } from 'rxjs';
import { catchError, first, take, timeout } from 'rxjs/operators';
import { BrowseService } from './browse.service';
import { BrowseFile } from './types';

const modifyPlaylist = Symbol('modify playlist');
const isMediaServer = (file: MediaServer | BrowseFile): file is MediaServer => (file as MediaServer).uuid !== undefined;

@Component({
    selector: 'app-browse',
    templateUrl: './browse.component.html',
    styleUrls: ['./browse.component.scss', '../common/header/header.common.component.scss',]
}) export class BrowseComponent implements OnInit {
    public browsables$ = this.store.select(selectBrowsables);
    public gettingMoreFiles$ = this.store.select(selectGettingMoreFiles);

    constructor(
        public browse: BrowseService,
        private store: Store,
        private settings: SettingsService,
        private server: ServerService,
        public menu: MenuActionsService,
        private snackbar: MatSnackBar,
        public input: InputService,
        public displayType: DisplayTypeService,
    ) { }

    async ngOnInit() {
        const breadcrumbs = await this.store.select(selectBreadcrumbs).pipe(take(1)).toPromise();
        if (breadcrumbs && !!breadcrumbs.slice(-1)[0]?.mediaServer) {
            this.store.dispatch(gotoBreadcrumb({ breadcrumb: breadcrumbs.slice(-1)[0] }));

        }
    }

    onServerImgLoadError(mediaServer: MediaServer) {
        this.store.dispatch(markMediaServerIconBroken({ mediaServer }));
    }

    onFileImgLoadError(file: BrowseFile) {
        this.store.dispatch(markFileIconBroken({ file }));
    }

    async onClickPath(file: BrowseFile | MediaServer) {
        if (isMediaServer(file)) {
            const currentMediaServer = await this.store.pipe(select(selectCurrentMediaServer), first()).toPromise();
            if (currentMediaServer.uuid !== file.uuid) {
                await this.browse.setMediaServer(file);
                // make sure we actually swap media servers
                await this.store.select(selectCurrentMediaServer).pipe(
                    first(ms => ms.uuid === file.uuid),
                    timeout(3 * 1000),
                    catchError(e => {
                        console.error(`Error swapping media server - ui report will be done later`, e);
                        return EMPTY;
                    }),
                ).toPromise();
            }

            this.store.dispatch(getFiles({ mediaServer: file, path: '0', opts: { displayName: file.friendly_name } }));
        } else if (this.isFolder(file)) {
            const mediaServer = await this.store.pipe(select(selectDesiredMediaServer), take(1)).toPromise();
            this.store.dispatch(getFiles({ mediaServer, path: `${file.id}`, opts: { displayName: file.title } }));
        } else {
            this.playlistModify(file, { replace: false, insert_after_current_track: false });
        }
    }

    getFileId(index, item: BrowseFile | MediaServer) {
        return isMediaServer(item) ? item.uuid : item.id;
    }


    onClickStandby() {
        this.store.dispatch(setStandby({ standby: true }));
        void /* await */ this.server.postStandby({ standby: true });
    }

    isFolder(file: BrowseFile) {
        return file.is_container;
    }

    @runlock(modifyPlaylist)
    private async playlistModify(file: BrowseFile, { replace, insert_after_current_track }: { replace: boolean, insert_after_current_track: boolean }) {
        try {
            let successMessage = `Added ${file.title} to playlist`;
            if (replace) {
                void await this.server.deletePlaylistDeleteAll();
                successMessage = `Replaced playlist with ${file.title}`;
            }


            let currentTrack = null;
            if (insert_after_current_track) {
                currentTrack = await firstValueFrom(this.store.select(selectCurrentPlaylistTrackId));
            }

            const transportState = await firstValueFrom(this.store.select(selectTransportState));
            const play_when_done = transportState === 'Stopped' || (await firstValueFrom(this.store.select(selectOhTrackList))).length === 0;

            if (file.is_container) {
                void await this.server.postPlaylistAppendContainer(omitBy({ 'container_id': file.id, play_when_done, insert_after: currentTrack }, isNull));
            } else {
                // NOTE: this isnt quite 100% if multiple clients are interacting with the api
                // its the best I can do however
                void await this.server.postPlaylist(omitBy({ uri: file.uri, didl: file.didl, insert_after: currentTrack }, isNull));
                void await this.store.pipe(select(selectActionsInProgress), first(aip => aip === 0)).toPromise();
                const tracks = await this.store.pipe(select(selectOhTrackList), first()).toPromise();

                if (play_when_done && !insert_after_current_track) { // the reason it would not be > 0 would be multiple clients messing with stuff
                    const addedTrack = tracks.slice(-1)[0];
                    void await this.server.postPlay({ playlist_id: addedTrack.id });
                }
            }

            // its quite likely the user will add a few in a row; transport state will not have a chance to update natrually
            const { newTransportState } = await this.server.getTransportState();
            this.store.dispatch(setTransportState({ transportState: newTransportState }));

            this.snackbar.open(successMessage, 'Ok', { duration: 2 * 1000 });
        } catch (error) {
            console.log("Error modifying playlist", error);
            this.snackbar.open("Error modifying playlist", 'Ok', { duration: 5 * 1000 });
        }
    }

    async add(e: Event, file: BrowseFile) {
        e.stopPropagation();

        this.playlistModify(file, { replace: false, insert_after_current_track: false });
    }

    async addAndPlay(file: BrowseFile) {
        this.playlistModify(file, { replace: false, insert_after_current_track: false });
    }

    async addAndPlayAfterCurrent(file: BrowseFile) {
        this.playlistModify(file, { replace: false, insert_after_current_track: true });
    }

    async replaceAndPlay(file: BrowseFile) {
        this.playlistModify(file, { replace: true, insert_after_current_track: false });
    }
}
