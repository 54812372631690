import { Pipe, PipeTransform } from '@angular/core';
import { ScreenSetting } from './settings.component';

@Pipe({
    name: 'screenSettingGetRoute'
}) export class ScreenSettingGetRoutePipe implements PipeTransform {
    transform(setting: ScreenSetting): string {
        const screenName = setting.config.find(c => c.endsWith('_screen'));
        if (screenName === 'network_settings_screen') {
            return '/settings/network';
        } else if (screenName === 'update_firmware_screen') {
            return '/update';
        } else if (screenName === 'bluetooth_settings_screen') {
            return '/settings/bluetooth';
        } else {
            console.error('We dont know how to route to the screen specified as ', setting);
            return '/settings';
        }
    }
}
