<mat-toolbar class="header" (click)="this.onClickBack()">
    <mat-icon>arrow_back</mat-icon>
    <h1>Bluetooth settings</h1>
</mat-toolbar>
<div class="body">
    <mat-list>
        <mat-list-item
            ><div class="label">Bluetooth Connection Status:&nbsp;</div>
            <div
                class="value"
                [class.good]="(bluetoothConnected$ | ngrxPush) === true"
            >
                {{ this.bluetoothConnectedString$ | ngrxPush }}
            </div></mat-list-item
        >
        <mat-list-item
            ><div class="label">Bluetooth Pairing Status:&nbsp;</div>
            <div
                class="value"
                [class.good]="(bluetoothPaired$ | ngrxPush) === true"
            >
                {{ this.bluetoothPairingString$ | ngrxPush }}
            </div></mat-list-item
        >

        <mat-list-item *ngIf="this.bluetoothPaired$ | ngrxPush">
            <button mat-flat-button (click)="onClickUnpair()">
                Unpair Bluetooth
            </button>
        </mat-list-item>
    </mat-list>
</div>
