import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { storeName } from './actions';
import { allReducer } from './reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(storeName, allReducer),
    ]
}) export class AllModule { }
