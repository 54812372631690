<mat-menu #ampMenu="matMenu">
    <a (click)="this.menu.onClickSelectDevice()"
        ><button mat-menu-item>Select Boulder Device</button></a
    >
    <a routerLink="/settings"><button mat-menu-item>Settings</button></a>
</mat-menu>

<app-header>
    <button
        mat-button
        [matMenuTriggerFor]="ampMenu"
        *appOnlyDisplayOn="'NOTDEVICE'"
    >
        <mat-icon inline>menu</mat-icon>
    </button>
    <button mat-button routerLink="/settings" *appOnlyDisplayOn="'DEVICE'">
        <mat-icon inline>menu</mat-icon>
    </button>
    <button mat-button routerLink="/input/show">
        <mat-icon inline svgIcon="amp:select"></mat-icon>
    </button>
    <ng-container *appOnlyDisplayOn="'DEVICE'">
        <div class="model">{{ this.model$ | ngrxPush }}</div>
        <div class="padding">
            <div
                *appOnlyDisplayOn="'DEVICE'"
                class="input-icon"
                [class.roon]="this.input.isRoon$ | async"
                [style.backgroundImage]="
                    (this.input.isRoon$ | async)
                        ? 'url(assets/img/roon-ready-white.svg)'
                        : null
                "
            ></div>
        </div>
    </ng-container>
    <button
        mat-button
        (click)="this.menu.onClickStandby()"
        *appOnlyDisplayOn="'NOTDEVICE'"
    >
        <mat-icon inline>power_settings_new</mat-icon>
    </button>
</app-header>

<div
    class="song-progress"
    [style.pointerEvents]="(this.progressEditable$ | async) ? null : 'none'"
    *ngIf="showControls$ | async"
>
    {{ (this.currentTrack$ | async).seconds | timeDelta }}&nbsp;
    <mat-slider
        thumbLabel
        [displayWith]="timeDelta"
        min="0"
        [max]="(this.currentTrack$ | async)?.track.duration"
        [formControl]="this.trackTime"
    ></mat-slider>
    &nbsp;{{ (this.currentTrack$ | async).track.duration | timeDelta }}
</div>

<div
    class="album-art"
    (mousedown)="onDown($event)"
    (touchstart)="onDown($event)"
    (mouseup)="onUp($event)"
    (touchend)="onUp($event)"
    (touchmove)="trackTouch($event)"
>
    <div
        class="cover"
        [ngStyle]="{
            'background-image':
                'url(' + (this.currentTrack$ | async).track.cover_art_uri + ')'
        }"
    ></div>
</div>

<div class="metadata">
    <div class="track-name">{{ (this.currentTrack$ | async).track.title }}</div>
    <div class="artist">
        <ng-container *ngIf="(this.currentTrack$ | async).track | getArtist">
            <div class="nonshrinkable name">
                {{ (this.input.isRoon$ | async) ? 'Performed By' : 'by' }}&nbsp;
            </div>
            <div class="shrinkable name">
                {{ (this.currentTrack$ | async).track | getArtist }}
                <ng-container *ngIf="(this.currentTrack$ | async).track.album"
                    >&nbsp;</ng-container
                >
            </div>
        </ng-container>
        <ng-container *ngIf="(this.currentTrack$ | async).track.album">
            <div class="nonshrinkable album">
                {{ (this.input.isRoon$ | async) ? 'on' : 'on the album' }}&nbsp;
            </div>
            <div class="shrinkable album">
                {{ (this.currentTrack$ | async).track.album }}
            </div>
        </ng-container>
    </div>
    <div class="padding"></div>
    <div class="resolution" *ngIf="(this.currentTrack$ | async).sample_rate">
        {{ (this.currentTrack$ | async).bit_depth }}-bit /
        {{ (this.currentTrack$ | async).sample_rate / 1000 }}kHz
    </div>
</div>

<div class="actions">
    <button
        mat-button
        *ngIf="
            !(this.input.isRemoteService$ | async) &&
            !this.displayType.isDevice()
        "
        routerLink="/filebrowser"
        class="pre-action"
    >
        <mat-icon inline fontSet="mdi" fontIcon="mdi-folder-music"></mat-icon>
    </button>

    <div class="transport">
        <button mat-button (click)="onClickSkipPrevious()">
            <mat-icon inline>skip_previous</mat-icon>
        </button>

        <ng-container
            *ngTemplateOutlet="
                (this.isPlaying$ | async) ? actionStop : actionStart
            "
        >
        </ng-container>

        <button mat-button (click)="onClickSkipNext()">
            <mat-icon inline>skip_next</mat-icon>
        </button>
    </div>

    <button
        mat-button
        *ngIf="
            !(this.input.isRemoteService$ | async) &&
            !this.displayType.isDevice()
        "
        routerLink="/playlist"
        class="post-action"
    >
        <mat-icon inline fontSet="mdi" fontIcon="mdi-playlist-music"></mat-icon>
    </button>
</div>

<ng-template #actionStop>
    <button mat-button (click)="onClickPause()">
        <mat-icon inline>pause</mat-icon>
    </button>
</ng-template>

<ng-template #actionStart>
    <button mat-button (click)="onClickPlay()">
        <mat-icon inline>play_arrow</mat-icon>
    </button>
</ng-template>

<ng-container *appOnlyDisplayOn="'NOTDEVICE'">
    <app-footer class="footer"></app-footer>
</ng-container>

<ng-container *appOnlyDisplayOn="'DEVICE'">
    <div class="footer">
        <div
            class="server-icon"
            [style.backgroundImage]="
                (this.input.isRemoteService$ | async)
                    ? null
                    : 'url(' +
                      (
                          (this.currentTrack$ | async).track
                          | getTrackMediaServer: (this.mediaServers$ | async)
                      )?.icon_uri +
                      ')'
            "
        ></div>
        <div class="server-text">
            {{
                (this.input.isRemoteService$ | async)
                    ? ''
                    : (
                          (this.currentTrack$ | async).track
                          | getTrackMediaServer: (this.mediaServers$ | async)
                      )?.friendly_name
            }}
        </div>
        <div class="volume-off">
            <!-- <img [src]="(this.settings.mute$ | async) ? 'assets/img/volume_off.svg' : null"> -->
            <mat-icon
                *ngIf="this.mute$ | async"
                svgIcon="amp:volume_off"
                inline
            ></mat-icon>
        </div>
        <div class="volume-text">
            <div>
                {{
                    this.volume$
                        | async
                        | possiblevolume: (this.showDB$ | async)
                }}
            </div>
        </div>
    </div>
</ng-container>
