<div class="all" [class.frontpanel]="this.displayType.isDevice()">
    <mat-toolbar class="header" routerLink="/input">
        <mat-icon>arrow_back</mat-icon>
        <h1>Settings</h1>
    </mat-toolbar>

    <mat-accordion multi="true" class="settings body">
        <div *ngIf="(this.settingsSpec$ | async).length < 1" class="loading">
            <div>Loading ...</div>
        </div>
        <mat-expansion-panel *ngFor="let section of this.settingsSpec$ | async">
            <mat-expansion-panel-header>
                <mat-panel-title>{{ section.display }}</mat-panel-title>
            </mat-expansion-panel-header>
            <div
                *ngFor="let setting of section.settings"
                [ngSwitch]="setting.type"
                class="setting"
            >
                <div *ngSwitchCase="'enum'">
                    <ng-container
                        *ngTemplateOutlet="
                            enumSetting;
                            context: {
                                setting: setting,
                                group: this.settingsForm$.value
                            }
                        "
                    ></ng-container>
                </div>
                <div *ngSwitchCase="'range'">
                    <ng-container
                        *ngTemplateOutlet="
                            unknownRangeSetting;
                            context: {
                                setting: setting,
                                group: this.settingsForm$.value
                            }
                        "
                    ></ng-container>
                </div>
                <div *ngSwitchCase="'inputSettings'">
                    <ng-container
                        *ngTemplateOutlet="
                            inputSetting;
                            context: { setting: setting }
                        "
                    ></ng-container>
                </div>
                <div *ngSwitchCase="'screen'">
                    <ng-container
                        *ngTemplateOutlet="
                            screenSetting;
                            context: { setting: setting }
                        "
                    ></ng-container>
                </div>
                <div *ngSwitchCase="'info'">
                    <ng-container
                        *ngTemplateOutlet="
                            infoSetting;
                            context: { setting: setting }
                        "
                    ></ng-container>
                </div>
                <div *ngSwitchCase="'button'" class="button">
                    <ng-container
                        *ngTemplateOutlet="
                            buttonSetting;
                            context: { setting: setting }
                        "
                    ></ng-container>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<ng-template #enumSetting let-setting="setting" let-group="group">
    <form [formGroup]="group">
        <mat-form-field>
            <mat-label>{{ setting.display }}</mat-label>

            <mat-select [formControlName]="setting | getControlId">
                <mat-option
                    *ngFor="let option of setting.options"
                    [value]="option[1]"
                >
                    {{ option[0] }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</ng-template>

<ng-template #unknownRangeSetting let-setting="setting" let-group="group">
    <ng-container *ngIf="isBalanceRange(setting)">
        <ng-container
            *ngTemplateOutlet="
                balanceRangeSetting;
                context: { setting: setting, group: group }
            "
        >
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!isBalanceRange(setting)">
        <ng-container
            *ngTemplateOutlet="
                rangeSetting;
                context: { setting: setting, group: group }
            "
        >
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #balanceRangeSetting let-setting="setting" let-group="group">
    <form [formGroup]="group">
        <div class="range label">
            <mat-label>{{ setting.display }}</mat-label>
        </div>
        <div class="range slider">
            <span class="min"> L </span>
            <mat-slider
                [formControlName]="setting | getControlId"
                [min]="setting.min"
                [max]="setting.max"
                thumbLabel
                [invert]="!!setting.config.invert_slider"
            >
            </mat-slider>
            <span class="max"> R </span>
        </div>
        <div class="range value">C</div>
    </form>
</ng-template>

<ng-template #rangeSetting let-setting="setting" let-group="group">
    <form [formGroup]="group">
        <div class="range label">
            <mat-label>{{ setting.display }}</mat-label>
        </div>
        <div class="range slider">
            <span class="min">
                {{
                    (!!setting.config.invert_slider ? setting.max : setting.min)
                        | possiblevolume
                            : this.volumeInDb
                            : !!setting.config.volume_type
                }}
            </span>
            <mat-slider
                [formControlName]="setting | getControlId"
                [min]="setting.min"
                [max]="setting.max"
                [invert]="!!setting.config.invert_slider"
                thumbLabel
                [displayWith]="
                    this.genThumbLabel(
                        !!setting.config.volume_type,
                        this.showDB$ | async
                    )
                "
            >
            </mat-slider>
            <span class="max">
                {{
                    (!!setting.config.invert_slider ? setting.min : setting.max)
                        | possiblevolume
                            : this.volumeInDb
                            : !!setting.config.volume_type
                }}
            </span>
        </div>
        <div class="range value">
            {{
                group.controls[setting | getControlId].value
                    | possiblevolume
                        : this.volumeInDb
                        : !!setting.config.volume_type
            }}
        </div>
    </form>
</ng-template>

<ng-template #textSetting let-setting="setting" let-group="group">
    <form [formGroup]="group" *appOnlyDisplayOn="'NOTDEVICE'">
        <mat-form-field>
            <mat-label>{{ setting.display }}</mat-label>
            <input matInput [formControlName]="setting | getControlId" />
        </mat-form-field>
    </form>
</ng-template>

<ng-template
    #imageSetting
    let-setting="setting"
    let-group="group"
    let-input="input"
>
    <form [formGroup]="group" class="imageSetting">
        <mat-label> {{ setting.display }}</mat-label>
        <div>
            <img
                class="image"
                *ngIf="this.images[input.index] | async"
                [src]="this.images[input.index] | async"
            />
        </div>
        <div class="imageActions" *ngIf="input | canChangeInput">
            <button
                mat-flat-button
                (click)="this.onClickTakePicture(input)"
                *appOnlyDisplayOn="'PHONE'"
            >
                <mat-icon>photo_camera</mat-icon>
            </button>
            <button
                mat-flat-button
                (click)="this.onClickUseGallery(input)"
                *appOnlyDisplayOn="'PHONE'"
            >
                <mat-icon
                    fontSet="mdi"
                    fontIcon="mdi-image-multiple"
                    [inline]="true"
                ></mat-icon>
            </button>
            <button mat-flat-button (click)="onUseDefaultClick(input)">
                <mat-icon
                    fontSet="mdi"
                    fontIcon="mdi-image-off"
                    [inline]="true"
                ></mat-icon>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #inputSetting let-inputsetting="setting">
    <mat-accordion multi="true" class="inputs">
        <!-- this.inputs.options$ changes when we alter any of its subitems. Thus iterate over a list of the
ids so we dont kill the panel when an option changes -->
        <mat-expansion-panel *ngFor="let inputId of this.allInputsIds$ | async">
            <mat-expansion-panel-header class="input">
                <mat-panel-title>{{
                    (inputId | getInput$ByInputId | async).name
                }}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="this.__inputs__.controls[inputId]; let group">
                <div
                    *ngFor="let setting of inputsetting.settings"
                    [ngSwitch]="setting.type"
                >
                    <div *ngSwitchCase="'text'">
                        <ng-container
                            *ngIf="
                                inputId
                                    | getInput$ByInputId
                                    | async
                                    | canChangeInput
                            "
                        >
                            <ng-container
                                *ngTemplateOutlet="
                                    textSetting;
                                    context: { setting: setting, group: group }
                                "
                            ></ng-container>
                        </ng-container>
                    </div>
                    <div *ngSwitchCase="'range'">
                        <ng-container
                            *ngTemplateOutlet="
                                unknownRangeSetting;
                                context: { setting: setting, group: group }
                            "
                        ></ng-container>
                    </div>
                    <div *ngSwitchCase="'image'">
                        <ng-container
                            *ngTemplateOutlet="
                                imageSetting;
                                context: {
                                    setting: setting,
                                    group: group,
                                    input:
                                        (inputId | getInput$ByInputId | async)
                                }
                            "
                        ></ng-container>
                    </div>
                    <div *ngSwitchCase="'enum'">
                        <ng-container
                            *ngTemplateOutlet="
                                enumSetting;
                                context: { setting: setting, group: group }
                            "
                        ></ng-container>
                    </div>
                </div>
            </ng-container>
        </mat-expansion-panel>
    </mat-accordion>
</ng-template>

<ng-template #screenSetting let-setting="setting">
    <ng-container [ngSwitch]="setting | screenSettingGetRoute">
        <ng-container *ngSwitchCase="'/update'">
            <ng-container
                *ngTemplateOutlet="gotoUpdate; context: { setting: setting }"
            >
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <ng-container
                *ngTemplateOutlet="gotoGeneric; context: { setting: setting }"
            >
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #gotoUpdate let-setting="setting">
    <button
        mat-flat-button
        [routerLink]="setting | screenSettingGetRoute"
        [disabled]="!(isUpdateAvailable$ | async)"
        [disableRipple]="!(isUpdateAvailable$ | async)"
    >
        {{ setting.display }}
    </button>
</ng-template>

<ng-template #gotoGeneric let-setting="setting">
    <button mat-flat-button [routerLink]="setting | screenSettingGetRoute">
        {{ setting.display }}
    </button>
</ng-template>

<ng-template #infoSetting let-setting="setting">
    <div class="info">
        {{ setting.display }}:
        {{ this.infoWatchers[setting | getControlId] | async }}
    </div>
</ng-template>

<ng-template #buttonSetting let-setting="setting">
    <button mat-flat-button (click)="onButtonSettingClick(setting)">
        {{ setting.display }}
    </button>
</ng-template>
