<!-- this is the update actually in progress page. As such there is no user way to get out of it as there is no api to cancel in in progress update. The xfe must transition out of 'updating' on its own -->
<app-header>
    <button mat-button (click)="this.menu.onClickSelectDevice()">
        <mat-icon svgIcon="mat:logout" [inline]="true"></mat-icon>
    </button>
</app-header>

<div class="page">
    <div class="notice">Update in progress</div>
    <div class="status">
        <div class="statusString">
            {{ progressString$ | async }}
        </div>
        <mat-progress-bar
            class="statusBar"
            color="amber"
            mode="determinate"
            [value]="progressPercent$ | async"
        ></mat-progress-bar>
        <div class="statusPercent">
            {{ progressPercent$ | async }}
        </div>
    </div>
</div>
