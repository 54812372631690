import { TrackData } from '@amp/types/current-track.data.types';
import { MediaServer } from '@amp/types/media-server.data.types';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getTrackMediaServer'
}) export class GetTrackMediaServerPipe implements PipeTransform {
    constructor(
    ) { }

    transform(track: TrackData, mediaServers: Array<MediaServer>): MediaServer {
        return mediaServers.find(s => RegExp(s.ip_and_port).test(track.uri));

    }
}
