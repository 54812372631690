import { Breadcrumb, BrowseFile } from '@amp/browse/types';
import { MediaServer } from '@amp/types/media-server.data.types';
import { createAction, props } from '@ngrx/store';

export const storeName = 'browse';

type GetMoreFileOpts = { offset: number };
type GetNewFileOpts = { displayName: string };
type GetFileOpts = { offset: number } | { displayName: string };
export const isNewFileOpts = (opts: GetFileOpts): opts is GetNewFileOpts => !!(opts as GetNewFileOpts).displayName;

export const getFiles = createAction(`[${storeName}] get files`, props<{ mediaServer: MediaServer, path: string, opts: GetFileOpts }>());

export const gotFiles = createAction(`[${storeName}] got files`, props<{ mediaServer: MediaServer, path: string, offset: number, newFiles: Array<BrowseFile>, next: URL | null }>());
export const gotAllFiles = createAction(`[${storeName}] got all files for folder`);

export const getMediaServers = createAction(`[${storeName}] get media servers`);
export const gotMediaServers = createAction(`[${storeName}] got media server for selection`, props<{ mediaServers: Array<MediaServer> }>());

export const markMediaServerIconBroken = createAction(`[${storeName}] mark media server icon broken`, props<{ mediaServer: MediaServer }>());
export const markFileIconBroken = createAction(`[${storeName}] mark file icon broken`, props<{ file: BrowseFile }>());

export const gotoBreadcrumb = createAction(`[${storeName}] go to breadcrumb`, props<{ breadcrumb: Breadcrumb }>());

export const noop = createAction(`[${storeName}] noop`);
