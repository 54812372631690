import { Input } from '@amp/store/inputs/actions';
import { selectInputById } from '@amp/store/inputs/selectors';
import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Pipe({
    name: 'getInput$ByInputId'
}) export class GetInput$ByInputIdPipe implements PipeTransform {
    constructor(
        private store: Store,
    ) { }

    transform(inputId: number): Observable<Input> {
        return this.store.select(selectInputById(inputId));
    }
}
