import { MenuActionsService } from '@amp/services/menu-actions.service';
import { ServerService } from '@amp/services/server.service';
import { selectUpdateInProgress, selectUpdateProgressPercent, selectUpdateProgressString, selectUpdateVersionString } from '@amp/store/all/selector';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom, Subscription } from 'rxjs';
import { first, skipWhile } from 'rxjs/operators';


type DialogData = { updateName: string };
@Component({
    selector: 'confirm-update-dialog',
    template: `
<h1 mat-dialog-title>Confirm update</h1>
<div mat-dialog-content>
<p>Are you sure you want to update to {{ this.data.updateName }}?</p>
<div mat-dialog-actions>
<button mat-button [mat-dialog-close]="false">No</button>
<button mat-button [mat-dialog-close]="true">Yes</button>
</div>
</div>
`,
}) export class ConfirmUpdateDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }
}

@Component({
    selector: 'app-update',
    templateUrl: './update.component.html',
    styleUrls: ['./update.component.scss', '../common/header/header.common.component.scss']
}) export class UpdateComponent implements OnInit {
    private updateSub: Subscription | null = null;

    progressPercent$ = this.store.select(selectUpdateProgressPercent);
    progressString$ = this.store.select(selectUpdateProgressString);

    constructor(
        public menu: MenuActionsService,
        public server: ServerService,
        private store: Store,
        private router: Router,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
    ) { }

    async ngOnInit() {
        const inProgress = await firstValueFrom(this.store.select(selectUpdateInProgress));
        if (inProgress) {
            this.addFinishWatcher();
            return;
        }
        const updateName = await firstValueFrom(this.store.select(selectUpdateVersionString));
        const doUpdate = !!updateName ? await this.dialog.open(ConfirmUpdateDialog, { data: { updateName } }).afterClosed().toPromise() : false;

        if (doUpdate) {
            this.startUpdate();
        } else {
            this.goBack("Update canceled");
        }
    }

    ngOnDestroy() {
        if (this.updateSub) { this.updateSub.unsubscribe(); }
    }

    async startUpdate() {
        void /* await */ this.server.putStartUpdate();
        this.addFinishWatcher();
    }

    addFinishWatcher() {
        this.updateSub = this.store.select(selectUpdateInProgress).pipe(
            skipWhile(ip => !ip), // skip until we are inProgress
            skipWhile(ip => ip),   // then wait until we are not (finished)
            first(),
        ).subscribe(() => this.goBack());
    }

    goBack(message = "Update finished!") {
        this.snackbar.open(message, "Ok", { duration: 3 * 1000 });
        this.router.navigate(['/input/show']);
    }
}
