import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { storeName } from './actions';
import { inputsReducer } from './reducer';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(storeName, inputsReducer),
    ]
}) export class InputsStoreModule { }
