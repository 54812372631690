import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'possiblevolume',
    pure: true,
}) export class PossibleVolumePipe implements PipeTransform {

    transform(value: number, isDb: boolean, shouldTransform: boolean = true): string {
        return formatVolume(value, isDb, shouldTransform);
    }
}

// slider thumb labels need to format the values too. However they do not use pipes.
// Instead you are expected to provide a function of type (number) => number | string
// this is a helper function so that everyone uses the same db format conversion logic
export const formatVolume = (value: number, isDb: boolean, shouldTransform: boolean = true) => {
    // todo - do we want halfpoints displayed in full? if so we will have to tweak the width of swiper frames
    if ((value % 1).toFixed(1) === '-0.5') {
        return '\u00bd';
    }
    if ((value % 1).toFixed(1) == '-0.1') {
        return '.1';
    }
    if ((value % 1).toFixed(1) === '-0.2') {
        return '.2';
    }
    if ((value % 1).toFixed(1) === '-0.3') {
        return '.3';
    }
    if ((value % 1).toFixed(1) === '-0.4') {
        return '.4';
    }
    if ((value % 1).toFixed(1) === '-0.6') {
        return '.6';
    }
    if ((value % 1).toFixed(1) === '-0.7') {
        return '.7';
    }
    if ((value % 1).toFixed(1) === '-0.8') {
        return '.8';
    }
    if ((value % 1).toFixed(1) === '-0.9') {
        return '.9';
    }
    if (value < -100) {
        return '-\u221e'; // unicode inf
    }
    if (shouldTransform && !isDb) {
        value += 100;
    }
    return `${value.toFixed(0)}`;
}
