import { MenuActionsService } from '@amp/services/menu-actions.service';
import { setMute, setVolume } from '@amp/store/all/actions';
import { selectIsUpdateAvailable, selectModelName, selectMute, selectShowDB, selectVolume } from '@amp/store/all/selector';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { DisplayTypeService } from '../services/display-type.service';
import { ServerService } from '../services/server.service';
import { SettingsService } from '../services/settings.service';
import { formatVolume } from '../settings/possiblevolume.pipe';


@Component({
    selector: 'app-input-base',
    templateUrl: './input-base.component.html',
    styleUrls: ['./input-base.component.scss', '../common/header/header.common.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
}) export class InputBaseComponent {
    public volumeControl: FormControl = new FormControl(-50)
    public volume$ = this.store.select(selectVolume);
    public model$ = this.store.select(selectModelName);
    public showDB$ = this.store.select(selectShowDB);
    public mute$ = this.store.select(selectMute);
    public isUpdateAvailable$ = this.store.select(selectIsUpdateAvailable);

    constructor(
        public settings: SettingsService,
        public server: ServerService,
        public displayType: DisplayTypeService,
        public menu: MenuActionsService,
        private router: Router,
        private store: Store,
    ) { }

    // note there is an issue here. We know we have muted. We *dont* know the new volume
    // todo: use the poller to autoissue a new poller on ANY post/delete/put. is the server
    // sync with such things?
    async onClickMute() {
        if (this.displayType.isDevice()) {
            return
        }
        const currentMute = await firstValueFrom(this.store.select(selectMute));
        const mute = !currentMute;
        this.store.dispatch(setMute({ mute }));
        void await this.server.postMute({ mute });
        this.server.getAll();
    }

    private form2ModelSub: Subscription;
    private model2FormSub: Subscription;
    ngOnDestroy() {
        if (this.form2ModelSub) { this.form2ModelSub.unsubscribe(); }
        if (this.model2FormSub) { this.model2FormSub.unsubscribe(); }
    }

    ngOnInit() {
        // we may not be showing the slider

        this.form2ModelSub = this.volumeControl.valueChanges.pipe(
            switchMap(async (uiVolume) => {
                const storeVolume = await firstValueFrom(this.store.select(selectVolume));
                return { uiVolume, storeVolume };
            }),
            filter(({ storeVolume, uiVolume }) => uiVolume !== storeVolume),
        ).subscribe(
            async ({ uiVolume, storeVolume: oldVolume }) => {
                try {
                    this.store.dispatch(setVolume(uiVolume));
                    void await this.server.postVolume({ volume: uiVolume });
                } catch (e) {
                    this.store.dispatch(setVolume({ volume: oldVolume }));
                }
            }
        );


        this.model2FormSub = this.store.select(selectVolume).subscribe(volume => this.volumeControl.patchValue(volume, { emitEvent: false }));
    }

    // callback of mat-slider - so we need input-base context
    thumbLabel = (volume: number, showDB: boolean) => {
        return formatVolume(volume, showDB);
    }

    // clicking on a header in general does the button routing, however the
    // network is only on a xfe. otherwise we need to propagete for a potential
    // matMenuTriggerfor
    onClickHeader() {
        if (this.displayType.isDevice()) {
            this.router.navigate(['/settings/network'], { queryParams: { duration: 15 } });
        }
    }
}
