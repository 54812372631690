import { BrowseFile } from '@amp/browse/types';
import { SettingsSpec } from '@amp/settings/settings.component';
import { ApiType } from '@amp/store/all/actions';
import { setSupportedApis } from '@amp/store/settings/actions';
import {
    selectLoadedApi,
    selectSupportedApis,
} from '@amp/store/settings/selectors';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { get, isArray } from 'lodash-es';
import { Observable, first, firstValueFrom, lastValueFrom } from 'rxjs';
import { BADeviceApi, TransportState } from './product-apis/all-apis';
import { DacApi } from './product-apis/dac';
import { PreampApi } from './product-apis/preamp';
import { SettingsService } from './settings.service';

type BrowseResults = {
    next: string;
    media_items: Array<BrowseFile>;
};

async function getApi(that: any) {
    await firstValueFrom(
        that.settings.loadedStorage$.pipe(first((ls) => !!ls))
    );
    const loadedApi = await firstValueFrom(that.store.select(selectLoadedApi));
    if (!loadedApi) {
        that.getSettingsSpec();
        await firstValueFrom(
            that.store.select(selectLoadedApi).pipe(first((loaded) => !!loaded))
        );
    }
    const supportedApis: ReadonlyArray<String> = await firstValueFrom(
        that.store.select(selectSupportedApis)
    );

    let api: BADeviceApi = null;
    if (supportedApis.includes('preamp')) {
        api = that.preampApi;
    } else if (supportedApis.includes('dac')) {
        api = that.dacApi;
    }

    return { api, supportedApis };
}

function useApi(
    target: ServerService,
    propertyKey: string,
    descriptor: PropertyDescriptor
) {
    descriptor.value = async function (...args) {
        const { api, supportedApis } = await getApi(this);

        if (api === null) {
            console.error('unknown api: ', supportedApis);
        } else {
            return api[propertyKey].apply(api, args);
        }
    };
    return descriptor;
}

@Injectable({
    providedIn: 'root',
})
export class ServerService implements BADeviceApi {
    constructor(
        private settings: SettingsService,
        private http: HttpClient,
        private store: Store,

        // these two *are* used in the @useApi decorator
        private preampApi: PreampApi,
        private dacApi: DacApi
    ) {}

    // BADeviceApi - almost all of these will use the underlying apis
    // calls that return something need a return in the body for type data
    // not sure why that works as its not of the correct type (the real return)
    // is provided by the implementing api
    @useApi async getAll(): Promise<Object> {
        return;
    }
    @useApi async getProofOfPossession(): Promise<{ proof_of_possession }> {
        return;
    }
    @useApi async getHasDisplay(): Promise<{ has_display: boolean }> {
        return;
    }
    @useApi async getTransportState(): Promise<{
        newTransportState: TransportState;
    }> {
        return;
    }

    // this one does not return a promise. so we have to be a little more clever on it
    getBrowseObs(data: {
        params: { container_id: string; start_position: number };
    }): Observable<any> {
        return new Observable((subscriber) => {
            getApi(this).then(({ api }) => {
                const innnerObs = api.getBrowseObs(data);
                return innnerObs.subscribe({
                    next: (event) => subscriber.next(event),
                    error: (error) => subscriber.error(error),
                    complete: () => subscriber.complete(),
                });
            });
        });
    }

    @useApi async getInputImageUrl(data: {
        image_id: string;
    }): Promise<string> {
        return;
    }

    @useApi async postInputs(data: { input_index: number }) {}
    @useApi async postVolume(data: { volume: number }) {}
    @useApi async postMute(data: { mute: boolean }) {}
    @useApi async postInputImage(formData: FormData) {}
    @useApi async postStandby(data: { standby: boolean }) {}
    @useApi async postVolumeDefault(data: { volume_default: number }) {}
    @useApi async postShowPopQR(data: { show_pop_qr: boolean }) {}
    @useApi async postResetWifiNetwork() {}
    @useApi async postPlay(data?: { playlist_id: number }): Promise<void> {}
    @useApi async postPause(): Promise<void> {}
    @useApi async postNext(): Promise<void> {}
    @useApi async postPrevious(): Promise<void> {}
    @useApi async postMediaServer(data: {
        server_uuid: string;
    }): Promise<void> {}
    @useApi async postPlaylist(data: {
        container_id?: number;
        play_when_done?: boolean;
        insert_after?: number;
    }) {}
    @useApi async postPlaylistAppendContainer(data: {
        container_id?: number;
        play_when_done?: boolean;
        insert_after?: number;
    }): Promise<void> {}
    @useApi async postHeadphoneOut(data: {
        headphone_out: boolean;
    }): Promise<{ headphone_out: boolean }> {
        return;
    }
    @useApi async postClearFailover(): Promise<void> {}
    @useApi async postRestoreWifiNetwork(): Promise<void> {}

    @useApi async putResetInputImage(data: {
        input_index: number;
    }): Promise<void> {}
    @useApi async putStartUpdate(): Promise<void> {}
    @useApi async putBluetoothUnpair(): Promise<void> {}

    @useApi async deletePlaylistDeleteAll(): Promise<void> {}

    @useApi async _rawEndpointUpdate(protocol: "POST" | "PUT", url: string, args?: any): Promise<void> {}

    async getSettingsSpec(): Promise<SettingsSpec> {
        await firstValueFrom(
            this.settings.loadedStorage$.pipe(first((ls) => ls))
        );

        const url = `http://${this.settings.server$.value}/api/settings_spec`;
        let settingsSpec;
        try {
            settingsSpec = await lastValueFrom(this.http.get(url));
        } catch {
            return null;
        }

        let supportedApis: ReadonlyArray<ApiType> = [];
        let settingsSpecData;
        let settingsSpecVersion = 1;
        if (isArray(settingsSpec)) {
            supportedApis = ['dac'];
            settingsSpecData = settingsSpec;
        } else {
            settingsSpecVersion =
                settingsSpec.product_info.settings_spec_version;
            settingsSpecData = settingsSpec.settings_spec;
            supportedApis = get(
                settingsSpec,
                'product_info.product_features',
                []
            );
        }

        this.store.dispatch(
            setSupportedApis({ supportedApis, settingsSpecVersion })
        );

        return settingsSpecData as any;
    }

    getBaseUrl() {
        return `http://${this.settings.server$.value}`;
    }
}
