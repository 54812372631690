import { CurrentnetworkComponent } from '@amp/provisioning/currentnetwork/currentnetwork.component';
import { ProvisioningService } from '@amp/provisioning/provisioning.service';
import {
    DiscoveryService,
    NormalizedService
} from '@amp/services/discovery.service';
import { SettingsService } from '@amp/services/settings.service';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, interval, lastValueFrom, Subscription } from 'rxjs';
import { first, map, take } from 'rxjs/operators';

declare const device: any;
declare const WifiWizard2: any;
declare const serviceDiscovery: any;


// constants for registration use
// because we are throwing up an ad-hoc network we know & can garuntee the ip is always the same
const ip = '192.168.0.2';
const port = '1234';
const url = `http://${ip}:${port}`;

@Component({
    selector: 'app-wait-success',
    templateUrl: './wait-success.component.html',
    styleUrls: ['./wait-success.component.scss'],
})
export class WaitSuccessComponent implements AfterViewInit {
    public timeoutPct$: BehaviorSubject<number> = new BehaviorSubject(0);
    @ViewChild(CurrentnetworkComponent) currentNetwork: CurrentnetworkComponent;
    private xfe: NormalizedService;
    public networkCorrect: boolean = false;

    // consts
    private upnpScanInterval = 5 * 1000;
    private upnpScanTimeout = 60 * 1000;

    constructor(
        public provisioning: ProvisioningService,
        private settings: SettingsService,
        private discovery: DiscoveryService,
        private router: Router,
        private snackbar: MatSnackBar
    ) {}

    private subs: Array<Subscription> = [];
    ngAfterViewInit() {
        this.subs.push(
            this.currentNetwork.network.subscribe((cn) => {
                this.networkCorrect = cn === this.provisioning.ssid;
            })
        );

        /* NOT await */ this.actionSequence();
    }

    async actionSequence() {
        try {
            void (await this.sendNetworkInfo());
        } catch (e) {
            // all errors with send network info are fatal
            this.snackbar.open(
                e?.message
                    ? e.message
                    : 'There was an error sending your network credentials',
                'Ok',
                { duration: 10 * 1000 }
            );
            this.gotoGetValues();
            return;
        }

        try {
            // make sure we join our target network. Its up to the user to do this (though it might be
            // automatic depending on phone settings)
            void (await this.waitForSSID(this.provisioning.ssid));
        } catch {
            // Not sure what our network is - so be safe & goto joinnetwork
            this.snackbar.open(
                `Your phone had an error joining ${this.provisioning.ssid}.`,
                'Ok',
                { duration: 10 * 1000 }
            );
            this.gotoJoinNetwork();
            return;
        }

        try {
            // cosmetic 60 sec timeout
            // 60 sec timeout => 60000 ms / 100 => 600
            const timerMs = 600;
            interval(timerMs)
                .pipe(
                    take(100),
                    map((pct) => pct + 1)
                )
                .subscribe(this.timeoutPct$);

            void (await this.scanForUpnp());
        } catch {
            this.snackbar.open(
                `Timed out trying to find your Boulder Device on ${this.provisioning.ssid}.`,
                'Ok',
                { duration: 10 * 1000 }
            );
            this.gotoJoinNetwork();
            return;
        }

        this.onSuccess();
    }

    async waitForSSID(targetSsid: string) {
        await lastValueFrom(this.currentNetwork.network
            .pipe(first((n) => n === targetSsid)));
    }

    async scanForUpnp() {
        await new Promise<void>((resolve, reject) => {
            const poller = setInterval(async () => {
                if (await this.findXfe()) {
                    clearInterval(poller);
                    clearTimeout(timeout);
                    resolve();
                }
            }, this.upnpScanInterval);

            const timeout = setTimeout(() => {
                // timeout
                clearInterval(poller);
                clearTimeout(timeout);
                reject();
            }, this.upnpScanTimeout);
        });
    }

    onClickRetry() {
        this.gotoGetValues();
    }

    gotoGetValues() {
        this.router.navigate(['/provisioning/getvalues']);
    }

    gotoJoinNetwork() {
        this.router.navigate(['/provisioning/joinnetwork']);
    }

    onSuccess() {
        this.snackbar.open(
            `Your Boulder Amplifier has joined ${this.provisioning.ssid}.`,
            'Ok',
            { duration: 10 * 1000 }
        );
        const ip = this.xfe.location.match(/http:\/\/([0-9.]+):\d+/)[1];
        this.settings.server$.next(ip);
        this.router.navigate(['/signin']);
    }

    async findXfe() {
        const devices = await this.discovery.getServices();
        if (!devices) {
            return false;
        }

        const parser = new DOMParser();

        const device = devices.find((device) => {
            const xmlDoc = parser.parseFromString(device.xml, 'text/xml');
            const serial =
                xmlDoc.getElementsByTagName('serialNumber')[0].textContent;

            return serial === this.provisioning.serial;
        });

        this.xfe = device;

        return !!device;
    }

    async sendNetworkInfo() {
        if (!this.provisioning.ssid || !this.provisioning.pop) {
            console.error('this component requires ssid and pop parameters');
            return;
        }

        try {
            const { pop, ssid, password } = this.provisioning;

            this.provisioning.sendProvisioningData(ssid, password, pop);
        } catch (e) {
            console.error('error sending provisioning data', e);
            this.snackbar.open('Error sending provisioning data', 'Ok', {
                duration: 10 * 1000,
            });
        }
    }

    ngOnDestroy() {
        for (const s of this.subs) {
            s.unsubscribe();
        }
        this.subs = [];
    }
}
