import { createFeatureSelector, createSelector } from '@ngrx/store';
import { storeName } from './actions';
import { MediaServerState, serverInfoListAdapter } from './reducer';


const mediaServerState = createFeatureSelector<MediaServerState>(storeName);

const { selectAll: selectMediaServers, selectEntities: selectMediaServerEntities } = serverInfoListAdapter.getSelectors(mediaServerState);

const selectBrokenIconUUIDS = createSelector(
    mediaServerState,
    (s) => s.brokenServerIconUUIDS,
);

const selectCurrentServerUUID = createSelector(
    mediaServerState,
    (s) => s.currentServerUUID,
);

export const selectServerInfoList = createSelector(
    selectMediaServers,
    selectBrokenIconUUIDS,
    (ms, brokenIcons) => ms.map(s => ({ ...s, icon_uri: brokenIcons.includes(s.uuid) ? null : s.icon_uri })),
);

export const selectCurrentMediaServer = createSelector(
    selectServerInfoList,
    selectCurrentServerUUID,
    selectBrokenIconUUIDS,
    (msil, currentUUID, brokenIcons) => {
        if (!currentUUID) {
            return {
                uuid: null,
                icon_uri: null,
                friendly_name: "Loading Media Server list ...",
                ip_and_port: null,
            };
        }

        return msil.find(ms => ms.uuid === currentUUID);
    },
);
