import { ServerService } from '@amp/services/server.service';
import { SettingsService } from '@amp/services/settings.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProvisioningService } from '../provisioning.service';

const noop = () => { }

@Component({
    selector: 'app-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss']
}) export class BaseComponent implements OnInit {

    constructor(
        private server: ServerService,
        private settings: SettingsService,
        private provisioning: ProvisioningService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.provisioning.origSsid = this.settings.server$.value;
        if (/BoulderAmplifier/.test(this.provisioning.origSsid)) {
            this.provisioning.ssid = this.provisioning.origSsid;
        }
        this.settings.server$.next('192.168.0.2');
    }

    onClickGoback() {
        // set the not showPoP flag on server
        // explicitly does NOT wait for return of server post
        this.server.postShowPopQR({ show_pop_qr: false }).catch(noop);

        this.settings.server$.next(this.provisioning.origSsid);
        this.router.navigate(['/signin']);
    }
}
