import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare const WifiWizard2;

@Component({
    selector: 'app-currentnetwork',
    templateUrl: './currentnetwork.component.html',
    styleUrls: ['./currentnetwork.component.scss']
}) export class CurrentnetworkComponent implements OnInit {
    public network: BehaviorSubject<string> = new BehaviorSubject('Scanning ...');
    private timer;
    @Output('change') public onChange: EventEmitter<string> = new EventEmitter();

    constructor() { }

    ngOnInit() {
        this.timer = setInterval(async () => {
            // we dont actually care about location - but its needed for ssid since ios 13
            void await new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject));
            const ssid = await WifiWizard2.getConnectedSSID();
            this.network.next(ssid);
        }, 3 * 1000);
    }

    ngOnDestroy() {
        clearInterval(this.timer);
    }
}
