import { NOT_SUPPORTED, serverUpdateAll, StandardizedDeviceAllApi } from "@amp/store/all/actions";
import { Input } from "@amp/store/inputs/actions";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { get, has } from 'lodash-es';
import { first, firstValueFrom, lastValueFrom, map, Observable, switchMap } from "rxjs";
import { SettingsService } from "../settings.service";
import { BADeviceApi, TransportState } from "./all-apis";

@Injectable({
    providedIn: 'root',
}) export class DacApi implements BADeviceApi {
    constructor(
        private http: HttpClient,
        private settings: SettingsService,
        private store: Store,
        private snackbar: MatSnackBar,
    ) { }

    private badModelWarned = false;
    private inputAutoselectedWarned = false;

    async getAll(): Promise<Object> {
        const all = await this._get('all');

        const headphone_out = get(all, 'all.headphone_out.headphone_out', NOT_SUPPORTED);
        const dac_mode = get(all, 'all.dac_mode.dac_mode', NOT_SUPPORTED);
        const bluetooth_connected = get(all, 'all.bluetooth_connected.bluetooth_connected', NOT_SUPPORTED);
        const bluetooth_paired = get(all, 'all.bluetooth_paired.bluetooth_paired', NOT_SUPPORTED);

        // update things
        const { update_available: updateAvailable, update_version } = get(all, 'all.update_available', { update_available: false, update_version: null });

        const updateVersion = updateAvailable ? update_version : null;
        const updateInProgress = get(all, 'all.update_in_progress.update_in_progress', false);
        const updateProgressString = get(all, 'all.update_status.update_status', 'Updating');
        const updateProgressPercent = get(all, 'all.update_progress.update_progress', '50');

        const transportState = get(all, 'all.transport_state.transport_state', null);


        // settings.service things
        const showDB = get(all, 'all.volume_type.volume_type', 'db') === "db";
        const nextModel = get(all, 'all.model.model', '');
        if (nextModel === '' && !this.badModelWarned) {
            console.error("The api for all.model.model is ''. This is not allowed");
            this.badModelWarned = true;
        }
        const model = nextModel;

        const hasMuteApi = has(all, 'all.mute');
        const mute = hasMuteApi ? get(all, 'all.mute.mute', false) : NOT_SUPPORTED;

        const hasSoftApi = has(all, 'all.soft');
        const soft = hasSoftApi ? get(all, 'all.soft.soft', false) : NOT_SUPPORTED;

        const volume = get(all, 'all.volume.volume', -50);
        const maxVolume = get(all, 'all.max_volume.max_volume', -20);
        const volumeDefault = get(all, 'all.volume_default.volume_default', -50);

        const deviceMacAddress = get(all, 'all.mac_address.mac_address', NOT_SUPPORTED);
        const deviceIpAddress = get(all, 'all.network_info.ip', NOT_SUPPORTED);
        const deviceSSID = get(all, 'all.network_info.ssid', NOT_SUPPORTED);
        const deviceNetworkStatus = get(all, 'all.network_info.status', NOT_SUPPORTED);

        const standby = get(all, 'all.standby.standby', false);

        const currentServerIconUri = get(all, 'all.media_server.current_server.icon_uri', null);
        const currentServerName = get(all, 'all.media_server.current_server.friendly_name', null);
        const showPopQr = get(all, 'all.show_pop_qr.show_pop_qr', false);
        const isProvisioning = get(all, 'all.is_provisioning.is_provisioning', false);
        const actionsInProgress = get(all, 'all.actions_in_progress.actions_in_progress', 0);

        // inputs
        const rawInputs: ReadonlyArray<Omit<Input, 'orderNumber'> & { current: boolean }> = get(all, 'all.inputs.inputs', []);
        const inputs: ReadonlyArray<Input> = rawInputs.map((input, orderNumber) => ({
            name: input.name,
            index: input.index,
            type: input.type,
            trim: input.trim,
            image_id: input.image_id,
            theater_mode: input.theater_mode,
            visible: input.visible,
            orderNumber,
        }));
        const newInput = rawInputs.find(input => input.current);
        let selectedInputIndexId: number; // ids of selected. which might NOT be the index in array
        if (!newInput) {
            selectedInputIndexId = inputs[0].index;

            try {
                void /* await */ this.postInputs({ input_index: selectedInputIndexId });

            } catch (e) {
                console.error('was not able to post to inputs', e);
            }

            // post can fail - if for example we are in standby. keep trying till an input is selected.
            // however we only need to warn once
            if (!this.inputAutoselectedWarned) {
                this.inputAutoselectedWarned = true;
                this.snackbar.open('Selected input was not available. We have autoselected one.', 'Ok');
            }
        } else {
            selectedInputIndexId = newInput.index;
        }


        // this is not super type safe but it is what it was
        const media_server = all.all.media_server;
        const playlist = all.all.playlist;
        const current_track = all.all.current_track;


        const allUpdate: StandardizedDeviceAllApi = {
            headphone_out,
            dac_mode,
            bluetooth_connected,
            bluetooth_paired,
            showPopQr,
            isProvisioning,
            actionsInProgress,

            // settings.serivce
            model,
            mute,
            soft,
            volume,
            showDB,
            maxVolume,
            volumeDefault,
            deviceIpAddress,
            deviceMacAddress,
            deviceSSID,
            deviceNetworkStatus,
            standby,
            currentServerIconUri,
            currentServerName,

            // update.service
            updateVersion,
            updateInProgress,
            updateProgressString,
            updateProgressPercent,

            // nowplaying.service
            transportState,

            inputs,
            selectedInputIndexId,

            current_track,
            playlist,
            media_server,

            // new stuff
            volumeStepSize: 1,
        }

        this.store.dispatch(serverUpdateAll({ all: allUpdate, apiType: 'dac' }));

        return all;
    }

    getProofOfPossession(): Promise<{ proof_of_possession: string; }> {
        return this._get('proof_of_possession');
    }

    getHasDisplay(): Promise<{ has_display: boolean; }> {
        return this._get('has_display');
    }

    getTransportState(): Promise<{ newTransportState: TransportState; }> {
        return this._get('transport_state');
    }

    getBrowseObs({ params }: { params: { container_id: string, start_position: number } }): Observable<any> {
        return this.settings.loadedStorage$.pipe(
            first(ls => ls),
            switchMap(() => this.http.get(`http://${this.settings.server$.value}/api/browse`, { params })),
        );
    }

    async getInputImageUrl(params: { image_id: string; }): Promise<string> {
        let queryArgs = '';
        if (Object.keys(params).length !== 0) {
            queryArgs = '?' + Object.entries(params).map(([k, v]) => `${k}=${v}`).join('&');
        }
        return `${await this.getBaseUrl()}/input_image${queryArgs}`;
    }

    async postInputs(data: { input_index: number; }): Promise<void> {
        await this._post('inputs', data);
    }

    async postVolume(data: { volume: number; }): Promise<void> {
        await this._post('volume', data);
    }

    async postMute(data: { mute: boolean; }): Promise<void> {
        await this._post('mute', data);
    }

    async postInputImage(formData: FormData): Promise<void> {
        await this._post('input_image', formData);
    }

    async postStandby(data: { standby: boolean; }): Promise<void> {
        await this._post('standby', data);
    }

    async postVolumeDefault(data: { volume_default: number; }): Promise<void> {
        await this._post('volume_default', data);
    }

    async postShowPopQR(data: { show_pop_qr: boolean; }): Promise<void> {
        await this._post('show_pop_qr', data);
    }

    async postResetWifiNetwork(): Promise<void> {
        await this._post('reset_wifi_network');
    }

    async postPlay(data?: { playlist_id: number; }): Promise<void> {
        await this._post('play', data);
    }

    async postPause(): Promise<void> {
        await this._post('pause');
    }

    async postNext(): Promise<void> {
        await this._post('next');
    }

    async postPrevious(): Promise<void> {
        await this._post('previous');
    }

    async postMediaServer(data: { server_uuid: string; }): Promise<void> {
        await this._post('media_server', data);
    }

    async postPlaylist(data: { container_id?: number; play_when_done?: boolean; insert_after?: number; }): Promise<void> {
        await this._post('playlist', data);
    }

    async postPlaylistAppendContainer(data: { container_id?: number; play_when_done?: boolean; insert_after?: number; }): Promise<void> {
        await this._post('playlist_append_container', data);
    }

    async postHeadphoneOut(data: { headphone_out: boolean; }): Promise<{ headphone_out: boolean; }> {
        return this._post('headphone_out', data) as any;
    }

    async postClearFailover(): Promise<void> {
        await this._post('clear_failover');
    }

    async postRestoreWifiNetwork(): Promise<void> {
        await this._post('restore_wifi_network');
    }

    async putResetInputImage(data: { input_index: number; }): Promise<void> {
        await this._put('reset_input_image', data);
    }

    async putStartUpdate(): Promise<void> {
        await this._put('start_update');
    }

    async putBluetoothUnpair(): Promise<void> {
        await this._put('bluetooth_unpair');
    }

    async deletePlaylistDeleteAll(): Promise<void> {
        await this._delete('playlist_delete_all');
    }

    async _rawEndpointUpdate(endpoint: "POST" | "PUT", path: string, args: any): Promise<void> {
        if (endpoint === 'POST') {
            await this._post(path, args);
        } else {
            await this._put(path, args);
        }
    }

    private async _get(apiSuffix: string): Promise<any> {
        const baseUrl = await this.getBaseUrl();
        return lastValueFrom(this.http.get(`${baseUrl}/${apiSuffix}`));
    }

    private async _post(apiSuffix: string, args?: unknown) {
        const baseUrl = await this.getBaseUrl();
        return lastValueFrom(this.http.post(`${baseUrl}/${apiSuffix}`, args));
    }

    private async _put(apiSuffix: string, args?: unknown) {
        const baseUrl = await this.getBaseUrl();
        return lastValueFrom(this.http.put(`${baseUrl}/${apiSuffix}`, args));
    }

    private async _delete(apiSuffix: string) {
        const baseUrl = await this.getBaseUrl();
        return lastValueFrom(this.http.delete(`${baseUrl}/${apiSuffix}`));
    }

    private async getBaseUrl() {
        return firstValueFrom(this.settings.loadedStorage$.pipe(
            first(ls => ls),
            map(() => `http://${this.settings.server$.value}/api`),
        ));
    }
}
