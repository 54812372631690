import { Pipe, PipeTransform } from '@angular/core';

const domparser = new DOMParser();

@Pipe({
    name: 'getAlbumUri'
}) export class GetAlbumUriPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        const dom = domparser.parseFromString(value, 'application/xml');
        const artNode = dom.querySelector('albumArtURI');

        return artNode ? artNode.innerHTML : null;
    }

}
