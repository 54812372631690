import { isRemoteService } from '@amp/services/input.service';
import { Input } from '@amp/store/inputs/actions';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'canChangeInput'
}) export class CanChangeInputPipe implements PipeTransform {
    transform(input: Input): boolean {
        return !isRemoteService(input);
    }

}
