import { SettingsService } from '@amp/services/settings.service';
import { selectShowDB, selectSupportsNegInfVolume, selectVolume, selectVolumeStepSize } from '@amp/store/all/selector';
import { Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngrx/store';
import { range as _range } from 'lodash-es';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
    selector: 'app-swiper-slider',
    templateUrl: './swiper-slider.component.html',
    styleUrls: ['./swiper-slider.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SwiperSliderComponent),
        multi: true,
    }],
}) export class SwiperSliderComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input('min') min: number;
    @Input('max') max: number;

    @ViewChild(SwiperComponent) swiper: SwiperComponent;

    private _onTouched;
    private _onChange;
    public slideIndex$: BehaviorSubject<number> = new BehaviorSubject(0);
    public volumeSlides: Array<number> = []
    public touching: boolean = false;
    public showDB$ = this.store.select(selectShowDB);

    private touchStartIndex: number = 0;

    public swiperConfig: SwiperOptions = {
        centeredSlides: true,
        slidesPerView: 'auto',
        // freeMode: true,
        // freeModeSticky: true,
    };

    private subs: Array<Subscription> = [];

    constructor(
        public settings: SettingsService,
        private store: Store,
    ) { }

    ngOnInit() {
        this.subs.push(
            this.store.select(selectVolumeStepSize).subscribe(volumeStepSize => {
                this.volumeSlides = _range(this.min, this.max + 1, volumeStepSize);
            })
        );

    }

    ngOnDestroy() {
        for (const sub of this.subs) {
            sub.unsubscribe();
        }
        this.subs = [];
    }

    onIndexChange = () => {
        if (!this.swiper?.swiperRef) { return; }

        const newIndex = this.swiper.swiperRef.activeIndex;
        this.slideIndex$.next(newIndex);
    }

    async onTransitionEnd() {
        const currentVolume = await firstValueFrom(this.store.select(selectVolume));
        const hasNegInf = await firstValueFrom(this.store.select(selectSupportsNegInfVolume));
        const stepSize = await firstValueFrom(this.store.select(selectVolumeStepSize));

        const newVolume = this.indexToVolume(this.slideIndex$.value, hasNegInf, stepSize);

        if (currentVolume !== newVolume) {
            this.setModelValue(this.indexToVolume(this.slideIndex$.value, hasNegInf, stepSize));
        }
    }

    setModelValue(newVolume) {
        this._onChange(newVolume);
        this._onTouched();
    }

    onTouchStart = () => {
        this.touching = true;
        this.touchStartIndex = this.slideIndex$.value;
    }

    onTouchEnd = () => {
        this.touching = false;

        // If the server updates us on the volume while we have our finger down
        // it is ignored. If we do not slide from our initial slide value there
        // is no onTransitionEnd. This would mean the model (which *is* correct)
        // has desynced from swiper ui.

        // In such a case I call the user releasing their finger the last input
        // as the release of the finger happend *after* the volume had been sent
        // by the server.

        // setTimeout is used because onTouchEnd will always be triggered before
        // onIndexChange. And onTransitionEnd triggers after both of those. Thus
        // in the hold in place event we will ONLY get a onTouchEnd event.
        const boundStartIndex = this.touchStartIndex;
        setTimeout(async () => {
            console.log(boundStartIndex, this.slideIndex$)
            const currentVolume = await firstValueFrom(this.store.select(selectVolume));
            const hasNegInf = await firstValueFrom(this.store.select(selectSupportsNegInfVolume));
            const stepSize = await firstValueFrom(this.store.select(selectVolumeStepSize));

            if (
                boundStartIndex === this.slideIndex$.value &&
                this.indexToVolume(boundStartIndex, hasNegInf, stepSize) !== currentVolume
            ) {
                this.setModelValue(this.indexToVolume(boundStartIndex, hasNegInf, stepSize));
            }
        }, 100);
    }

    async writeValue(volume: any) {
        const hasNegInf = await firstValueFrom(this.store.select(selectSupportsNegInfVolume));
        const stepSize = await firstValueFrom(this.store.select(selectVolumeStepSize));

        if (!this.touching) {
            const val = this.volumeToSlide(volume, hasNegInf, stepSize)
            this.slideIndex$.next(val);
        } else {
            console.log('not updating with volume!');
        }
    }
    registerOnChange(fn: (v: any) => void): void {
        this._onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
    }

    // volume is always -100-0; slides are 0-100, there may be a -Inf at -101
    volumeToSlide(volume: number, hasNegInf: boolean, stepSize: number) {
        return Math.round((volume + (100 + (hasNegInf ? 1 : 0))) / stepSize);
    }

    indexToVolume(index: number, hasNegInf: boolean, stepSize: number) {
        return (index * stepSize) - (100 + (hasNegInf ? 1 : 0));
    }
}
