import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NOT_SUPPORTED, storeName } from './actions';
import { AllState } from './reducer';

const allState = createFeatureSelector<AllState>(`${storeName}`);

export const selectHeadphoneOut = createSelector(
    allState,
    (state) => state.headphone_out,
);

export const selectDacMode = createSelector(
    allState,
    (state) => state.dac_mode,
);

export const selectBluetoothConnected = createSelector(
    allState,
    (state) => state.bluetooth_connected,
);

export const selectBluetoothPaired = createSelector(
    allState,
    (state) => state.bluetooth_paired,
);

export const selectVolume = createSelector(
    allState,
    (state) => state.volume,
)

export const selectModelName = createSelector(
    allState,
    (state) => state.model,
);

// settings.service
export const selectMaxVolume = createSelector(
    allState,
    (state) => state.maxVolume,
);

export const selectDefaultVolume = createSelector(
    allState,
    (state) => state.volumeDefault,
);

export const selectShowDB = createSelector(
    allState,
    (state) => state.showDB,
);

export const selectMute = createSelector(
    allState,
    (state) => state.mute,
);

export const selectDeviceIpAddress = createSelector(
    allState,
    (state) => state.deviceIpAddress,
);

export const selectDeviceSSID = createSelector(
    allState,
    (state) => state.deviceSSID,
);
export const selectHasDeviceSSID = createSelector(
    selectDeviceSSID,
    (ssid) => ssid !== NOT_SUPPORTED
);

export const selectDeviceMacAddress = createSelector(
    allState,
    (state) => state.deviceMacAddress,
);

export const selectIsUpdateAvailable = createSelector(
    allState,
    (state) => state.updateVersion !== null,
);

export const selectUpdateVersionString = createSelector(
    allState,
    (state) => state.updateVersion
);

export const selectUpdateInProgress = createSelector(
    allState,
    (state) => state.updateInProgress,
);

export const selectUpdateProgressPercent = createSelector(
    allState,
    (state) => state.updateProgressPercent,
);

export const selectUpdateProgressString = createSelector(
    allState,
    (state) => state.updateProgressString,
);

export const selectTransportState = createSelector(
    allState,
    (state) => state.transportState,
);

export const selectShouldShowPopQr = createSelector(
    allState,
    (state) => state.showPopQr === true,
);

export const selectIsProvisioning = createSelector(
    allState,
    (state) => state.isProvisioning === true,
);

export const selectStandby = createSelector(
    allState,
    (state) => state.standby,
);

export const selectApiType = createSelector(
    allState,
    (state) => state.apiType,
);

export const selectSupportsNegInfVolume = createSelector(
    selectApiType,
    (apiType) => apiType === 'preamp'
);

export const selectVolumeStepSize = createSelector(
    allState,
    (state) => state.volumeStepSize,
);

export const selectDeviceNetworkStatus = createSelector(
    allState,
    (state) => state.deviceNetworkStatus,
);