<div
    class="footer"
    [ngClass]="(headphoneOutSupported$ | async) ? 'twobutton' : 'onebutton'"
>
    <button
        mat-button
        *ngIf="headphoneOutSupported$ | async"
        (click)="this.onClickHeadphone()"
        [style.visibility]="headphoneVisibility$ | async"
    >
        <mat-icon
            [inline]="true"
            [svgIcon]="
                (headphoneOut$ | async)
                    ? 'mat:headphones'
                    : 'amp:headphones_off'
            "
        ></mat-icon>
    </button>

    <app-swiper-slider
        [style.visibility]="visibility$ | async"
        [formControl]="this.volume"
        [min]="(hasNegInf$ | async) ? -101 : -100"
        [max]="0"
    ></app-swiper-slider>

    <button
        mat-button
        [style.visibility]="visibility$ | async"
        (click)="this.onClickMute()"
    >
        <mat-icon
            [inline]="true"
            [svgIcon]="
                (this.mute$ | async) ? 'amp:volume_off' : 'mat:volume_up'
            "
        ></mat-icon>
    </button>
</div>
<div class="footer-hole"></div>
