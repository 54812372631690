import { createFeatureSelector, createSelector } from "@ngrx/store";
import { storeName } from "./actions";
import { inputsAdapter, InputsState } from "./reducer";

const selectInputsState = createFeatureSelector<InputsState>(storeName);

const { selectAll: selectAllInputs, selectEntities: selectInputEntities } = inputsAdapter.getSelectors(selectInputsState);

export { selectAllInputs };

const selectSelectedInputIndex = createSelector(
    selectInputsState,
    (state) => state.selectedInputIndexId,
);

export const selectSelectedInput = createSelector(
    selectInputEntities,
    selectSelectedInputIndex,
    (entities, index) => entities[index],
);

export const selectInputById = (inputIndex: number) => createSelector(
    selectInputEntities,
    (entities) => entities[inputIndex],
);