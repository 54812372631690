import { SettingsService } from '@amp/services/settings.service';
import { ConfirmDialogComponent } from '@amp/settings/confirm-dialog/confirm-dialog.component';
import { NOT_SUPPORTED } from '@amp/store/all/actions';
import { selectDeviceIpAddress, selectDeviceMacAddress, selectDeviceNetworkStatus, selectDeviceSSID } from '@amp/store/all/selector';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { has as _has } from 'lodash-es';
import QRCode from 'qrcode';
import { Observable, from, lastValueFrom, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ServerService } from '../services/server.service';


@Component({
    selector: 'app-network-settings',
    templateUrl: './network-settings.component.html',
    styleUrls: ['./network-settings.component.scss']
}) export class NetworkSettingsComponent implements OnInit {
    public resetWifiText = "Reset Wifi Defaults";
    public restoreWifiText = "Restore Saved Wifi";

    notSupported = NOT_SUPPORTED;

    deviceSSID$ = this.store.select(selectDeviceSSID);
    deviceMacAddress$ = this.store.select(selectDeviceMacAddress);
    deviceIpAddress$ = this.store.select(selectDeviceIpAddress);
    deviceNetworkStatus$ = this.store.select(selectDeviceNetworkStatus);

    // done this way to only call the server iff we need the value
    hasProofOfPossession$ = of(false).pipe(
        switchMap(() => from(this.server.getProofOfPossession()).pipe(
            catchError((e) => of({ proof_of_possession: NOT_SUPPORTED })),
            map(r => r.proof_of_possession !== NOT_SUPPORTED),
        )),
    );

    constructor(
        public settings: SettingsService,
        private route: ActivatedRoute,
        private location: Location,
        private dialog: MatDialog,
        private server: ServerService,
        private store: Store,
    ) { }

    xfeIpAddressQr$: Observable<string>;

    private timeout = null;
    ngOnInit() {
        this.xfeIpAddressQr$ = this.deviceIpAddress$.pipe(
            filter(ip => ip !== NOT_SUPPORTED),
            switchMap(async (ip) => await QRCode.toDataURL(ip, {
                width: 360,
                errorCorrectionLevel: 'high',
            }))
        );

        if (_has(this.route.snapshot.queryParams, 'duration')) {
            const seconds = +this.route.snapshot.queryParams.duration;
            this.timeout = setTimeout(() => this.onClickBack(), seconds * 1000);
        }
    }

    ngOnDestroy() {
        if (!!this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    onClickBack() {
        this.location.back();
    }

    async onClickWifiReset() {
        const doFactory = await lastValueFrom(this.dialog.open(ConfirmDialogComponent, { data: { title: this.resetWifiText } }).afterClosed());
        if (doFactory) {
            try {
                await this.server.postResetWifiNetwork();
            } catch (e) {
                console.error('Could not reset to factory wifi defaults error was ', e);
            }
        }
    }

    async onClickWifiRestore() {
        const doFactory = await lastValueFrom(this.dialog.open(ConfirmDialogComponent, { data: { title: this.restoreWifiText } }).afterClosed());
        if (doFactory) {
            try {
                await this.server.postRestoreWifiNetwork();
            } catch (e) {
                console.error('Could not reset to factory wifi defaults error was ', e);
            }
        }
    }
}
