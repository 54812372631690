<app-header>
    <button
        mat-button
        *ngIf="!this.displayType.isDevice()"
        (click)="this.menu.onClickSelectDevice()"
    >
        <mat-icon svgIcon="mat:logout" [inline]="true"></mat-icon>
    </button>
</app-header>

<div class="fillall">
    <div class="failover">
        <div class="header">
            <mat-icon
                fontSet="mdi"
                fontIcon="mdi-lan-disconnect"
                inline
            ></mat-icon>
            Connection to Wi-Fi Lost!
        </div>
        <div class="message">
            Please check connection. Attempt to reconnect, or continue in WiFi
            Direct Mode (Offline)
        </div>
        <button class="reconnect" (click)="reconnect()" mat-flat-button>
            Reconnect
        </button>
        <button class="resume" (click)="ack()" mat-flat-button>
            Continue Offline
        </button>
    </div>
</div>
