import { DisplayTypeService } from '@amp/services/display-type.service';
import {
    InputService,
    isNetwork,
    isRemoteService,
} from '@amp/services/input.service';
import { PollerService } from '@amp/services/poller.service';
import { SettingsService } from '@amp/services/settings.service';
import { NOT_SUPPORTED } from '@amp/store/all/actions';
import {
    selectDeviceNetworkStatus,
    selectIsProvisioning,
    selectShouldShowPopQr,
    selectStandby,
    selectTransportState,
    selectUpdateInProgress,
} from '@amp/store/all/selector';
import { Input } from '@amp/store/inputs/actions';
import { selectSelectedInput } from '@amp/store/inputs/selectors';
import { lock } from '@amp/util';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { some as _some } from 'lodash-es';
import {
    BehaviorSubject,
    firstValueFrom,
    fromEvent,
    interval,
    merge,
    Subscription,
} from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    startWith,
    switchMap,
} from 'rxjs/operators';

const mutex = new BehaviorSubject(false);

// There are various state transitions where we want to automatically
// rereoute the visible screen according to some change in app state
@Injectable({
    providedIn: 'root',
})
export class AutorouterService {
    constructor(
        private settings: SettingsService,
        private poller: PollerService,
        private input: InputService,
        private store: Store,

        private displayType: DisplayTypeService,
        private router: Router
    ) {
        // most (all?) of the time we only care about state if we are logged in
        // thus we setup & kill the listeners on the login event
        this.settings.loggedIn$
            .pipe(
                distinctUntilChanged(),
                filter((li) => li)
            )
            .subscribe(() => this.onLogin());

        this.settings.loggedIn$
            .pipe(
                distinctUntilChanged(),
                filter((li) => !li)
            )
            .subscribe(() => this.onLogout());
    }

    loginSubs: Array<Subscription> = [];

    userActionRecent$ = new BehaviorSubject(true);

    onLogin() {
        const userActionTimeout$ = fromEvent(document, 'pointerdown').pipe(
            switchMap(() =>
                interval(20 * 1000).pipe(
                    first(),
                    map(() => false),
                    startWith(true)
                )
            ),
            startWith(false)
        );

        this.loginSubs.push(
            userActionTimeout$.subscribe(this.userActionRecent$)
        );

        this.loginSubs.push(
            merge(
                this.settings.rawAllData$,
                // serverResponding$ does not trigger an all$ on the false case
                this.poller.serverResponding$,
                // increases responsiveness by not relying on the poll
                this.store.select(selectStandby),
                this.store.select(selectDeviceNetworkStatus),
                this.store.select(selectUpdateInProgress),
                userActionTimeout$,
                // if all else fails you WILL eventually update
                interval(10 * 1000).pipe(startWith(() => 0))
            )
                .pipe(
                    // multiple of combine latest might trigger at the 'same' time
                    debounceTime(25)
                )
                .subscribe(() => this.onAllUpdate())
        );
    }

    onLogout() {
        for (const sub of this.loginSubs) {
            sub.unsubscribe();
        }
        this.loginSubs = [];
    }

    private lastValidRoute = '/input/show';
    private invalidReturnRoutes = [
        '/signin(?!w)',
        '/standby(?!w)',
        '/update(?!w)',
        '/network_failover(?!w)',
        '/device_load_failure(?!w)',
    ];
    private xfeInvalidReturnRoutes = ['/settings/pop(?!w)'];
    @lock(mutex)
    private async onAllUpdate() {
        const shouldShowPopQr = await this.shouldShowPopQr();

        const fromRoute = this.router.routerState.snapshot.url;
        let routeIsSavable = this.routeIsSavable(fromRoute);
        if (!shouldShowPopQr && fromRoute === '/settings/pop') {
            routeIsSavable = true;
        }
        if (routeIsSavable) {
            this.lastValidRoute = fromRoute;
        }

        const selectedInput = await firstValueFrom(
            this.store.select(selectSelectedInput)
        );
        const updateInProgress = await firstValueFrom(
            this.store.select(selectUpdateInProgress)
        );
        const isPlaying = await this.isPlaying();
        const isProvisioning = await this.isProvisioning();
        const standby = await firstValueFrom(this.store.select(selectStandby));
        const inFailoverStatus = await this.inFailoverStatus();

        if (!this.poller.serverResponding$.value) {
            await this.router.navigate(['/signin'], {
                skipLocationChange: true,
            });
        } else if (inFailoverStatus) {
            await this.router.navigate(['/network_failover'], {
                skipLocationChange: true,
            });
        } else if (standby && !isProvisioning) {
            await this.router.navigate(['/standby'], {
                skipLocationChange: true,
            });
        } else if (updateInProgress) {
            await this.router.navigate(['/update'], {
                skipLocationChange: true,
            });
        } else if (this.displayType.isDevice() && shouldShowPopQr) {
            await this.router.navigate(['/settings/pop'], {
                skipLocationChange: true,
            });

            // Note: its very important that its not possible to flip from nowplaying to
            // input/show and back again automatically. I dont think this state should allow it
            // but its very important to double check that
        } else if (
            fromRoute === '/nowplaying_player' &&
            !this.playerInput(selectedInput)
        ) {
            await this.router.navigate(['/input/show']);
        } else if (
            this.displayType.isDevice() &&
            isPlaying &&
            this.canTransitionToPlayer(fromRoute) &&
            this.noRecentUserActions() &&
            this.playerInput(selectedInput)
        ) {
            await this.router.navigate(['/nowplaying_player']);
        } else if (!routeIsSavable) {
            await this.router.navigate([this.lastValidRoute]);
        }
    }

    private routeIsSavable(fromRoute): boolean {
        return (
            !_some(this.invalidReturnRoutes, (route) =>
                RegExp(route).test(fromRoute)
            ) &&
            !(
                this.displayType.isDevice() &&
                _some(this.xfeInvalidReturnRoutes, (route) =>
                    RegExp(route).test(fromRoute)
                )
            )
        );
    }

    private async shouldShowPopQr(): Promise<boolean> {
        const shouldShowPopQr = await firstValueFrom(
            this.store.select(selectShouldShowPopQr)
        );
        return shouldShowPopQr;
    }

    private async isProvisioning(): Promise<boolean> {
        return await firstValueFrom(this.store.select(selectIsProvisioning));
    }

    private playerInput(input: Input): boolean {
        return [isRemoteService, isNetwork].some((fn) => fn(input));
    }

    private async isPlaying(): Promise<boolean> {
        const transportState = await firstValueFrom(
            this.store.select(selectTransportState)
        );
        return transportState === 'Playing';
    }

    private async inFailoverStatus(): Promise<boolean> {
        const networkStatus = await firstValueFrom(
            this.store.select(selectDeviceNetworkStatus)
        );
        return (
            networkStatus !== NOT_SUPPORTED && /failover/.test(networkStatus)
        );
    }

    // it's better to err to doing no transition. Thus cantransition is based on
    // whitelisting various routes that *are* ok
    private canTransitionToPlayer(route: string) {
        return route === '/input/show';
    }

    private noRecentUserActions(): boolean {
        return !this.userActionRecent$.value;
    }
}
