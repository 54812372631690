import { has } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

const locks: { [key in symbol]: BehaviorSubject<boolean> } = {};

export const runlock = function (mutex: symbol) {
    if (!has(locks, mutex)) {
        // pointLock$ MUST be initialized to false
        locks[mutex] = new BehaviorSubject(false);
    }

    const pointLock$ = locks[mutex];

    return function (target, key, descriptor) {
        const origMethod = descriptor.value
        descriptor.value = async function (...args) {
            // when the pointLock$ goes to unlocked all watchers are notified / scheduled
            // only one will actually get onto the stack [first]. It will take the lock.
            // it may yield at which point other people who got that orig lock will be
            // run. They will then see that the lock was taken and reschedule themselves
            // to go for the lock
            do {
                await pointLock$.pipe(first((locked: boolean) => !locked)).toPromise()
            } while (pointLock$.value)
            pointLock$.next(true)

            let result = undefined
            try {
                result = await origMethod.apply(this, args)
            } catch (e) {
                pointLock$.next(false)
                throw e
            }
            pointLock$.next(false)
            return result
        }
        return descriptor;
    }
}
