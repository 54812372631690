<ng-container *ngIf="this.dt.isDevice(); then device; else nondevice">
</ng-container>

<ng-template #device>
    <img src="assets/img/boulder-logo-splash.png" />
    <div class="initmsg">Resuming from Standby ...</div>
</ng-template>

<ng-template #nondevice>
    <app-header>
        <button mat-button (click)="this.menu.onClickSelectDevice()">
            <mat-icon svgIcon="mat:logout" [inline]="true"></mat-icon>
        </button>
    </app-header>

    <div class="fillall">
        <div class="standby">
            <div class="message">
                {{ this.userResumed ? 'Exiting Standby ...' : 'In Standby' }}
            </div>
            <button
                class="resume"
                (click)="resume()"
                mat-flat-button
                [disabled]="userResumed"
            >
                Resume
            </button>
        </div>
    </div>
</ng-template>
