import { resetDeviceInfo } from '@amp/store/global-actions';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { DisplayTypeService } from './display-type.service';
import { DistinctBehaviorSubject } from './distinctbehaviorsubject';

@Injectable({
    providedIn: 'root'
}) export class SettingsService {
    subs: Array<Subscription> = [];
    constructor(
        private displayType: DisplayTypeService,
        private storage: Storage,
        private store: Store,
    ) {
        this.loadStorage();

        this.subs.push(
            this.server$.subscribe(() => this.store.dispatch(resetDeviceInfo()))
        );
    }

    ngOnDestroy() {
        for (const s of this.subs) {
            s.unsubscribe();
        }
        this.subs = [];
    }

    // autorouter item - use a reg behav subject
    public loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loadedStorage$: DistinctBehaviorSubject<boolean> = new DistinctBehaviorSubject(false);
    async loadStorage() {
        await this.storage.create();
        const server = await this.storage.get('server');
        if (!!server) { this.server$.next(server); }

        // server$ is a behaviorsubject so we can safely skip(1)
        this.server$.pipe(skip(1)).subscribe(newServer => this.storage.set('server', newServer));
        this.loadedStorage$.next(true);
    }

    // default server is
    // dfe -> localhost
    // phone -> <blank>
    // browser -> window.location.hostname
    public server$: DistinctBehaviorSubject<string> = new DistinctBehaviorSubject(
        this.displayType.isDevice() ? 'localhost' :
            this.displayType.isPhone() ? '' :
                window.location.hostname
    );

    public rawAllData$: DistinctBehaviorSubject<any> = new DistinctBehaviorSubject({});
}
