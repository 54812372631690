import { ServerService } from '@amp/services/server.service';
import { SettingsService } from '@amp/services/settings.service';
import { setStandby } from '@amp/store/all/actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
}) export class MenuActionsService {
    constructor(
        private settings: SettingsService,
        private server: ServerService,
        private router: Router,
        private store: Store,
    ) { }

    onClickStandby() {
        this.store.dispatch(setStandby({ standby: true }));
        void /* await */ this.server.postStandby({ standby: true });
    }

    onClickSelectDevice() {
        this.settings.loggedIn$.next(false);
        this.router.navigate(['/signin']);
    }
}
