<div>
    <mat-menu #ampMenu="matMenu">
        <a (click)="this.menu.onClickSelectDevice()"
            ><button mat-menu-item>Select Boulder Device</button></a
        >
        <a routerLink="/settings"><button mat-menu-item>Settings</button></a>
    </mat-menu>
    <app-header>
        <div class="pre-title">
            <button
                mat-button
                routerLink="/settings"
                *appOnlyDisplayOn="'DEVICE'"
            >
                <mat-icon inline>menu</mat-icon>
            </button>
            <button
                mat-button
                [matMenuTriggerFor]="ampMenu"
                *appOnlyDisplayOn="'NOTDEVICE'"
            >
                <mat-icon inline>menu</mat-icon>
            </button>
        </div>
        <div class="model-name">{{ this.model$ | ngrxPush }}</div>
        <!-- acts to help center the title -->
        <div class="post-title">
            <!--  && this.displayType.isPhone() -->
            <button
                class="updateAvailableShort"
                mat-button
                *ngIf="isUpdateAvailable$ | async"
                routerLink="/update"
            >
                <mat-icon fontSet="mdi" fontIcon="mdi-update" inline></mat-icon>
            </button>
            <button
                mat-button
                *ngIf="!this.displayType.isDevice()"
                (click)="this.menu.onClickStandby()"
            >
                <mat-icon inline>power_settings_new</mat-icon>
            </button>
        </div>
    </app-header>
</div>

<div class="body">
    <router-outlet></router-outlet>
</div>

<ng-container
    *ngIf="this.displayType.isDevice(); then deviceFooter; else phoneFooter"
></ng-container>

<ng-template #deviceFooter>
    <div class="footer device">
        <div class="padding"></div>
        <div class="volume" [class.muted]="this.mute$ | async">
            <mat-icon
                *ngIf="this.mute$ | async"
                svgIcon="amp:volume_off"
                inline
            ></mat-icon>
            <div class="text">
                {{
                    this.volume$
                        | async
                        | possiblevolume: (this.showDB$ | async)
                }}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #phoneFooter>
    <app-footer></app-footer>
</ng-template>
