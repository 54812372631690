import { OhTrackListData } from '@amp/playlist/playlist.types';
import { NOT_SUPPORTED, serverUpdateAll } from '@amp/store/all/actions';
import { resetDeviceInfo } from '@amp/store/global-actions';
import { createReducer, on } from '@ngrx/store';
import { clearPlaylist } from './actions';


export interface PlaylistState {
    oh_track_list: OhTrackListData;
    id: number;
}

export const initialState: PlaylistState = {
    oh_track_list: [],
    id: null,
};


export const playlistReducer = createReducer(
    initialState,

    on(serverUpdateAll, (state, { all }) => {
        // only a dfe has a playlist; so on an afe playlist will be null
        const playlist = all.playlist;
        if (!!playlist && playlist !== NOT_SUPPORTED) {
            return { ...state, oh_track_list: [...playlist.oh_track_list], id: playlist.id }
        } else {
            return state;
        }
    }),

    on(clearPlaylist, state => ({ ...state, oh_track_data: [] })),
    on(resetDeviceInfo, () => initialState),
);
