import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { get as _get, has as _has, includes as _includes, last as _last, remove as _remove, split as _split } from 'lodash-es';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, first, mergeMap, switchMap } from 'rxjs/operators';

// if we are in a noGET state since we have done a post & we want
// the server to have a bit of time to stabalize
export let uiQuietPeriod: boolean = false;
// the length of time (ms) to wait after the RETURN of an updating
// operation (POST/PUT/DELETE)
const uiQuietInterval = 500;
const uiQuietIntervalOverides = {
    standby: 5 * 1000,
};
// this will trigger if the mutating operation never returns
// its a safeguard to having pendingchanges stick arround forever
// and thus causing infinate rescheduling
const uiQuietForceReleaseInterval = 10 * 1000;

const mutatingHttpRequests = ['POST', 'PUT', 'DELETE'];

@Injectable()
export class PollInterceptor implements HttpInterceptor {
    constructor(private store: Store) {

    }

    private updateNumber: number = 0;
    private pendingChanges$: BehaviorSubject<Array<number>> = new BehaviorSubject([]);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isMutatingRequest(request)) {
            ++this.updateNumber;

            const currentPendingChanges = this.pendingChanges$.value;
            currentPendingChanges.push(this.updateNumber);
            this.pendingChanges$.next(currentPendingChanges);
            uiQuietPeriod = true;

            // if the update never comes back we want the ability to recover
            const boundUpdateNumber = this.updateNumber;
            window.setTimeout(() => {
                this.changeFinished(boundUpdateNumber, request);
            }, uiQuietForceReleaseInterval);
        }

        let updateIteration = this.updateNumber;

        if (request.method !== 'OPTIONS') {
            request = request.clone({ setHeaders: { 'State-Counter': `${updateIteration}` } })
        }

        const ensurePoll = mergeMap((result: HttpEvent<any> | HttpErrorResponse) => {
            if (result instanceof HttpResponse) {
                if (this.isMutatingRequest(request)) {
                    // poll rate is 250 ms - go give *changes* more time then poll rate
                    const timeoutHandle = window.setTimeout(() => {
                        this.changeFinished(updateIteration);
                    }, this.getQuietInterval(request));
                    return of(result);
                } else if (request.method === 'GET' && (this.updateNumber > updateIteration || this.pendingChanges$.value.length > 0)) {

                    updateIteration = this.updateNumber;
                    return this.pendingChanges$.pipe(
                        first(pc => pc.length === 0),
                        switchMap(() => next.handle(request = request.clone({
                            setHeaders: { 'State-Counter': `${updateIteration}` }
                        }))),
                        catchError(e => of(e)),
                        ensurePoll,
                    )
                } else {
                    return of(result);
                }
            } else if (result instanceof HttpErrorResponse) {
                if (this.isMutatingRequest(request)) {
                    // update failed so just resume get immediatly
                    this.changeFinished(updateIteration);
                }
                return throwError(result);
            } else {
                return of(result);
            }
        })

        return this.pendingChanges$.pipe(
            first(pc => pc.length === 0 || request.method !== 'GET'),
            switchMap(() => next.handle(request)),
            catchError(e => of(e)),
            ensurePoll,
        );
    }

    isMutatingRequest(request: HttpRequest<any>): boolean {
        return mutatingHttpRequests.includes(request.method);
    }

    getQuietInterval(request: HttpRequest<any>) {
        const strippedUrl = _last(_split(request.url, '/'));
        if (_has(uiQuietIntervalOverides, strippedUrl)) {
            return _get(uiQuietIntervalOverides, strippedUrl);
        } else {
            return uiQuietInterval;
        }
    }

    changeFinished(updateNumber: number, warnRequest: HttpRequest<any> = undefined) {
        const currentPendingChanges = this.pendingChanges$.value;

        if (!!warnRequest && _includes(currentPendingChanges, updateNumber)) {
            console.error('An update HTTP operation has not completed in before the backup timeout', warnRequest);
        }

        _remove(currentPendingChanges, change => change === updateNumber);
        this.pendingChanges$.next(currentPendingChanges);

        if (currentPendingChanges.length === 0) {
            uiQuietPeriod = false;
        }
    }
}

export const pollInterceptorProvider = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: PollInterceptor,
        multi: true
    }
]
