import { Pipe, PipeTransform } from '@angular/core';
import { BaseSetting, getControlId } from './settings.component';

@Pipe({
    name: 'getControlId'
}) export class GetControlIdPipe implements PipeTransform {
    transform(input: BaseSetting): string {
        return getControlId(input);
    }
}
