import { Injectable } from '@angular/core';

export type DisplayType = "PHONE" | "DEVICE" | "WEB" | "NOTDEVICE";

@Injectable({
    providedIn: 'root'
}) export class DisplayTypeService {

    public isDevice(): boolean {
        return /BoulderAmplifiersFrontPanel/.test(navigator.userAgent);
    }

    public isPhone(): boolean {
        return typeof (window['device']) !== 'undefined';
    }

    conditionValid(condition: DisplayType) {
        return (condition === 'DEVICE' && this.isDevice()) ||
            (condition === 'NOTDEVICE' && !this.isDevice()) ||
            (condition === 'PHONE' && this.isPhone()) ||
            (condition === 'WEB' && !this.isDevice() && !this.isPhone());
    }
}
