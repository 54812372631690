import { MediaServerModule } from '@amp/store/media-server/media-server.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActionsInProgressModule } from './actions-in-progress/actions-in-progress.module';
import { AllModule } from './all/all.module';
import { BrowseModule } from './browse/browse.module';
import { CurrentTrackModule } from './current-track/current-track.module';
import { InputsStoreModule } from './inputs/inputs.module';
import { PlaylistModule } from './playlist/playlist.module';
import { SettingsStoreModule } from './settings/settings.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        BrowseModule,
        PlaylistModule,
        CurrentTrackModule,
        ActionsInProgressModule,
        MediaServerModule,
        SettingsStoreModule,
        InputsStoreModule,
        AllModule,
    ]
}) export class RootStoreModule { }
