import { BrowseComponent } from '@amp/browse/browse.component';
import { InputBaseComponent } from '@amp/input-base/input-base.component';
import { InputShowComponent } from '@amp/input-show/input-show.component';
import { NetworkReprovisionComponent } from '@amp/network-reprovision/network-reprovision.component';
import { NetworkSettingsComponent } from '@amp/network-settings/network-settings.component';
import { PlayerComponent } from '@amp/now-playing/player/player.component';
import { PlaylistComponent } from '@amp/playlist/playlist.component';
import { PoPQRDisplayComponent } from '@amp/popqrdisplay/popqrdisplay.component';
import { BaseComponent as ProvisioningBaseComponent } from '@amp/provisioning/base/base.component';
import { GetValuesComponent } from '@amp/provisioning/getvalues/getvalues.component';
import { JoinnetworkComponent } from '@amp/provisioning/joinnetwork/joinnetwork.component';
import { WaitSuccessComponent } from '@amp/provisioning/wait-success/wait-success.component';
import { SettingsComponent } from '@amp/settings/settings.component';
import { SigninComponent } from '@amp/signin/signin.component';
import { StandbyComponent } from '@amp/standby/standby.component';
import { UpdateComponent } from '@amp/update/update.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BluetoothSettingsComponent } from './bluetooth-settings/bluetooth-settings.component';
import { NetworkFailoverComponent } from './network-failover/network-failover.component';

const routes: Routes = [
    { path: 'signin', component: SigninComponent },
    { path: 'settings', component: SettingsComponent },
    { path: 'settings/pop', component: PoPQRDisplayComponent },
    { path: 'settings/network', component: NetworkSettingsComponent },
    {
        path: 'settings/network/reprovision',
        component: NetworkReprovisionComponent,
    },
    { path: 'settings/bluetooth', component: BluetoothSettingsComponent },
    {
        path: 'provisioning',
        component: ProvisioningBaseComponent,
        children: [
            { path: 'joinnetwork', component: JoinnetworkComponent },
            { path: 'getvalues', component: GetValuesComponent },
            { path: 'waitsuccess', component: WaitSuccessComponent },
        ],
    },
    { path: 'update', component: UpdateComponent },
    {
        path: 'input',
        component: InputBaseComponent,
        children: [
            { path: 'show', component: InputShowComponent },
            { path: '', redirectTo: 'show', pathMatch: 'full' },
        ],
    },
    { path: 'nowplaying_player', component: PlayerComponent },
    { path: 'playlist', component: PlaylistComponent },
    { path: 'filebrowser', component: BrowseComponent },
    { path: 'standby', component: StandbyComponent },
    { path: 'network_failover', component: NetworkFailoverComponent },
    { path: '', redirectTo: '/signin', pathMatch: 'full' },
    { path: '*', redirectTo: '/signin' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
