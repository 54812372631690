import { ProvisioningService } from '@amp/provisioning/provisioning.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { retry, timeout } from 'rxjs/operators';

// constants for registration use
// because we are throwing up an ad-hoc network we know & can garuntee the ip is always the same
const ip = '192.168.0.2';
const port = '1234';
const url = `http://${ip}:${port}`;

class KnownError {
    constructor(public message: string) {}
}

@Component({
    selector: 'app-network-reprovision',
    templateUrl: './network-reprovision.component.html',
    styleUrls: ['./network-reprovision.component.scss'],
})
export class NetworkReprovisionComponent implements OnInit {
    public networks: Array<string> = [];
    public form: FormGroup;
    public doingReprovision = false;

    constructor(
        private http: HttpClient,
        private fb: FormBuilder,
        private snackbar: MatSnackBar,
        private router: Router,
        private provisioning: ProvisioningService
    ) {
        this.form = this.fb.group({
            pop: ['', Validators.required],
            ssid: ['', Validators.required],
            password: [''],
        });
    }

    async ngOnInit() {
        try {
            const ssids: any = await lastValueFrom(this.http
                .get(`${url}/ssids`)
                .pipe(retry(3)));
            this.networks = ssids.ssids;
        } catch (e) {
            // todo: what should happen here?
        }
    }

    ngOnDestroy() {}

    get c() {
        return this.form.controls;
    }

    async tryConnect() {}
    deviceCanConnect(): Promise<void> {
        const startTime = new Date().valueOf();
        return new Promise((resolve, reject) => {
            const timer = setInterval(async () => {
                try {
                    void await lastValueFrom(this.http
                        .get(`${url}/prov-config`, undefined)
                        .pipe(timeout(500)));

                    // got value!
                    clearInterval(timer);
                    resolve();
                } catch (error) {
                    if (
                        error instanceof HttpErrorResponse &&
                        error.status === 424
                    ) {
                        // a 424 means we dont know yet - so keep going
                    } else if (error.name === 'TimeoutError') {
                        // this is a [single] timeout - its ok.
                    } else if (
                        new Date().valueOf() - startTime >
                        1000 * 60 * 2
                    ) {
                        clearInterval(timer);
                        reject(
                            new KnownError('Network took too long to come up')
                        );
                    } else if (
                        error instanceof HttpErrorResponse &&
                        error.status === 400
                    ) {
                        clearInterval(timer);
                        reject(new KnownError('Could not connect to WiFi.'));
                    } else {
                        console.error('unknown error', error);
                        // continue - since we dont know what this error is it might be
                        // transiant
                    }
                }
            }, 1000 * 10);
        });
    }

    async onReprovisionClick() {
        if (this.form.invalid) {
            this.form.markAsTouched();
            return;
        }
        this.doingReprovision = true;

        const ssid = this.c.ssid.value;
        const password = this.c.password.value;
        const pop = this.c.pop.value;

        try {
            void (await this.provisioning.sendProvisioningData(
                ssid,
                password,
                pop
            ));

            void (await this.deviceCanConnect());
            this.snackbar.open(`Joined ${ssid}`, 'Ok');
            this.router.navigate(['/input/show']);
        } catch (e) {
            console.error('Error doing reprovisioning', e);

            this.snackbar.open(
                e instanceof KnownError ? e.message : 'Reprovisioning failed',
                'Ok',
                { duration: 5 * 1000 }
            );
        }
        this.doingReprovision = false;
    }
}
