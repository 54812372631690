import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Counter as AESCounter,
    ModeOfOperation,
    utils as AESUtils,
} from 'aes-js';
import BN from 'bn.js';
import * as Hash from 'hash.js';
import { lastValueFrom, timeout } from 'rxjs';
import { box, scalarMult } from 'tweetnacl';

type ProvSess0 = {
    dev_random: string;
    device_pubkey: string;
};

type ProvSess1 = {
    device_verify: string;
};

const utf8Bytes = AESUtils.utf8.toBytes;

const encodeBytes = (buf: Uint8Array): string => {
    var binstr = Array.prototype.map
        .call(buf, function (ch: number) {
            return String.fromCharCode(ch);
        })
        .join('');
    return btoa(binstr);
};

const decodeString = (str: string): Uint8Array =>
    Uint8Array.from(atob(str), (c) => c.charCodeAt(0));

// constants for registration use
// because we are throwing up an ad-hoc network we know & can garuntee the ip is always the same
const ip = '192.168.0.2';
const port = '1234';
const url = `http://${ip}:${port}`;

@Injectable({
    providedIn: 'root',
})
export class ProvisioningService {
    public ssid: string = '';
    public pop: string = '';
    public password: string = '';
    public serial: string = '';
    public origSsid: string = '';

    public hasDisplay: boolean = null;

    constructor(private http: HttpClient) {}

    async sendProvisioningData(
        ssid: string,
        password: string,
        pop: string
    ): Promise<void> {
        const keyPair = box.keyPair();

        let rProvSess0: ProvSess0;
        try {
            rProvSess0 = await lastValueFrom(
                this.http.post<ProvSess0>(`${url}/prov-session0`, {
                    client_pubkey: encodeBytes(keyPair.publicKey),
                })
            );
        } catch (e) {
            console.error('Error with /prov-session0', e);
            throw e;
        }

        const deviceRandom = new BN(
            decodeString(rProvSess0.dev_random)
        ).fromTwos(8 * 8);
        const devicePubkey = decodeString(rProvSess0.device_pubkey);

        const sharedK: Uint8Array = scalarMult(keyPair.secretKey, devicePubkey);

        const digest = Hash.sha256().update(pop).digest();
        const xored = new BN(sharedK).xor(new BN(digest));

        const counterInit = deviceRandom
            .toTwos(16 * 8)
            .toArrayLike(Uint8Array, undefined, 16);
        const cipher = new ModeOfOperation.ctr(
            xored.toArrayLike(Uint8Array),
            new AESCounter(counterInit)
        );
        const clientVerify = cipher.encrypt(devicePubkey);

        let rProvSess1: ProvSess1;
        try {
            rProvSess1 = await lastValueFrom(
                this.http.post<ProvSess1>(`${url}/prov-session1`, {
                    client_verifier: encodeBytes(clientVerify),
                })
            );
        } catch (e) {
            console.error('Error with /prov-session1', e);
            if (e instanceof HttpErrorResponse && e.status === 403) {
                // the user provides SSID, POP & password. The only one we can actually
                // have the system check & get a return value for is pop
                throw new Error('Incorrect Proof of Possession');
            } else {
                throw e;
            }
        }

        const deviceVerifyPlaintext = cipher.decrypt(
            decodeString(rProvSess1.device_verify)
        );

        if (
            deviceVerifyPlaintext.length !== keyPair.publicKey.length ||
            !keyPair.publicKey.every((x, i) => x === deviceVerifyPlaintext[i])
        ) {
            // todo: Alert user? with what?
            console.error('device sent incorrect verification code');
            throw 'device did not send a correct verification code';
        }

        const ssidCrypt = encodeBytes(cipher.encrypt(utf8Bytes(ssid)));
        const passwordCrypt = encodeBytes(cipher.encrypt(utf8Bytes(password)));

        try {
            void (await lastValueFrom(
                this.http
                    .post(`${url}/prov-config`, {
                        ssid: ssidCrypt,
                        password: passwordCrypt,
                    })
                    .pipe(timeout(2 * 1000))
            ));
        } catch (e) {
            console.error('Error with /prov-session1', e);
            throw e;
        }
    }
}
