<p>Please change your phone's connection to {{ this.provisioning.ssid }}.</p>

<p>
    Currently connected to
    <span [class]="this.networkCorrect ? 'network-ok' : 'network-bad'"
        ><app-currentnetwork></app-currentnetwork
    ></span>
</p>

<div>Scanning for your Boulder Device</div>
<mat-progress-bar [value]="this.timeoutPct$ | async"></mat-progress-bar>
