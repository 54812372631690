
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


declare const cordova;

if (environment.production) {
    enableProdMode();
}


const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

if (typeof cordova === 'undefined') {
    bootstrap()
} else {
    document.addEventListener('deviceready', bootstrap, false);
}
