import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
}) export class ConfirmDialogComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { title: string },
    ) { }

    ngOnInit() {
    }
}
